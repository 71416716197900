import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CloseButton,
    Col,
    Container,
    Modal,
    ModalBody,
    Row,
    Spinner
} from "reactstrap";

//Import Breadcrumb
import BreadCrumbs from "../../Components/Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import he from "he";
import moment from "moment";
import {addComment} from "../../api/news";
import Loader from "../../Components/Common/Loader";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getNewsList} from "../../slices/news/thunk";
import sanitizeHtml from "sanitize-html";
import {toast, ToastContainer} from "react-toastify";

//import placeholder
import placeholder from "../../assets/images/small/img-1.jpg";
const NewsPage = () => {

    function unescapeHTML(html) {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.documentElement.textContent;
    }

    function removeHTMLTags(input) {
        return input
            .replace(/&nbsp;/g, ' ')
            .replace(/&quot;/g, '"')
            .replace(/<[^>]*>/g, '');
    }

    function cleanText(input) {
        const decodedText = he.decode(input);
        const doc = new DOMParser().parseFromString(decodedText, 'text/html');
        return doc.body.textContent || "";
    }

    const [modal_center, setmodal_center] = useState(false);
    const [currentNews, setCurrentNews] = useState(null);

    const dispatch = useDispatch();
    const selectDashboardData = createSelector(
        (state) => state.News.newsList,
        (newsList) => newsList
    );

    const newsList = useSelector(selectDashboardData);
    const [allNews, setAllNews] = useState([]);

    const [myComment, setMyComment] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [loading, setLoading] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    useEffect(() => {
        setMyComment('');

    }, [currentNews]);

    useEffect(() => {
        setLoading(true);
        dispatch(getNewsList());
    }, [dispatch]);

    useEffect(() => {
        setAllNews(newsList);
        setLoading(false);
    }, [newsList]);

    const submitComment = async () => {
        if (!myComment.trim()) return;
        setIsSending(true);
        await addComment(currentNews.id, myComment).then(
            () => {
                dispatch(getNewsList());
                toast.success("Комментарий успешно добавлен");
                setMyComment('');
            }
        ).finally(() => {
                setIsSending(false);
            }
        );
    }

    document.title = "Новости";   //for meta title
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumbs title="Новости" breadcrumbItem="Новости"/>
                    {loading ? <Loader/> :
                        <Row>
                            {(allNews?.slice(0, 50) || []).map((item, key) => (
                                <Col lg={6} key={key}>
                                    <Card className="border cursor-pointer" onClick={() => {
                                        setCurrentNews(item);
                                        tog_center()
                                    }}>
                                        <CardBody>
                                            <div className="d-sm-flex">
                                                <div className="img-container">
                                                    <img src={item.cover ? item.cover : item.images[0] ? item.images[0] : placeholder}
                                                         alt="Обложка новости" className="rounded-1 img-cover"/>
                                                </div>
                                                <div className="flex-grow-1 ms-sm-4 mt-1 mt-sm-0">
                                                    <h5 className="text-truncate text-truncate-two-lines">
                                                        <span>{removeHTMLTags(unescapeHTML(item.title)).substring(0, 100)}</span></h5>
                                                    {
                                                        item.text && (
                                                            <div className="text-muted mb-2 line-clamp-4">
                                                                {removeHTMLTags(unescapeHTML(item.text)).length > 100 ? removeHTMLTags(unescapeHTML(item.text)).substring(0, 100) + '...' : removeHTMLTags(unescapeHTML(item.text))}
                                                            </div>
                                                        )
                                                    }
                                                    <div>
                                                        <div
                                                            className={"d-flex justify-content-between align-items-center"}>
                                                            <div>
                                                                <div><i
                                                                    className="ri-user-2-fill text-success align-middle me-1"></i>{item.author.split(' ')[0] + ' ' + item.author.split(' ')[1]}
                                                                </div>
                                                                <div><i
                                                                    className="ri-calendar-2-fill text-success align-middle me-1"></i> {new moment(item.createDate).format('DD MMMM, HH:mm')}
                                                                </div>
                                                            </div>
                                                            <div className={"flex-shrink-0"}><i
                                                                className="ri-chat-1-line text-success align-middle me-1"></i> {item.comments.length}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>}
                    <ToastContainer autoClose={2000} limit={1}/>
                </Container>
            </div>

            {currentNews && !loading && <Modal
                size="xl"
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalBody>
                    <div className="d-flex justify-content-between"><h4 className="mb-3">{removeHTMLTags(unescapeHTML(currentNews.title))}</h4>
                        <CloseButton onClick={() => tog_center()}/></div>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"><i
                            className="ri-user-3-fill text-success align-middle me-1"></i> {currentNews.author} </li>
                        <li className="list-inline-item"><i
                            className="ri-calendar-2-fill text-success align-middle me-1"></i> {currentNews.createDate}
                        </li>
                    </ul>
                    <div className="p-2">
                        <img src={currentNews.cover ? currentNews.cover : currentNews.images[0]} alt={"cover"}
                             width={500} className={"img-fluid max-width-80 rounded"}/>
                    </div>
                    <div className="mt-1 p-4">
                        <div
                            dangerouslySetInnerHTML={{__html: sanitizeHtml(currentNews.text, {allowedTags: ['p', 'a', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6']})}}></div>
                    </div>
                    <Card className={"mt-4"}>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-title mb-0 flex-grow-1">Комментарии ({currentNews.comments.length})</h4>
                        </CardHeader>

                        <CardBody>
                            <SimpleBar autoHide={false}
                                       style={{height: `${currentNews.comments.length > 3 ? '300px' : '120px'}`}}
                                       className="px-3 mx-n3 mb-2">
                                {
                                    currentNews.comments.map((item, key) => (
                                        <div className="mb-4 ms-3" key={key}>
                                            <h5 className="fs-14 fw-bold">{item.author}<small
                                                className="text-muted ms-2">{item.createDate}</small></h5>
                                            <div className="text-muted">{cleanText(unescapeHTML(item.text))}</div>
                                        </div>
                                    ))
                                }
                            </SimpleBar>
                            <form className="mt-4">
                                <Row className="g-3">
                                    <Col xs={12}>
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label text-body">Оставить
                                            комментарий</label>
                                        <textarea
                                            className="form-control bg-light border-light"
                                            id="exampleFormControlTextarea1" rows="3"
                                            value={myComment} onChange={(e) => setMyComment(e.target.value)}
                                            placeholder="Введите комментарии..."></textarea>
                                    </Col>
                                    <Col xs={12} className="text-end">
                                        <Button
                                            onClick={submitComment}
                                            color="success" disabled={isSending} className="btn btn-success">
                                            {isSending ?
                                                <Spinner size="sm" className='me-2'> Отправка... </Spinner> : null}
                                            Отправить
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>}
        </>
    );
}

export default NewsPage;