import React from "react";
import {Card, CardBody, CardHeader} from "reactstrap";
import {extractIframeSrc} from "../../../api/formattingHelpers";

const EventDescription = ({event}) => {
    return (
        <React.Fragment>
            <Card>
                <CardHeader>
                    <img src={event.kartinka_meropriyatiya} width="auto" height={350} alt="Картинка мероприятия"
                         className="rounded-1 img-fluid"/>
                </CardHeader>
                <CardBody>
                    <div className={"p-2 fs-14 text-start tryFormatMultilineString"} >

                        {event.opisanie}
                    </div>
                    {
                        event.yandex_iframe &&
                        <div className="ratio ratio-4x3">
                            <iframe
                                src={extractIframeSrc(event.yandex_iframe)}
                                width={100} height={250} allowFullScreen={true}>
                            </iframe>
                        </div>
                    }
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default EventDescription;
