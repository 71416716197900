import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import {Link} from 'react-router-dom';
import Masonry from "react-masonry-component";
import {getGallery} from "../../api/gallery";
import galleryMock from "../../pages/Gallery/galleryMock.json";

const Gallery = () => {
    document.title = "Галерея";
    const [displayCategory, setCategory] = useState("Все");
    const [gallery, setGallery] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getGallery().then(
            response => {
                setGallery( response?.filter(item => item.soderzhimoe_alboma && item.soderzhimoe_alboma.length > 0).map(item => {
                    item.category = [];
                    if (item.teg && item.teg.length > 0) {
                        item.category = item.teg.map(tag => tag.name);
                    }
                    return item;
                }));
            }
        );
    }, []);

    useEffect(() => {
        const tags = gallery.map(item => item.teg);
        const flattenedTags = [].concat(...tags);
        const uniqueTags = Array.from(new Set(flattenedTags.map(tag => tag?.name)));
        setCategories(uniqueTags);
    }, [gallery]);


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Галерея" pageTitle="Галерея"/>
                    <Row>
                        <Col lg={12}>
                            <div className="">
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center">
                                                <ul className="list-inline categories-filter animation-nav" id="filter">
                                                    <li className="list-inline-item">
                                                        <Link to="#" onClick={() => setCategory("Все")}
                                                              className={displayCategory === "Все" ? "categories active" : "categories"}
                                                              data-filter="*">Все</Link></li>
                                                    {
                                                        categories.map((category, key) => (
                                                            <li className="list-inline-item" key={key}>
                                                                <Link to="#"
                                                                      onClick={() => setCategory(category)}
                                                                      className={displayCategory === category ? "categories active" : "categories"}
                                                                      data-filter={`.${category}`}>{category}</Link>
                                                            </li>

                                                        ))
                                                    }
                                                </ul>
                                            </div>

                                            <Masonry className="row gallery-wrapper">
                                                {(gallery.filter(({category}) => category?.includes(displayCategory) || displayCategory === "Все")).map(({__id, __name, cover, category}, key) => (
                                                    <Col xxl={4} xl={6} sm={6}
                                                         className="element-item project designing development"
                                                         key={key}>
                                                        <Card className="gallery-box">
                                                            <div className="gallery-container">
                                                                <Link className="image-popup" to={`album/?album=${__id}`} title="">
                                                                    <img className=" rounded-1 img-cover-gal"
                                                                         src={cover} alt=""/>
                                                                </Link>
                                                            </div>

                                                            <div className="box-content mt-2">
                                                                <h5 className="mt-0">{__name}</h5>
                                                                <div className="d-flex align-items-center mt-1 gap-1 flex-wrap">
                                                                    {(category && category.length > 0) && (
                                                                        category.slice(0, 4).map((cur_category, key) => (
                                                                            <div
                                                                                className="badge badge-gradient-info fs-11"
                                                                                key={key}>
                                                                                {cur_category.length > 10 ? cur_category.slice(0, 10) + "..." : cur_category}
                                                                            </div>

                                                                        )))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))}

                                            </Masonry>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </Col>
                    </Row>


                </Container>

            </div>
        </React.Fragment>
    );
};

export default Gallery;