import React, {useState} from "react";
import {Link} from "react-router-dom";
import {Card, CardBody, CardHeader, Table,} from "reactstrap";
import SimpleBar from "simplebar-react";
import moment from "moment/moment";
import "moment/locale/ru";
import {getFio} from "../../../api/formattingHelpers";

moment.locale("ru");

const RightSection = ({event, allUsers, userId, sendData, isSending}) => {

    const isKtoPridet = event.kto_pridet && event.kto_pridet.includes(userId);
    const isKtoNePridet = event.kto_ne_pridet_otmechayutsya_sami && event.kto_ne_pridet_otmechayutsya_sami.includes(userId);

    const [allUsersData, setAllUsersData] = useState(allUsers);

    const changeDecision = (event) => {
        if (isKtoPridet) {
            sendData(event, "kto_ne_pridet_otmechayutsya_sami")
        } else  {
            sendData(event, "kto_pridet")
        }
    }

    return (
        <React.Fragment>
            <Card className="card">
                <CardHeader className="card-header">
                    <h5 className="mb-0">Мероприятие</h5>
                </CardHeader>
                <CardBody className="card-body">
                    <div className="table-responsive table-card">
                        <Table className="table mb-0">
                            <tbody>
                            <tr>
                                <td className="fw-medium">Название:</td>
                                <td>{event?.__name}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">Место:</td>
                                <td>{event?.mesto_provedeniya}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">Дата:</td>
                                <td>{moment(event.__startAt).format('DD MMMM')}</td>
                            </tr>
                            <tr>
                                <td className="fw-medium">Время:</td>
                                <td>
                                    {moment(event.__startAt).format('HH:mm') + ' - ' + moment(event.__endAt).format('HH:mm')}
                                </td>
                            </tr>

                            {event?.otvetstvennyi_za_meropriyatie && <tr>
                            <td className="fw-medium">Ответственный:</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className="flex-grow-1">
                                        <h5 className="fs-13 mb-0">
                                                <span className="text-body d-block">
                                                    {getFio(event?.otvetstvennyi_za_meropriyatie)}</span></h5>
                                    </div>
                                </div>
                            </td>
                            </tr>
                            }
                            <tr>
                                <td className="fw-medium">Телефон ответственного:</td>
                                <td><Link
                                    to={`tel:${event?.telefon_otvetstvennogo?.[0].tel}`}>{event?.telefon_otvetstvennogo?.[0].tel}</Link>
                                </td>
                            </tr>
                            {event?.kontaktnoe_lico_na_meste &&
                                <>
                                    <tr>
                                        <td className="fw-medium">Контактное лицо на месте:</td>
                                        <td>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <h5 className="fs-13 mb-0">
                                                        <span className="text-body d-block">
                                                            {getFio(event.kontaktnoe_lico_na_meste)}</span></h5>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="fw-medium">Телефон контактного лица на месте:</td>
                                        <td><Link
                                            to={`tel:${event?.telefon_kontaktnogo_lica?.[0].tel}`}>{event?.telefon_kontaktnogo_lica?.[0].tel}</Link>
                                        </td>
                                    </tr>
                                </>
                            }
                            <tr>
                                <td className="fw-medium">Статус:</td>
                                <td>{event?.__status.status === 1 ?
                                    <div className="badge bg-success fs-12">Открыто</div> :
                                    <div className="badge bg-danger fs-12">Закрыто</div>
                                }</td>
                            </tr>
                            </tbody>
                        </Table>
                    </div>
                    {
                        (event?.__status.status === 1 && moment(event?.__endAt).isAfter(moment())) &&
                        <div className="mt-4 pt-2 hstack d-flex flex-column gap-2">
                            {!(isKtoPridet || isKtoNePridet) ?
                              <div className="vstack gap-2">
                                  <button onClick={() => {sendData(event, "kto_pridet", true)}} type="button"
                                          className={`btn btn-success w-100 ${isSending ? 'disabled' : ''}`}>
                                      {isSending ? 'Сохранение...' : 'Принять'}
                                  </button>
                                  <button onClick={() => {sendData(event, "kto_ne_pridet_otmechayutsya_sami", false)}} type="button"
                                          className={`btn btn-danger w-100 ${isSending ? 'disabled' : ''}`}>
                                      {isSending ? 'Сохранение...' : 'Отклонить'}
                                  </button>
                              </div>
                              :
                              <div>
                                  { isKtoPridet &&
                                    <button className={`btn w-100 disabled bg-success-subtle`}>Вы придете</button>

                                  }
                                  { isKtoNePridet &&
                                    <button className={`btn w-100 disabled bg-danger-subtle`}>Вы не придете</button>
                                  }

                                  { isKtoPridet &&
                                    <span className="cursor-pointer text-info text-decoration-underline" onClick={() => sendData(event, "kto_pridet", false)}>Не сможете присутствовать?</span>
                                  }
                                  { isKtoNePridet &&
                                    <span className="cursor-pointer text-info text-decoration-underline" onClick={() => sendData(event, "kto_ne_pridet_otmechayutsya_sami", true)}>Сможете присутствовать?</span>
                                  }
                              </div>
                            }
                        </div>
                    }
                </CardBody>
            </Card>

            <Card>
                <CardHeader className="align-items-center d-flex border-bottom-dashed">
                    <h4 className="card-title mb-0 flex-grow-1">Приглашены</h4>
                </CardHeader>
                <CardBody>
                    <SimpleBar data-simplebar style={{height: "235px"}} className="mx-n3 px-3">
                        <div className="vstack gap-3">
                            {
                                event.__participants.map((item, key) => (
                                    <Link to={`/profile?user_id=${item}`} className="d-flex align-items-center"
                                          key={key}>
                                        <div className="avatar-xs flex-shrink-0 me-3">
                                            <img src={allUsersData?.find(user => user.__id === item)?.avatar} alt=""
                                                 className="img-fluid rounded-circle"/>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="fs-13 mb-0">
                                                <span
                                                    className="text-body d-block">{allUsersData?.find(user => user.__id === item)?.__name}</span>
                                            </h5>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    </SimpleBar>

                </CardBody>
            </Card>

            <Card>
                <CardHeader className="align-items-center d-flex border-bottom-dashed">
                    <h4 className="card-title mb-0 flex-grow-1">Подтвержденные участники</h4>
                </CardHeader>
                <CardBody>
                    <SimpleBar data-simplebar style={{height: "235px"}} className="mx-n3 px-3">
                        <div className="vstack gap-3">
                            {
                                event.kto_pridet?.map((item, key) => (
                                    <Link to={`/profile?user_id=${item}`} className="d-flex align-items-center"
                                          key={key}>
                                        <div className="avatar-xs flex-shrink-0 me-3">
                                            <img src={allUsersData?.find(user => user.__id === item)?.avatar} alt=""
                                                 className="img-fluid rounded-circle"/>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h5 className="fs-13 mb-0">
                                                <span
                                                    className="text-body d-block">{allUsersData?.find(user => user.__id === item)?.__name}</span>
                                            </h5>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                    </SimpleBar>
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="align-items-center d-flex border-bottom-dashed">
                    <h4 className="card-title mb-0 flex-grow-1">Отклонившие</h4>
                </CardHeader>
                <CardBody>
                    <SimpleBar data-simplebar style={{height: "235px"}} className="mx-n3 px-3">
                        <div className="vstack gap-3">
                            {
                                event.kto_ne_pridet_otmechayutsya_sami?.map((item, key) => (
                                  <Link to={`/profile?user_id=${item}`} className="d-flex align-items-center"
                                        key={key}>
                                      <div className="avatar-xs flex-shrink-0 me-3">
                                          <img src={allUsersData?.find(user => user.__id === item)?.avatar} alt=""
                                               className="img-fluid rounded-circle"/>
                                      </div>
                                      <div className="flex-grow-1">
                                          <h5 className="fs-13 mb-0">
                                                <span
                                                  className="text-body d-block">{allUsersData?.find(user => user.__id === item)?.__name}</span>
                                          </h5>
                                      </div>
                                  </Link>
                                ))
                            }
                        </div>
                    </SimpleBar>
                </CardBody>
            </Card>


        </React.Fragment>
    );
};

export default RightSection;
