import {apiError, loginSuccess, logoutUserSuccess, reset_login_flag} from './reducer';
import {getFullProfileById, getMyElmaUser} from "../../../api/myAPI";
import {redirect} from "react-router-dom";
import {toast} from "react-toastify";
import Cookies from 'js-cookie';

export const loginUser = () => async (dispatch) => {
    getMyElmaUser().then(r => {
        if (r) {
            getFullProfileById(r['__id']).then(t => {
                dispatch(loginSuccess(t));
                localStorage.setItem("authUser", JSON.stringify(t));
                sessionStorage.setItem("authUser", JSON.stringify(t));
                window.location.href = "/dashboard";
            });
        }
    }).catch(e => {
        redirect("https://hr.mc-medprof.ru/sso-login");
    })
};


export const logoutUser = () => async (dispatch) => {
    try {
        sessionStorage.removeItem("authUser");
        localStorage.removeItem("authUser");
        Cookies.remove(".AspNetCore.cookie");
        toast.success("Вы вышли из системы");
        dispatch(logoutUserSuccess(true));
        redirect("https://hr.mc-medprof.ru/sso-logout");
    } catch (error) {

        dispatch(apiError(error.message));
    }
};
export const resetLoginFlag = () => async (dispatch) => {
    try {
        return dispatch(reset_login_flag());
    } catch (error) {
        dispatch(apiError(error));
    }
};