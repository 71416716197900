import {changeDevMode} from "./reducer";



export const changeDevModeThunk = (devmode) => async (dispatch) => {
    try {
        sessionStorage.setItem("devmode", devmode);
        dispatch(changeDevMode(devmode));
    } catch (error) { }
};

export const loadDevModeFromSessionStorageThunk = () => async (dispatch) => {
    try {
        // Load devMode from session storage
        const devMode = sessionStorage.getItem("devmode");
        // Dispatch action to update Redux state
        if (devMode !== null) {
            dispatch(changeDevMode(devMode === "true"));
        }
        else {
            dispatch(changeDevMode(false));
        }
    } catch (error) {
        console.error("Error loading devMode from session storage:", error);
    }
};