import {createAsyncThunk} from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {getElmaNews} from "../../api/news";
import mockNews from "../../pages/NewNews/mockNews.json";
import moment from "moment";

export const getElmaNewsList = createAsyncThunk("news/getElmaNews", async () => {
    try {
        const news = await getElmaNews().catch(() => mockNews);
        const sluzheb = news.filter(x=>x.sluzhebnaya_novost).sort((a, b) => moment(b.__createdAt).subtract(moment(a.__createdAt))).slice(0,25);
        const novosti = news.filter(x=>!x.sluzhebnaya_novost).sort((a, b) => moment(b.__createdAt).subtract(moment(a.__createdAt))).slice(0,25);
        return [...sluzheb, ...novosti];
    } catch (error) {
        return error;
    }
});

