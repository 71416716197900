import { createSlice } from "@reduxjs/toolkit";
import {getElmaNewsList} from './thunk';

export const initialState = {
    newsList: [],
    error: {},
};

const ElmNewsSlice = createSlice({
    name: 'ElmaNews',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getElmaNewsList.fulfilled, (state, action) => {
            state.newsList = action.payload;
        });
        builder.addCase(getElmaNewsList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default ElmNewsSlice.reducer;