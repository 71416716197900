import React, {useEffect, useState} from 'react';
import usersData from './mockUsers.json';
import {Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem} from "reactstrap";
import {Link} from "react-router-dom";

const FolderStructure = ({users = usersData, allGroups}) => {

    function getParents(group) {
        const parents = [];
        let currentGroup = group;

        while (currentGroup.parent !== null) {
            const parentGroup = allGroups.find(g => g.__id === currentGroup.parent);
            if (parentGroup) {
                parents.push(parentGroup);
                currentGroup = parentGroup;
            } else {
                break;
            }
        }

        return parents;
    }

    const allFolded = allGroups.reduce((acc, cur) => {
        acc[cur.__id] = true;
        return acc;
    });


    const [filteredGroups, setFilteredGroups] = useState(allGroups);
    const [foldedGroups, setFoldedGroups] = useState(allFolded);

    function toggleGroup(groupId) {
        setFoldedGroups(prevState => ({...prevState, [groupId]: !prevState[groupId]}));
    }

    function toggleGroupAll(state) {
        if (state) setFoldedGroups(allFolded);
        else setFoldedGroups({});
    }

    useEffect(() => {
        const includedGroupIds = new Set();
        users.forEach((user) => {
            user.osIds?.forEach((groupId) => {
                includedGroupIds.add(groupId);
                const group = allGroups.find(g => g.__id === groupId);
                if (group) {
                    const parentGroups = getParents(group);
                    parentGroups.forEach(parentGroup => includedGroupIds.add(parentGroup.__id));
                }
            });
        });
        setFilteredGroups(allGroups);
    }, [users, allGroups]);

    const Group = ({parent_id, nestLevel}) => {
        if (filteredGroups.filter(g => g.parent === parent_id).length === 0) return null;
        return (
            <ListGroupItem className={`nested-${nestLevel+2}`}>
                {filteredGroups.filter(g => g.parent === parent_id).map((group) =>
                    <React.Fragment key={group.__id}>
                        <div className="btn-group btn-group-sm align-items-center d-sm-flex">
                            <div className="btn-group btn-group-sm align-items-center d-sm-flex cursor-pointer" onClick={() => toggleGroup(group.__id)}>
                                <i className={`fs-24 ${!foldedGroups[group.__id] ? 'ri-arrow-down-s-line' : 'ri-arrow-right-s-line'}`}/>
                                {group.type === "DEPARTMENT" &&
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"
                                         width="36px" height="36px" className="ng-star-inserted">
                                        <rect fill="#A7A9AC" height="24" width="18.591" x="6.705"
                                              y="4.157"></rect>
                                        <rect fill="#FFFFFF" height="3.2" width="5.922" x="13.039"
                                              y="6.87"></rect>
                                        <rect fill="#FFFFFF" height="1.6" width="3.259" x="19.6"
                                              y="16.856"></rect>
                                    </svg>
                                }
                                {
                                    group.type === "GROUP" &&
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36"
                                         width="36px" height="36px" className="ng-star-inserted">
                                        <path
                                            d="M18.596,20.351v-1c1.895-1.351,2.356-3.455,2.446-3.991c0.148,0.015,0.312-0.008,0.473-0.107c0,0,1.615-0.983,1.043-2.933c0,0-0.27-0.574-0.947-0.643c0.227-2.448,0.115-4.262,0.115-4.262c-0.938-3.791-5.665-3.399-5.665-3.399s-4.727-0.392-5.665,3.399c0,0-0.111,1.813,0.116,4.262c-0.678,0.069-0.948,0.643-0.948,0.643c-0.571,1.95,1.045,2.933,1.045,2.933c0.159,0.099,0.323,0.122,0.471,0.107c0.087,0.537,0.551,2.641,2.448,3.991v1c-4.69,0.797-7.324,3.334-7.324,3.334C4.97,24.96,5.807,28.31,5.807,28.31h20.511c0,0,0.836-3.35-0.398-4.625C25.919,23.685,23.284,21.147,18.596,20.351z"
                                            fill="#A7A9AC"></path>
                                        <path
                                            d="M30.864,21.624c0,0-1.844-1.771-5.117-2.328v-0.7c1.322-0.944,1.645-2.414,1.709-2.789c0.104,0.012,0.219-0.006,0.328-0.075c0,0,1.129-0.688,0.73-2.05c0,0-0.188-0.401-0.662-0.448c0.16-1.713,0.08-2.98,0.08-2.98c-0.656-2.648-3.957-2.375-3.957-2.375s-0.611-0.049-1.357,0.103c0.014,0.645,0.014,1.746-0.084,3.099c0.537,0.316,0.779,0.795,0.814,0.87l0.047,0.125c0.598,2.047-0.67,3.463-1.426,3.922c-0.064,0.039-0.129,0.073-0.197,0.101c-0.105,0.399-0.285,0.916-0.562,1.466c0.245,0.364,0.562,0.727,0.993,1.033v0.7c-0.73,0.124-1.376,0.313-1.958,0.523c3.955,1.043,6.173,3.134,6.279,3.236c0.461,0.479,0.709,1.123,0.834,1.801h3.781C31.14,24.856,31.726,22.519,30.864,21.624z"
                                            fill="#A7A9AC"></path>
                                        <path
                                            d="M11.877,19.82c-0.583-0.21-1.229-0.399-1.957-0.523v-0.7c0.431-0.306,0.748-0.669,0.99-1.033c-0.275-0.55-0.453-1.067-0.56-1.463c-0.067-0.031-0.132-0.065-0.196-0.102c-0.756-0.461-2.026-1.875-1.427-3.924l0.047-0.125c0.034-0.075,0.276-0.554,0.813-0.87C9.491,9.727,9.491,8.626,9.503,7.981C8.757,7.83,8.148,7.879,8.148,7.879S4.845,7.606,4.19,10.254c0,0-0.079,1.268,0.08,2.98c-0.475,0.047-0.662,0.448-0.662,0.448c-0.397,1.362,0.729,2.05,0.729,2.05c0.112,0.068,0.226,0.086,0.33,0.075c0.063,0.375,0.386,1.845,1.71,2.789v0.7c-3.275,0.556-5.119,2.328-5.119,2.328c-0.861,0.895-0.277,3.232-0.277,3.232h3.777c0.12-0.67,0.363-1.311,0.817-1.781C5.7,22.956,7.922,20.863,11.877,19.82z"
                                            fill="#A7A9AC"></path>
                                    </svg>
                                }
                            </div>
                            {
                                group.type === "POSITION" && (
                                    <Link className="d-flex align-items-center mb-2 mt-1"
                                          onClick={() => toggleGroup(group.__id)}

                                    >
                                        {(() => {
                                            let positionUser = users.find((user) =>
                                                user.groupIds?.includes(group.__id) ||
                                                user.osIds?.includes(group.__id) ||
                                                user.__id === group.__id
                                            );

                                            return (
                                                <>
                                                    {positionUser ?
                                                        <div className="avatar-xs flex-shrink-0 me-2">
                                                            <img src={positionUser?.avatar} alt=""
                                                                 className="img-fluid rounded-circle"/>
                                                        </div> :
                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                             viewBox="0 0 36 36" width="36px" height="36px"
                                                             className="ng-star-inserted">
                                                            <path
                                                                d="M26.967,24.535c0,0-3.024-2.907-8.403-3.822v-1.148c2.176-1.549,2.706-3.963,2.809-4.579c0.17,0.018,0.357-0.009,0.541-0.121c0,0,1.852-1.13,1.197-3.365c0,0-0.309-0.658-1.088-0.738c0.262-2.81,0.133-4.89,0.133-4.89c-1.075-4.348-6.498-3.899-6.498-3.899s-5.424-0.448-6.5,3.899c0,0-0.129,2.08,0.133,4.89c-0.779,0.08-1.088,0.738-1.088,0.738c-0.654,2.235,1.197,3.365,1.197,3.365c0.186,0.112,0.372,0.139,0.541,0.121c0.104,0.616,0.633,3.03,2.809,4.579v1.148c-5.378,0.915-8.402,3.822-8.402,3.822c-1.416,1.466-0.455,5.309-0.455,5.309h23.53C27.422,29.844,28.382,26.001,26.967,24.535z"
                                                                fill="#A7A9AC"></path>
                                                            <path
                                                                d="M17.157,23.853c0.08-0.094,0.08-0.232,0-0.326l-1.311-1.529c-0.047-0.055-0.116-0.087-0.189-0.087c-0.072,0-0.143,0.032-0.189,0.087l-1.313,1.529c-0.08,0.094-0.08,0.232,0.001,0.326l1.224,1.425l-1.275,4.498c-0.021,0.075-0.006,0.156,0.042,0.219c0.047,0.063,2.974,0.063,3.021,0s0.063-0.144,0.041-0.219l-1.273-4.497L17.157,23.853z"
                                                                fill="#FFFFFF"></path>
                                                        </svg>}
                                                    <div>
                                                        <div>
                                                            <Link  to={`/profile?user_id=${positionUser?.__id}`}><h5 className="fs-16 mb-1 text-decoration-underline">{positionUser?.__name}</h5></Link>
                                                        </div>
                                                        <div >
                                                            <h5 className="fs-12 mb-0">{group.name}</h5>
                                                        </div>
                                                    </div>
                                                </>
                                            );
                                        })()}
                                    </Link>
                                )
                            }

                            {
                                group.type !== "POSITION" &&
                                <Link className="d-flex align-items-center mb-2 mt-1 "
                                      onClick={() => toggleGroup(group.__id)}
                                >{group.name}</Link>
                            }
                        </div>
                        {
                            (!foldedGroups[group.__id] && group.type !== "POSITION" && users.filter((user) => user.groupIds?.includes(group.__id) || user.osIds?.includes(group.__id) || user.__id === group.__id).length>0) && (
                                <ListGroupItem className={`nested-${nestLevel + 2} px-4`}>

                                    {users.filter((user) => user.groupIds?.includes(group.__id) || user.osIds?.includes(group.__id) || user.__id === group.__id).map((user) => (
                                        <Link className="d-flex align-items-lg-start mb-2 mt-1 me-2">
                                            <div className="avatar-xs flex-shrink-0 me-2">
                                                <img src={user.avatar} alt="" className="img-fluid rounded-circle"/>
                                            </div>
                                            <div className="flex-grow-1 align-self-center">
                                                <Link to={`/profile?user_id=${user?.__id}`}><h5
                                                    className="fs-14 mb-0 text-decoration-underline">{user.__name}</h5>
                                                </Link>
                                            </div>
                                        </Link>
                                    ))}
                                </ListGroupItem>)
                        }
                        {
                            !foldedGroups[group.__id] && (
                                <Group key={group.__id} parent_id={group.__id} nestLevel={nestLevel + 1}/>
                            )
                        }
                    </React.Fragment>
                )}
            </ListGroupItem>)
    }

    return (
        <Col lg={12}>
            <Card>
                <CardHeader>
                    <h4 className="card-title mb-0">Оргструктура</h4>
                </CardHeader>
                <CardBody>
                    <button onClick={() => toggleGroupAll(false)} className="btn btn-sm btn-primary me-2 align-middle">
                        <i className="ri-arrow-down-s-line align-bottom"></i>Развернуть все
                    </button>
                    <button onClick={() => toggleGroupAll(true)} className="btn btn-sm btn-primary me-2 align-middle">
                        <i className="ri-arrow-up-s-line align-bottom"></i>Свернуть все
                    </button>

                    <ListGroup className="nested-list nested-sortable">
                        {
                            allGroups.filter((group) => group.parent === null).map((group) => (
                                <React.Fragment key={group.__id}>
                                    {users.filter((user) => user.groupIds?.includes(group.__id) || user.osIds?.includes(group.__id) || user.__id === group.__id).map((user) => (
                                        <Link className="d-flex align-items-center mb-2 mt-1 px-lg-4">
                                            <div className="avatar-xs flex-shrink-0 me-3">
                                                <img src={user.avatar} alt="" className="img-fluid rounded-circle"/>
                                            </div>
                                            <div>
                                                <div>
                                                    <Link to={`/profile?user_id=${user?.__id}`}><h5
                                                        className="fs-16 mb-1 text-decoration-underline">{user?.__name}</h5>
                                                    </Link>
                                                </div>
                                                <div>
                                                    <h5 className="fs-12 mb-0">{group.name}</h5>
                                                </div>
                                            </div>
                                        </Link>
                                    ))}
                                    <Group parent_id={group.__id}
                                           nestLevel={0}
                                    />
                                </React.Fragment>
                            ))
                        }
                    </ListGroup>
                </CardBody>
            </Card>
        </Col>
    );
};

export default FolderStructure;