import React, {useEffect, useRef, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CloseButton,
    Col,
    Container,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Row,
    Spinner
} from "reactstrap";

//Import Breadcrumb
import BreadCrumbs from "../../Components/Common/BreadCrumb";
import SimpleBar from "simplebar-react";
import moment from "moment";
import {getCommentsByNews, sendCommentElma, sendLike, sendView} from "../../api/news";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {toast, ToastContainer} from "react-toastify";

//import placeholder
import placeholder from "../../assets/images/small/img-1.jpg";
import {getElmaNewsList} from "../../slices/elmaNews/thunk";
import {getUsersList} from "../../slices/users/thunk";

import avatar3 from "../../assets/images/users/avatar-3.jpg";
import classnames from "classnames";
import Loader from "../../Components/Common/Loader";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {Link} from "react-router-dom";
import InvoiceModal from "../Invoices/InvoiceModal";
import {marked} from "marked";

const NewsPage = () => {
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));

    const [modal_center, setmodal_center] = useState(false);
    const [currentNews, setCurrentNews] = useState(null);
    const dispatch = useDispatch();


    const selectDashboardData = createSelector(
        (state) => state.ElmaNews.newsList,
        (newsList) => newsList
    );

    const selectUsersData = createSelector(
        (state) => state.Users.usersList,
        (usersList) => usersList
    );

    const newsList = useSelector(selectDashboardData);
    const usersList = useSelector(selectUsersData);

    const [allNews, setAllNews] = useState([]);

    const [myComment, setMyComment] = useState(null);
    const [isSending, setIsSending] = useState(false);
    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentComments, setCurrentComments] = useState([]);
    const [currentLiked, setCurrentLiked] = useState(false);

    const displayWidth = window.innerWidth;

    const [activeTab, setActiveTab] = useState("1");

    const commentsEl = useRef(null);

    // Function to scroll to the bottom of the comments container
    const scrollToBottom = () => {
        if (commentsEl.current) {
            const scrollElement = commentsEl.current.getScrollElement();
            const lastComment = scrollElement.lastElementChild;
            if (lastComment) {
                lastComment.scrollIntoView({behavior: 'smooth', block: 'end'});
            }
        }
    };


    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    function tog_center() {
        setmodal_center(!modal_center);
    }

    async function getCurCommentsByNews(newsId) {
        await sendView(newsId, currentNews?.kto_posmotrel || []);
        return await getCommentsByNews(newsId);
    }

    useEffect(() => {
        if (currentNews) {
            getCurCommentsByNews(currentNews.__id).then((comments) => {
                setCurrentComments(comments);
            })
        }
        setMyComment('');
        setCurrentLiked(currentNews?.kto_laiknul?.includes(currentUser?.__id) || false);
        scrollToBottom();
    }, [currentNews]);

    useEffect(() => {
        setLoading(true);
        dispatch(getElmaNewsList());
        dispatch(getUsersList());
    }, [dispatch]);

    useEffect(() => {
        setAllNews(newsList);
    }, [dispatch, newsList]);

    useEffect(() => {
        setAllUsers(usersList);
        setLoading(false);
    }, [dispatch, usersList]);

    const renderer = new marked.Renderer();

// Override the default rendering for images
    renderer.image = function(href, title, text) {
        return `<img src="${href}" alt="${text}" style="max-width: 80%; height: auto; display: block;">`;
    };


    const submitLike = async () => {
        setIsSending(true);
        await sendLike(currentNews.__id, currentNews?.kto_laiknul || []).then(
            () => {
                if (!currentLiked) {
                    setCurrentLiked(true);
                    setCurrentNews({
                        ...currentNews,
                        kto_laiknul: (currentNews?.kto_laiknul || []).concat(currentUser?.__id),
                        kollichestvo_laikov: currentNews.kollichestvo_laikov + 1
                    });
                } else {
                    setCurrentLiked(false);
                    setCurrentNews({
                        ...currentNews,
                        kto_laiknul: (currentNews?.kto_laiknul || []).filter(id => id !== currentUser?.__id),
                        kollichestvo_laikov: currentNews.kollichestvo_laikov>0 ? currentNews.kollichestvo_laikov - 1 : currentNews.kollichestvo_laikov
                    });
                }
            }
        ).finally(() => {
                setIsSending(false);
                dispatch(getElmaNewsList());
            }
        );
    }


    const submitComment = async () => {
        setIsSending(true);
        await sendCommentElma(currentNews.__id, myComment, currentComments.length || 0).then(
            () => {
                setMyComment('');
                toast.success("Комментарий успешно добавлен");
                getCurCommentsByNews(currentNews.__id).then((comments) => {
                    setCurrentComments(comments);
                });
                setCurrentNews(allNews.find(x => x.__id === currentNews.__id));
            }
        )
            .finally(() => {
                    setIsSending(false);
                    dispatch(getElmaNewsList());
                }
            );
    }



    document.__name = "Новости";   //for meta __name
    return (
        <>
            <div className="page-content">

                <Container fluid={true}>
                    <BreadCrumbs __name="Новости" breadcrumbItem="Новости"/>

                    {displayWidth < 768 && <div className="d-flex p-1 bg-light bg-opacity-75 rounded-2 mx-2 me-2 mb-4">
                        <Nav pills className="animation-nav  gap-2 gap-lg-3 flex-grow-1"
                             role="tablist">
                            <NavItem>
                                <NavLink
                                    href={"#sluzhebnaya"}
                                    className={classnames({active: activeTab === "1"})}
                                    onClick={() => {
                                        tabChange("1");
                                    }}>
                                    <i className="fas fa-home"></i>
                                    Служебные
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    href={"#profile"}
                                    className={classnames({active: activeTab === "2"})}
                                    onClick={() => {
                                        tabChange("2");
                                    }}>
                                    <i className="fas fa-home"></i>
                                    Прочие
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>}


                    {
                        loading ? <Loader/> :
                            <Container fluid={true}>
                                <Row lg={12}>

                                    {(activeTab == 1 || displayWidth > 990) && <Col lg={6} md={12} xs={12}>
                                        <h5>Служебные новости ({allNews?.filter(item => item.sluzhebnaya_novost && (item.kto_posmotrel?.findIndex(id => id === currentUser?.__id) === -1 || !item.kto_posmotrel)).length || 0})</h5>

                                        {(allNews?.slice(0, 50) || []).filter(item => item.sluzhebnaya_novost).map((item, key) => (
                                            <Card key={key} className="border cursor-pointer" onClick={() => {
                                                setCurrentNews(item);
                                                tog_center()
                                            }}>
                                                <CardBody className={
                                                    (item.kto_posmotrel?.findIndex(id => id === currentUser?.__id) === -1 || !item.kto_posmotrel) ? "bg-light" : "bg-light-subtle"
                                                }>
                                                    <div className="d-sm-flex">
                                                        <div className="img-container">
                                                            <img
                                                                src={item.kartinka ? item.kartinka : item.kartinka[0] ? item.kartinka[0] : placeholder}
                                                                alt="Обложка новости"
                                                                className="rounded-1 img-cover"/>
                                                        </div>
                                                        <div className="flex-grow-1 ms-sm-4 mt-5 mt-sm-0 ">
                                                            <div
                                                                className="d-flex justify-content-between align-items-center">

                                                                <h5 className="text-truncate text-truncate-two-lines mb-0">
                                                                    <span>{(item.__name).substring(0, 100)}</span></h5>
                                                                {(item.kto_posmotrel?.findIndex(id => id === currentUser?.__id) === -1 || !item.kto_posmotrel) &&
                                                                    <i className="badge bg-warning">новая</i>}
                                                            </div>
                                                            <div>
                                                                {
                                                                    item.tekst_novosti && (
                                                                        <div className="text-muted mb-2 mt-0 line-clamp-2">
                                                                            <Markdown
                                                                                remarkPlugins={[remarkGfm]}>
                                                                                {item.tekst_novosti.length > 99 ?
                                                                                    item.tekst_novosti.split('![')[0].substring(0, 97) + '...' : item.tekst_novosti.split('![')[0]}
                                                                            </Markdown>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>

                                                            <div>
                                                                <div
                                                                    className={"d-flex justify-content-between align-items-center"}>
                                                                    <div>
                                                                        <div><i
                                                                            className="ri-user-2-fill text-success align-middle me-1"></i>{item.avtor_teksta_str}
                                                                        </div>
                                                                        <div><i
                                                                            className="ri-calendar-2-fill text-success align-middle me-1"></i> {new moment(item.__createdAt).format('DD MMMM, HH:mm')}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className={"flex-shrink-0"}><i
                                                                            className="ri-chat-1-line text-success align-middle me-1"></i> {item.kolichestvo_kommentariev || 0}
                                                                        </div>

                                                                        <div className={"flex-shrink-0"}><i
                                                                            className="ri-thumb-up-line text-success align-middle me-1"></i> {item?.kollichestvo_laikov || 0}
                                                                        </div>

                                                                        <div className={"flex-shrink-0"}><i
                                                                            className="ri-eye-line text-success align-middle me-1"></i> {item.kollichestvo_prosmotrov || 0}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </Col>}

                                    {(activeTab == 2 || displayWidth > 990) && <Col lg={6} md={12} xs={12}>
                                        <h5>Прочие новости ({allNews?.filter(item=> !item.sluzhebnaya_novost && (item.kto_posmotrel?.findIndex(id => id === currentUser?.__id) === -1 || !item.kto_posmotrel)).length || 0})</h5>

                                        {(allNews?.slice(0, 50) || []).filter(item => !item.sluzhebnaya_novost).map((item, key) => (
                                            <Card key={key} className="border cursor-pointer" onClick={() => {
                                                setCurrentNews(item);
                                                tog_center()
                                            }}>
                                                <CardBody className={
                                                    (item.kto_posmotrel?.findIndex(id => id === currentUser?.__id) === -1 || !item.kto_posmotrel) ? "bg-light" : "bg-light-subtle"
                                                }>
                                                    <div className="d-sm-flex">
                                                        <div className="img-container">
                                                            <img
                                                                src={item.kartinka ? item.kartinka : item.kartinka[0] ? item.kartinka[0] : placeholder}
                                                                alt="Обложка новости"
                                                                className="rounded-1 img-cover"/>
                                                        </div>
                                                        <div className="flex-grow-1 ms-sm-4 mt-5 mt-sm-0">
                                                            <div
                                                                className="d-flex justify-content-between align-items-center">

                                                                <h5 className="text-truncate text-truncate-two-lines mb-0">
                                                                    <span>{(item.__name).substring(0, 100)}</span></h5>
                                                                    {(item.kto_posmotrel?.findIndex(id => id === currentUser?.__id) === -1 || !item.kto_posmotrel) &&
                                                                        <i className="badge bg-warning">новая</i>}
                                                            </div>
                                                            {
                                                                item.tekst_novosti && (
                                                                    <div className="text-muted mb-2 mt-0 line-clamp-2">
                                                                        <Markdown
                                                                            remarkPlugins={[remarkGfm]}>
                                                                            {item.tekst_novosti.length > 99 ?
                                                                                item.tekst_novosti.split('![')[0].substring(0, 97) + '...' : item.tekst_novosti.split('![')[0]}
                                                                        </Markdown>
                                                                    </div>
                                                                )
                                                            }
                                                            <div>
                                                                <div
                                                                    className={"d-flex justify-content-between align-items-center"}>
                                                                    <div>
                                                                        <div><i
                                                                            className="ri-user-2-fill text-success align-middle me-1"></i>{item.avtor_teksta_str}
                                                                        </div>
                                                                        <div><i
                                                                            className="ri-calendar-2-fill text-success align-middle me-1"></i> {new moment(item.__createdAt).format('DD MMMM, HH:mm')}
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className={"flex-shrink-0"}><i
                                                                            className="ri-chat-1-line text-success align-middle me-1"></i> {item.kolichestvo_kommentariev || 0}
                                                                        </div>

                                                                        <div className={"flex-shrink-0"}><i
                                                                            className="ri-thumb-up-line text-success align-middle me-1"></i> {item?.kollichestvo_laikov || 0}
                                                                        </div>

                                                                        <div className={"flex-shrink-0"}><i
                                                                            className="ri-eye-line text-success align-middle me-1"></i> {item.kollichestvo_prosmotrov || 0}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </Col>}
                                </Row>
                            </Container>
                    }
                    <ToastContainer autoClose={2000} limit={1}/>
                </Container>
            </div>

            {currentNews && !loading && <Modal
                size="xl"
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalBody>
                    <div className="d-flex justify-content-between"><h4 className="mb-3">{currentNews.__name}</h4>
                        <CloseButton onClick={() => tog_center()}/></div>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"><i
                            className="ri-user-3-fill text-success align-middle me-1"></i> {currentNews.avtor_teksta_str}
                        </li>
                        <li className="list-inline-item"><i
                            className="ri-calendar-2-fill text-success align-middle me-1"></i> {new moment(currentNews.__createdAt).format('DD MMMM, HH:mm')}
                        </li>
                    </ul>
                    <div className="p-2">
                        <img src={currentNews.kartinka ? currentNews.kartinka : currentNews.kartinka[0]}
                             alt={"kartinka"}
                             width={500} className={"img-fluid max-width-80 rounded"}/>
                    </div>
                    <div className="mt-1 p-4">
                        <div dangerouslySetInnerHTML={{__html: marked(currentNews.tekst_novosti, {sanitize: false, breaks: true, pedantic: true, renderer: renderer})}}/>
                    </div>

                    <div className="p-1">
                    <CardBody>
                            {
                                currentLiked ?
                                    <Button color="success" className="btn-ghost-success" onClick={submitLike}><i
                                        className="ri-thumb-up-fill align-middle fs-24 me-1"></i> {currentNews?.kollichestvo_laikov || 0}
                                    </Button> :
                                    <Button color="info" className="btn-ghost-info" onClick={submitLike}><i
                                        className="ri-thumb-up-line align-middle fs-24 me-1"></i> {currentNews?.kollichestvo_laikov || 0}
                                    </Button>
                            }
                        </CardBody>
                    </div>
                    <Card className={"mt-4"}>
                        <CardHeader className="align-items-center d-flex">
                            <h4 className="card-__name mb-0 flex-grow-1">Комментарии ({currentComments.length})</h4>
                        </CardHeader>

                        <CardBody>
                            <SimpleBar autoHide={false}
                                       style={{height: `${currentComments.length > 3 ? '300px' : '120px'}`}}
                                       ref={commentsEl}
                                       className="px-3 mx-n3 mb-2">
                                {
                                    currentComments.map((item, key) => (
                                        <div className="mb-4 ms-3" key={key}>

                                            <Link to={`/profile?user_id=${item.avtor[0]}`}
                                                  className="d-flex align-items-center"
                                                  key={key}>
                                                <div className="d-flex align-items-center">

                                                    <img className="rounded-circle header-profile-user"
                                                         src={allUsers?.find(user => user.__id === item.avtor[0])?.avatar || avatar3}
                                                         alt="Header Avatar"/>

                                                    <div className="text-start ms-2 justify-content-between">
                                                        <h5 className="mx-1 fs-12 ms-2 fw-bold mb-0">{allUsers?.find(user => user.__id === item.avtor[0])?.__name || 'Аноним'}
                                                        </h5>
                                                        <span
                                                            className="text-muted fs-12 ms-2 fw-bold mt-0">{new moment(item.__createdAt).format('DD MMMM, HH:mm')}</span>
                                                    </div>
                                                </div>
                                            </Link>

                                            <div className="text-muted mt-1 px-5"><Markdown
                                                remarkPlugins={[remarkGfm]}>{item.tekst_kommentariya}</Markdown></div>
                                        </div>
                                    ))
                                }
                            </SimpleBar>
                            <form className="mt-4">
                                <Row className="g-3">
                                    <Col xs={12}>
                                        <label htmlFor="exampleFormControlTextarea1" className="form-label text-body">Оставить
                                            комментарий</label>
                                        <textarea
                                            className="form-control bg-light border-light"
                                            id="exampleFormControlTextarea1" rows="3"
                                            value={myComment} onChange={(e) => setMyComment(e.target.value)}
                                            placeholder="Введите комментарии..."></textarea>
                                        <span className="text-muted">Поддерживается Markdown</span>
                                        <div className="text-muted">
                                            <p>Пример: **жирный текст**, *курсив*, [ссылка](https://www.google.com)</p>
                                        </div>
                                    </Col>
                                    <Col xs={12} className="text-end">
                                        <Button
                                            onClick={submitComment}
                                            color="success" disabled={isSending} className="btn btn-success">
                                            {isSending ?
                                                <Spinner size="sm" className='me-2'> Отправка... </Spinner> : null}
                                            Отправить
                                        </Button>
                                    </Col>
                                </Row>
                            </form>
                        </CardBody>
                    </Card>
                </ModalBody>
            </Modal>}
        </>
    );
}

export default NewsPage;