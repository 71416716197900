import React from "react";
import { Navigate } from "react-router-dom";

//pages
import SimplePage from '../pages/Pages/Profile/SimplePage/SimplePage';
import Settings from '../pages/Pages/Profile/Settings/Settings';
import Maintenance from '../pages/Pages/Maintenance/Maintenance';

import Basic404 from '../pages/AuthenticationInner/Errors/Basic404';
import Cover404 from '../pages/AuthenticationInner/Errors/Cover404';
import Error500 from '../pages/AuthenticationInner/Errors/Error500';

import Offlinepage from "../pages/AuthenticationInner/Errors/Offlinepage";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import Company from "../pages/Company";
import Education from "../pages/Education";
import KnowledgeBase from "../pages/KnowledgeBase";
import News from "../pages/News";
import Events from "../pages/Events";
import PollsPage from "../pages/Polls";
import Faqs from "../pages/Pages/Faqs/Faqs";
import HumanResources from "../pages/HumanResources";
import KnowledgeBaseCategory from "../pages/KnowledgeBaseCategory";
import NewNews from "../pages/NewNews";
import Vacancies from "../pages/Vacancies";
import GalleryAlbum from "../pages/Gallery/GalleryAlbum";
import Gallery from "../pages/Gallery";
import Goals from "../pages/Goals";

const authProtectedRoutes = [
  { path: "/dashboard", component: <News /> },
  { path: "/index", component: <News /> },
  { path: "/profile", component: <SimplePage /> },
  { path: "/profile/edit", component: <Settings /> },
  { path: "/orgstruct", component: <Company orgstruct={true}/> },
  { path: "/team", component: <Company orgstruct={false}/> },
  { path: "/goals", component: <Goals /> },
  { path: "/news", component: <News /> },
  { path: "/news-dev", component: <NewNews /> },
  { path: "/news-erp", component: <News /> },
  { path: "/events", component: <Events /> },
  { path: "/education", component: <Education /> },
  { path: "/knowledge", component: <KnowledgeBase /> },
  { path: "/knowledge/category", component: <KnowledgeBaseCategory /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/gallery/album", component: <GalleryAlbum /> },
  { path: "/polls", component: <PollsPage /> },
  { path: "/hr", component: <HumanResources /> },
  { path: "/vacancies", component: <Vacancies /> },

  //404
  { path: "*", component: <Navigate to="/404" /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/news" />,
  },

];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },

  //AuthenticationInner pages
  { path: "/auth-404-basic", component: <Basic404 /> },
  { path: "/404", component: <Cover404 /> },
  { path: "/auth-500", component: <Error500 /> },
  { path: "/pages-maintenance", component: <Maintenance /> },
  { path: "/offline", component: <Offlinepage /> },
  { path: "/test", component: < Faqs /> }

];

export { authProtectedRoutes, publicRoutes };