import {ElmaAPI} from "./myAPI";
import he from 'he';
import mockComments from "../pages/NewNews/mockComments.json";

const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));

const sanitizeHTML = (html) => {
    // Create a temporary DOM element to parse the HTML
    const tempElement = document.createElement('div');
    tempElement.innerHTML = he.decode(html); // Decode HTML entities

    // Remove all <img> elements from the temporary DOM
    const imgElements = tempElement.querySelectorAll('img');
    imgElements.forEach((imgElement) => {
        imgElement.remove();
    });

    // Get the sanitized HTML content
    return tempElement.innerHTML;
};

const parseImageLinks = (html) => {
    // Regular expression to match <img> tags and extract src attribute
    const imgRegex = /<img[^>]*src=["'](https?:\/\/[^"']+)["'][^>]*>/g;
    const imageLinks = [];

    // Decode HTML entities in the HTML
    const decodedHTML = he.decode(html);

    let match;
    while ((match = imgRegex.exec(decodedHTML)) !== null) {
        const imageUrl = match[1];
        imageLinks.push(imageUrl);
    }

    return imageLinks;
};

export const getNews = async (page = 5, limit = 50) => {
    let allNews = [];
    let currentPage = page;

    while (true) {
        const response = await ElmaAPI.get(`/news?page=${currentPage}&limit=${limit}`);
        const news = response.data;

        if (news.length === 0) {
            // Stop fetching if no news was returned on the current page
            break;
        }

        allNews = [...allNews, ...news];
        currentPage++;
    }

    return allNews.map(item => {
        const sanitizedText = sanitizeHTML(item.text);
        const imageLinks = parseImageLinks(item.text);

        return {
            ...item,
            text: sanitizedText,
            images: imageLinks
        };
    }).sort((a, b) => b.id - a.id);
};

export const addComment = async (newsId, text) => {
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
    const newComment = {newsId: newsId, text: text, date: new Date(), author: currentUser.__name};
    return await ElmaAPI.post(`/news/comment`, newComment).then(r => r.data);
}

export const getElmaNews = async () => {
    return await ElmaAPI.get(`/elma_news`).then(r => r.data);
}

export const getCommentsByNews = async (newsId) => {
    return await ElmaAPI.get(`/elma_news_comments?newsId=${newsId}`).then(r => r.data).catch(()=> mockComments);
}

export const sendComment = async (newsId, text) => {
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
    const newComment = {newsId: newsId, text: text, date: new Date(), author: currentUser.__name};
    return await ElmaAPI.post(`/news/comment`, newComment).then(r => r.data);
}

export const sendLike = async (newsId, userList) => {
    let newUserList = [];

    if(userList.includes(currentUser.__id)){
        newUserList = userList.filter(user => user !== currentUser.__id);
    }
    else
    {
        newUserList = [...userList, currentUser.__id];
    }
    const newLike = {newsId: newsId, userList: newUserList};
    return await ElmaAPI.post(`/send_news_like`, newLike).then(r => r.data);
}

export const sendView = async (newsId, userList) => {
    if(!newsId || userList.includes(currentUser.__id)) return;

    const newLike = {newsId: newsId, userList: [...userList, currentUser.__id]};
    return await ElmaAPI.post(`/send_news_view`, newLike).then(r => r.data);
}

export const sendCommentElma = async (newsId, commentText, commentsCount) => {
    if(!newsId || !commentText) return;

    const comment = {newsId: newsId, userId: currentUser.__id, commentText: commentText, commentCount: commentsCount};
    return await ElmaAPI.post(`/send_news_comment`, comment).then(r => r.data);
}