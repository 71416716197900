import {createAsyncThunk} from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {getNews} from "../../api/news";

export const getNewsList = createAsyncThunk("news/getNews", async () => {
    try {
        return await getNews();
    } catch (error) {
        return error;
    }
});

