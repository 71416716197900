import PropTypes from "prop-types";
import React, {useEffect} from "react";
import {Navigate, useNavigate} from "react-router-dom";

import {logoutUser} from "../../slices/thunks";

//redux
import {useDispatch, useSelector} from "react-redux";

import withRouter from "../../Components/Common/withRouter";
import {createSelector} from "reselect";

const Logout = () => {
    const dispatch = useDispatch();
    //const navigate = useNavigate();
    const logoutData = createSelector(
        (state) => state.Login,
        (isUserLogout) => isUserLogout
    );
    //const isUserLogout = useSelector(logoutData);

    useEffect(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    return <Navigate to="https://hr.mc-medprof.ru/sso-logout"/>;
};

Logout.propTypes = {
    history: PropTypes.object,
};


export default withRouter(Logout);