import React, {useEffect, useState} from 'react';
import {
    Card,
    CardBody,
    Col,
    Container,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import BreadCrumbs from "../../Components/Common/BreadCrumb";
import {useNavigate, useSearchParams} from "react-router-dom";
import Header from "./Overview/Header";
import EventDescription from "./Overview/EventDescription";
import RightSection from "./Overview/RightSection";
import classnames from "classnames";
import profileBg from "../../assets/images/profile-bg.jpg";
import moment from "moment";
import 'moment/locale/ru';
import Loader from "../../Components/Common/Loader";
import {toast, ToastContainer} from "react-toastify";
import noImage from "../../assets/images/no-image.jpg";
import {getEvents, participateEvent} from "../../api/events";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getUsersList} from "../../slices/users/thunk";

moment.locale('ru');

const getParticipation = (item, userId) => {
    return item?.kto_pridet.includes(userId) || item?.kto_ne_pridet_otmechayutsya_sami.includes(userId);
}

export const changeDecision = (item, participate) => {
    let isUserInKtoPridet = item.kto_pridet.includes(userId);
    let isUserInKtoNePridet = item.kto_ne_pridet_otmechayutsya_sami.includes(userId);

    if (isUserInKtoPridet && !participate) {
        item.kto_pridet = item.kto_pridet.filter(x => x.__id !== userId);
        item.kto_ne_pridet_otmechayutsya_sami.push(userId);
    }

    if (isUserInKtoNePridet && participate) {
        item.kto_ne_pridet_otmechayutsya_sami = item.kto_ne_pridet_otmechayutsya_sami.filter(x => x.__id !== userId);
        item.kto_pridet.push(userId);
    }
    return {
        "kto_pridet": item.kto_pridet,
        "kto_ne_pridet_otmechayutsya_sami": item.kto_ne_pridet_otmechayutsya_sami
    }
}

const EventsPage = ({isWrapped = false}) => {
    const dispatch = useDispatch();

    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser")) || {__id: '2'};
    const userId = currentUser.__id;
    const [modalCenter, setModalCenter] = useState(false);
    const [eventsData, setEventsData] = useState([]);
    const [activeTab, setActiveTab] = useState(isWrapped ? '0' : '1');
    //const [currentPage, setCurrentPage] = useState(1);
    const [currentEvent, setCurrentEvent] = useState(null);
    const [allUsers, setAllUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const [sentApprove, setSentApprove] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();
    const eventId = searchParams.get('event_id');

    //const history = useNavigate();


    const selectedUsersData = createSelector(
        (state) => state.Users.usersList,
        (usersList) => usersList
    );

    const usersList = useSelector(selectedUsersData);

    useEffect(() => {
        dispatch(getUsersList());
    }, [dispatch]);

    useEffect(() => {
        setAllUsers(usersList);
    }, [usersList]);

    useEffect(() => {
        (async () => {
            try {
                const events = await getEvents().then((res) => res.filter(e => e.__participants &&e.__participants.length > 0 && e.__participants.includes(userId)));
                console.log(userId)
                setEventsData(events);
            } catch (error) {
                console.error('Error fetching events:', error);
            }
        })();

    }, []);

    const removeErrorParam = () => {
        if (searchParams.has('event_id')) {
            searchParams.delete('event_id');
            setSearchParams(searchParams);
        }
    };

    const toggleModalCenter = () => {
        if (modalCenter) removeErrorParam();
        setModalCenter(!modalCenter);
    };

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    //Pagination
    // const perPageData = 1;
    // const indexOfLast = currentPage * perPageData;
    // const indexOfFirst = indexOfLast - perPageData;
    // const currentData = useMemo(() => eventsData.slice(indexOfFirst, indexOfLast), [indexOfFirst, indexOfLast, eventsData]);

    useEffect(() => {
        if (eventId && !modalCenter && eventsData.find(e => e.__id === eventId)) {
            setCurrentEvent(eventsData.find(e => e.__id === eventId));
            setModalCenter(true);
        }
    }, [eventId, eventsData, modalCenter]);

    const sendData = async (item, list, participate = true) => {

        const updatedItem = {...item};
        updatedItem.kto_pridet = updatedItem.kto_pridet || [];
        updatedItem.kto_ne_pridet_otmechayutsya_sami = updatedItem.kto_ne_pridet_otmechayutsya_sami || [];

        updatedItem.kto_pridet = updatedItem.kto_pridet.filter(x=>x!==userId);
        updatedItem.kto_ne_pridet_otmechayutsya_sami = updatedItem.kto_ne_pridet_otmechayutsya_sami.filter(x=>x!==userId);

        if (!participate) {
            updatedItem.kto_ne_pridet_otmechayutsya_sami.push(userId);
        } else {
            updatedItem.kto_pridet.push(userId);
        }

        const newEventsData = eventsData.map(x => x.__id === updatedItem.__id ? updatedItem : x);
        setEventsData(newEventsData);
        setCurrentEvent(updatedItem);

        try {
            setSentApprove([...sentApprove, item]);
            await participateEvent(
                item.__id,
                currentUser.__id,
                participate
            ).then(() => {
                toast.success("Данные успешно обновлены", {autoClose: 3000});
                setTimeout(() => toast.clearWaitingQueue(), 3000);
            }).catch(e => {
                toast.error("Ошибка обновления: " + e, {autoClose: 3000});
                setTimeout(() => toast.clearWaitingQueue(), 3000);
                const oldEventsData = eventsData.map(x => x.__id === updatedItem.__id ? item : x);
                setEventsData(oldEventsData);
                setCurrentEvent(item);
            });
        } finally {
            setIsSending(false);
        }
    };

    const EventsRow = ({item}) => (
        <Col lg={4} md={6}>
            <Card className="min-w-350 shadow-lg">
                <CardBody>
                    <h5 className="text-truncate text-truncate-two-lines">{item.__name}</h5>
                    <p className="text-muted text-truncate-two-lines">
                        <i className="ri-map-pin-2-line text-primary me-1 align-middle"/>
                        {item.mesto_provedeniya}
                    </p>
                    <div className="d-flex gap-4 mb-1">
                        <div>
                            <i className="ri-calendar-line text-primary me-1 align-bottom"></i>
                            {moment(item.__startAt).format('DD.MM')}
                        </div>
                        <div>
                            <i className="ri-time-line text-primary me-1 align-bottom"></i>
                            {moment(item.__startAt).format('HH:mm') + ' - ' + moment(item.__endAt).format('HH:mm')}
                        </div>
                    </div>
                    <div className="d-flex flex-column align-items-center align-middle">
                        <img src={item.kartinka_meropriyatiya || noImage}
                             height={256}
                             className="img-cover-lg w-100 rounded-1"
                             alt="Обложка мероприятия"/>
                        {
                            item.opisanie
                                ?
                                <p className="lh-base text-muted text-truncate-two-lines mt-1">{item.opisanie.slice(0, 100)}</p>
                                : <p className="text-muted align-middle mt-4 mb-3">Нет описания</p>
                        }
                        {(item?.__status.status === 1 && moment(item?.__endAt).isAfter(moment())) &&
                            <div className="vstack gap-2">
                                {!(item.kto_pridet?.includes(userId) || item.kto_ne_pridet_otmechayutsya_sami?.includes(userId))  ?
                                    <div className="d-flex gap-2">
                                        <button
                                          onClick={() => sendData(item, "kto_pridet", true)}
                                          type="button"
                                          className={`btn btn-success w-100 ${isSending ? 'disabled' : ''}`}>
                                            {isSending ? 'Сохранение...' : 'Принять'}
                                        </button>
                                        <button
                                          onClick={() => sendData(item,"kto_ne_pridet_otmechayutsya_sami", false)}
                                          type="button"
                                          className={`btn btn-danger w-100 ${isSending ? 'disabled' : ''}`}>
                                            Отклонить
                                        </button>
                                    </div>
                                  :
                                  <div className="d-flex gap-2">
                                      <button
                                        type="button"
                                        className={` btn w-100
                                            ${(item.kto_pridet && item.kto_pridet.includes(userId) || sentApprove.includes(item))
                                                && 'bg-success-subtle'}
                                                ${(item.kto_ne_pridet_otmechayutsya_sami && item.kto_ne_pridet_otmechayutsya_sami.includes(userId) || sentApprove.includes(item))
                                        && 'bg-danger-subtle'}
                                        `}>
                                          {
                                              (item.kto_pridet && item.kto_pridet.includes(userId))
                                                && "Вы пойдете"
                                          }
                                          {
                                            (item.kto_ne_pridet_otmechayutsya_sami && item.kto_ne_pridet_otmechayutsya_sami.includes(userId))
                                                && 'Вы не пойдете'
                                          }
                                      </button>
                                  </div>

                                }

                                <button
                                    onClick={() => {
                                        setCurrentEvent(item);
                                        toggleModalCenter();
                                    }}
                                    className="btn btn-soft-primary w-100">
                                    Подробнее
                                </button>
                            </div>
                        }
                    </div>
                </CardBody>
            </Card>
        </Col>
    );

    if (!isWrapped) document.title = "Мероприятия";

    if (isLoading) return <div className="page-content"><Loader/></div>;

    return (
        <>
            <div className={isWrapped ? '' : "page-content"}>
                <Container fluid={true}>
                    {!isWrapped && (
                        <>
                            <BreadCrumbs title="Мероприятия" breadcrumbItem="Мероприятия"/>
                            <div className="profile-foreground position-relative mx-n4 mt-n4">
                                <div className="profile-wid-bg">
                                    <img src={profileBg} alt="" className="profile-wid-img"/>
                                </div>
                            </div>
                        </>
                    )}
                    <div className={isWrapped ? 'pt-0 mb-lg-3 pb-lg-4' : 'pt-4 mb-4 mb-lg-3 pb-lg-4'}>
                        <Col lg={13}>
                            <div className="d-flex p-1 bg-dark bg-opacity-75 rounded-2">
                                <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                     role="tablist">
                                    {isWrapped && (
                                        <NavItem className="fs-14">
                                            <NavLink
                                                href="#invited"
                                                className={classnames({active: activeTab === '0'})}
                                                onClick={() => toggleTab('0')}>
                                                <i className="ri-user-received-line d-inline-block d-md-none"></i>
                                                <span className="d-none d-md-inline-block">Вы приглашены</span>
                                            </NavLink>
                                        </NavItem>
                                    )}
                                    <NavItem className="fs-14">
                                        <NavLink
                                            href="#future"
                                            className={classnames({active: activeTab === '1'})}
                                            onClick={() => toggleTab('1')}>
                                            <i className="ri-group-line d-inline-block d-md-none"></i>
                                            <span className="d-none d-md-inline-block">Будущие</span>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem className="fs-14">
                                        <NavLink
                                            href="#past"
                                            className={classnames({active: activeTab === '2'})}
                                            onClick={() => toggleTab('2')}>
                                            <i className="ri-archive-line d-inline-block d-md-none"></i>
                                            <span className="d-none d-md-inline-block">Прошедшие</span>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </div>
                            <TabContent activeTab={activeTab} className="pt-4">
                                {isWrapped && (
                                    <TabPane tabId="0">
                                        <Card>
                                            <CardBody>
                                                <h5 className="card-title mb-3">Вы приглашены</h5>
                                                <Row id="job-list">
                                                    {eventsData
                                                        ?.filter(x => moment(x.__startAt).isAfter(moment()))
                                                        .filter(x => x['kto_pridet']?.includes(userId))
                                                        .sort((a, b) => moment(a.__startAt).diff(b.__startAt))
                                                        .map((item, index) => <EventsRow key={index} item={item}/>)}
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    </TabPane>
                                )}
                                <TabPane tabId="1">
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-title mb-3">Будущие мероприятия</h5>
                                            <Row id="job-list">
                                                {eventsData
                                                    ?.filter(x => moment(x.__startAt).isAfter(moment()))
                                                    .sort((a, b) => moment(a.__startAt).diff(b.__startAt))
                                                    .map((item, index) => <EventsRow key={index} item={item}/>)}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                                <TabPane tabId="2">
                                    <Card>
                                        <CardBody>
                                            <h5 className="card-title mb-3">Прошедшие мероприятия</h5>
                                            <Row id="job-list">
                                                {eventsData
                                                    ?.filter(x => moment(x.__startAt).isBefore(moment()))
                                                    .sort((a, b) => moment(a.__startAt).diff(b.__startAt))
                                                    .map((item, index) => <EventsRow key={index} item={item}/>)}
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </div>
                    <ToastContainer closeButton={false} limit={1}/>
                </Container>
            </div>
            <Modal
                size="xl"
                isOpen={modalCenter}
                toggle={toggleModalCenter}
                centered>
                <ModalBody className="p-2">
                    <Container fluid>
                        <Row>
                            <Header event={currentEvent} toggle={toggleModalCenter}/>
                        </Row>
                        <Row className="mt-n5">
                            <Col xxl={8}>
                                <EventDescription event={currentEvent}/>
                            </Col>
                            <Col xxl={4}>
                                <RightSection event={currentEvent} allUsers={allUsers} userId={userId}
                                              sendData={sendData} isSending={isSending}/>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>
        </>
    );
};

export default EventsPage;
