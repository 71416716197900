import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Row} from 'reactstrap';
import BreadCrumbs from '../../Components/Common/BreadCrumb';
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getPollsList} from "../../slices/polls/thunk";
import {getUsersList} from "../../slices/users/thunk";
import {Link} from "react-router-dom";
import Masonry from "react-masonry-component";
import {ToastContainer} from "react-toastify";
import {declOfNum} from "../../helpers/declOfNum";
import ModalPoll from "./modal";
import moment from "moment";
import ImageComponent from "./ImageComponent";

const PollsPage = () => {
    const searchParams = new URLSearchParams(location.search);
    const pollId = searchParams.get('poll_id');


    const dispatch = useDispatch();

    const selectPollsData = createSelector(
        (state) => state.Polls.pollsList,
        (pollsList) => pollsList
    );

    const selectUsersData = createSelector(
        (state) => state.Users.usersList,
        (usersList) => usersList
    );

    const pollsList = useSelector(selectPollsData);
    const usersList = useSelector(selectUsersData);

    //
    const [allPolls, setAllPolls] = useState([]);
    const [displayCategory, setCategory] = useState("Новые");
    const [currentPoll, setCurrentPoll] = useState(null);
    const [modal_center, setmodal_center] = useState(false);
    const [allUsers, setAllUsers] = useState([]);


    useEffect(() => {
        dispatch(getPollsList());
        dispatch(getUsersList());
    }, [dispatch]);

    useEffect(() => {
        setAllPolls(pollsList);
        setAllUsers(usersList);
    }, [pollsList, usersList]);

    useEffect(() => {
        let poll = allPolls?.find(e => e.__id === pollId || '');
        if (currentPoll && poll) {
            if (currentPoll && poll.__id !== currentPoll.__id) {
                setCurrentPoll(poll);
                setmodal_center(true);
            }
        } else if (poll) {
            setCurrentPoll(poll);
            setmodal_center(true);
        }
    }, [pollId, allPolls]);

    function tog_center() {
        setmodal_center(!modal_center);
        dispatch(getPollsList());
    }

    document.title = "Опросы";
    return (
        <div className="page-content">
            <Container fluid={true}>
                <BreadCrumbs title="Опросы" breadcrumbItem="Опросы"/>
                <Row>
                    <Col lg={12}>
                        <div className="">
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center">
                                            <ul className="list-inline categories-filter animation-nav" id="filter">

                                                <li className="list-inline-item">
                                                    <Link to="#" onClick={() => setCategory("Новые")}
                                                          className={displayCategory === "Новые" ? "categories active" : "categories"}
                                                          data-filter="*">Новые</Link>
                                                </li>

                                                <li className="list-inline-item">
                                                    <Link to="#"
                                                          onClick={() => setCategory("Пройдено")}
                                                          className={displayCategory === "Пройдено" ? "categories active" : "categories"}
                                                          data-filter=".designing">Пройдено</Link>
                                                </li>

                                                <li className="list-inline-item">
                                                    <Link to="#"
                                                          onClick={() => setCategory("Завершенные")}
                                                          className={displayCategory === "Завершенные" ? "categories active" : "categories"}
                                                          data-filter=".designing">Завершенные</Link>
                                                </li>
                                            </ul>
                                        </div>

                                        {(allPolls?.length > 0) && <Masonry className="row gallery-wrapper">
                                            {allPolls?.filter(({pending, completed, expired}) =>
                                                (displayCategory === "Пройдено" && completed === true) ||
                                                (displayCategory === "Завершенные" && expired === true) ||
                                                (displayCategory === "Новые" && pending === true))?.map((item, key) => (
                                                <Col xxl={3} xl={4} sm={6}
                                                     className="element-item project designing development"
                                                     key={key}>
                                                    <Card className="border cursor-pointer" onClick={() => {
                                                        setCurrentPoll(item);
                                                        tog_center()
                                                    }}>
                                                        <CardBody>
                                                            <div>
                                                                <h5 className="text-truncate text-truncate-two-lines mb-1">
                                                                    <Link to="#">{item.__name}</Link></h5>
                                                                {(item.pending) &&
                                                                    <p className={"mt-0 mb-1 fw-semibold"}>
                                                                        <i className={"las la-user-clock me-1 fs-5 text-warning align-top"}/>
                                                                        {
                                                                            item.okonchanie_oprosa && <span>
                                                                            Пройти до: {moment(item.okonchanie_oprosa)?.format("DD.MM.YYYY")}
                                                                            </span>
                                                                        }
                                                                    </p>
                                                                }
                                                                {
                                                                    <div className="img-cover">
                                                                        <ImageComponent item={item?.cover}/>
                                                                    </div>
                                                                }
                                                                <div className="text-muted mb-2 min-w-100 text-truncate-four-lines-poll">
                                                                    {item?.predislovie?.length >= 150 ? item?.predislovie?.trim().substring(0, 147) + "..." : item?.predislovie?.trim()}
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={"d-flex justify-content-between align-items-center"}>
                                                                {
                                                                    item.questions?.length > 0 &&
                                                                    <div><i
                                                                        className="las la-question text-success align-middle fs-4 me-1"></i>{item.questions?.length + " " + declOfNum(item.questions?.length, ["вопрос", "вопроса", "вопросов"])}
                                                                    </div>
                                                                }
                                                                <div>
                                                                    {
                                                                        (item?.expired && !item?.completed) &&
                                                                        <li className="list-inline-item"><i
                                                                            className="las la-clock text-danger align-middle me-1"></i>{"Завершен"}
                                                                        </li>
                                                                    }
                                                                    {
                                                                        (item?.pending && !item?.expired) &&
                                                                        <li className="list-inline-item"><i
                                                                            className="las la-clock text-warning align-middle me-1"></i>{"Ожидает"}
                                                                        </li>
                                                                    }
                                                                    {
                                                                        (item?.completed) &&
                                                                        <li className="list-inline-item"><i
                                                                            className="las la-check-circle text-success align-middle me-1"></i>{"Пройдено"}
                                                                        </li>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Masonry>}
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                    </Col>
                </Row>
                {
                    currentPoll &&
                    <ModalPoll
                        modal_center={modal_center}
                        tog_center={tog_center}
                        currentPoll={currentPoll}
                        allUsers={allUsers}
                    />
                }
                <ToastContainer closeButton={false} limit={1}/>
            </Container>
        </div>
    );
};

export default PollsPage;