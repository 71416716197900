import {createAsyncThunk} from "@reduxjs/toolkit";
import {getLessons, pendLessonToAPI, runLessonBP} from "../../api/lessons";
import {toast} from "react-toastify";

export const getLessonsList = createAsyncThunk("lessons/getLessons", async () => {
    try {
        return await getLessons();
    } catch (error) {
        return error;
    }
});

export const sendLesson = createAsyncThunk("lessons/sendLesson", async (lesson) => {
    try {
        const response = await runLessonBP(lesson);
        toast.success("Задание отправлено на проверку!", { autoClose: 3000 });
        return { lesson, ...response };
    } catch (error) {
        toast.error("Ошибка при отправке задания", { autoClose: 3000 });
        return error;
    }
});

export const pendLesson = createAsyncThunk("lessons/pendLesson", async (payload) => {
    try {
        const response = await pendLessonToAPI(payload);
        toast.success("Участие принято.", { autoClose: 3000 });
        return { lesson, ...response };
    } catch (error) {
        toast.error("Ошибка при подтверждении участия", { autoClose: 3000 });
        return error;
    }
})