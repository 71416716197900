import React from "react";
import {Card, CardBody, CloseButton, Col, Row} from "reactstrap";
import moment from "moment";
import "moment/locale/ru";

moment.locale('ru');

const Header = ({event, toggle}) => {
    return (
        <React.Fragment>
            <Col lg={12}>
                <Card className="mt-n4 mx-n4">
                    <div className="bg-warning-subtle">
                        <CardBody className="px-4 pb-4">
                            <Row className="mb-3">
                                <div className="col-md">
                                    <Row className="align-items-center g-3">
                                        <div className="col-md">
                                            <div>
                                                <div className="d-flex justify-content-between"><h4 className="fw-bold">{event?.__name}</h4><CloseButton onClick={() => toggle()}/></div>
                                                <div className="hstack gap-3 flex-wrap">
                                                    <div>
                                                        <i className="ri-map-pin-2-line align-bottom me-1"></i>{" "}
                                                        {event?.mesto_provedeniya}
                                                    </div>
                                                    <div className="vr"></div>
                                                    <div>
                                                        Дата:{" "}
                                                        <span
                                                            className="fw-semibold">{moment(event.__startAt).format('DD MMMM')}</span>
                                                    </div>
                                                    <div className="vr"></div>
                                                    <div>
                                                        Время:{" "}
                                                        <span
                                                            className="fw-semibold">{moment(event.__startAt).format('HH:mm')+' - '+moment(event.__endAt).format('HH:mm')}</span>
                                                    </div>
                                                    <div className="vr"></div>
                                                    {
                                                        event.__participants.includes('cd6fe717-f605-493c-86e3-b6685cac0e03') &&
                                                        <div className="badge rounded-pill bg-warning fs-12">
                                                            Вы приглашены
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Row>
                        </CardBody>
                    </div>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default Header;
