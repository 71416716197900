import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  devMode: false,
};

const DevModeSlice = createSlice({
  name: 'DevModeSlice',
  initialState,
  reducers: {
    changeDevMode(state, action) {
      state.devMode = action.payload; // Corrected line
    },
  }
});

export const {
  changeDevMode
} = DevModeSlice.actions;

export default DevModeSlice.reducer;
