import React, {useState} from 'react';
import {Card, CardBody, CloseButton, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import classnames from 'classnames';

//import images
import OverviewTab from './OverviewTab';
import moment from "moment/moment";

const Section = ({toggle, project, allUsers}) => {
    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <Card className="mt-n4 mx-n4">
                        <div className="bg-warning-subtle">
                            <CardBody className="pb-0 px-4">
                                <Row className="mb-3">
                                    <div className="col-md">
                                        <Row className="align-items-center g-3">
                                            <div className="col-md">
                                                <div>
                                                    <div className="d-flex justify-content-between"><h4 className="mb-3">{project?.__name}</h4>
                                                        <CloseButton onClick={toggle}/></div>
                                                    <div className="hstack gap-3 flex-wrap">
                                                        <div>Начало: <span
                                                            className="fw-bold">{moment(project._start_date).format('DD.MM.YYYY')}</span>
                                                        </div>
                                                        <div className="vr"></div>
                                                        <div>Окончание: <span
                                                            className="fw-bold">{moment(project._end_date).format('DD.MM.YYYY')}</span>
                                                        </div>
                                                        <div className="vr"></div>
                                                        <div
                                                            className={`badge ${project.__status.status === 2 ? 'bg-success-subtle' : 'bg-primary-subtle'}
                                                                                     ${project.__status.status === 2 ? 'text-success' : 'text-primary'} fs-10`}>
                                                            {project.__status.status === 2 ? 'Активный' : 'Стратегия'}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Row>
                                    </div>
                                </Row>

                                <Nav className="nav-tabs-custom border-bottom-0" role="tablist">
                                    <NavItem>
                                        <NavLink
                                            className={classnames({active: activeTab === '1'}, "fw-semibold")}
                                            onClick={() => {
                                                toggleTab('1');
                                            }}
                                            href="#">
                                            Общее
                                        </NavLink>
                                    </NavItem>
                                    {/*<NavItem>*/}
                                    {/*    <NavLink*/}
                                    {/*        className={classnames({active: activeTab === '2'}, "fw-semibold")}*/}
                                    {/*        onClick={() => {*/}
                                    {/*            toggleTab('2');*/}
                                    {/*        }}*/}
                                    {/*        href="#">*/}
                                    {/*        Документы*/}
                                    {/*    </NavLink>*/}
                                    {/*</NavItem>*/}
                                </Nav>
                            </CardBody>
                        </div>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col lg={12}>
                    <TabContent activeTab={activeTab} className="text-muted">
                        <TabPane tabId="1">
                            <OverviewTab allUsers={allUsers} project={project}/>
                        </TabPane>
                        {/*<TabPane tabId="2">*/}
                        {/*    <DocumentsTab/>*/}
                        {/*</TabPane>*/}
                    </TabContent>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default Section;