import axios from "axios";
import mockGroups from "../pages/Company/mock_os.json";
import mockUsers from "../pages/Company/mockUsers.json";
import Cookies from "js-cookie";
import {toast} from "react-toastify";

export const ElmaAPI = axios.create({
    baseURL: 'https://hr.mc-medprof.ru/',
    // baseURL: 'http://localhost:7290',
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 55000
});

ElmaAPI.interceptors.response.use(
    function (response) {
        // If the request succeeds, we don't have to do anything and just return the response
        return response;
    },
    async function (error) {
        const {status} = error.response;
        const originalRequest = error.config;

        // Check if the flag is already set, indicating we already tried a redirect
        if (!originalRequest._retry) {
            originalRequest._retry = true;
            if (status === 401 && window.location.pathname !== '/c/logout' && window.location.pathname !== '/c/login') {
                sessionStorage.removeItem("authUser");
                localStorage.removeItem("authUser");
                Cookies.remove(".AspNetCore.cookie");
                toast.success("Пожалуйста, залогиньтесь.");
                window.location.href = "https://hr.mc-medprof.ru/sso-login";
            }
        }

        // Always return a rejection for errors not explicitly handled
        return Promise.reject(error);
    }
);


export const getFullProfileById = async (user_id) => {
    const userProfile = await getAllUsers().then(r => r.find(item => item['__id'] === user_id));
    const profile = await ElmaAPI.get('/profiles').then(r => r.data['result']['result'].find(item => item['polzovatel'][0] === user_id));
    const hobbies = await getHobbies().then(r => r);
    const dolzhnosti = await getDolzhnosti().then(r => r);
    const achievements = await getAchievements().then(r => r);

    if (profile) {
        const wholeUser = {...profile, ...userProfile};
        wholeUser.hasProfile = true;
        wholeUser.profile_id = profile.__id;
        wholeUser.mobilePhone = wholeUser.mobilePhone || wholeUser.workPhone;
        wholeUser.interesy_i_uvlecheniya = wholeUser.interesy_i_uvlecheniya?.map(item => hobbies.find(item2 => item2['__id'] === item));
        wholeUser.karty_dolzhnostei = wholeUser.karty_dolzhnostei?.map(item => dolzhnosti.find(item2 => item2['__id'] === item));
        wholeUser.dostizheniya = wholeUser.dostizheniya?.map(item => achievements.find(item2 => item2['__id'] === item));
        wholeUser.dolzhnost = wholeUser?.karty_dolzhnostei[0] ?? {__name: 'Не указано', opisanie: 'test'};
        return wholeUser;
    }
    if (!profile) {
        const noProfileUser = userProfile;
        noProfileUser.hasProfile = false;
        return noProfileUser;
    }
}
export const updateMyProfile = async (userid, {OSebe, Gorod, Hobbies, TelegramID, tgPolls, tgEvents, tgEducation}) => {
    return await ElmaAPI.post(`/update_profile/${userid}`, {
        OSebe,
        Gorod,
        Hobbies,
        TelegramID,
        tgPolls,
        tgEvents,
        tgEducation
    }).then(r => r.data);
}
export const getAllUsers = async () => {
    const groups = mockGroups;
    let allUsers = [];
    try {
        allUsers = await ElmaAPI.get('/new_users').then(r => r.data);
    }
    catch (e) {
        allUsers = mockUsers;
    }

    return allUsers.map(item => {
        item.osNames = [];
        item.groupNames = [];
        item.osIds = item?.osIds || [];
        if (item?.osIds && item?.osIds.length > 0) {
            item.osIds.forEach((item2) => {
                const group = groups.find((item3) => item3.__id === item2);
                item.osNames.push(group?.name);
            });
        }
        item.groupNames = item?.groupIds?.map((item2) => {
            const group = groups.find((item3) => item3.__id === item2);
            return group ? group.name : null;
        }).filter(Boolean);

        return item
    })
}
export const getAllUsersToCheck = async () => {
    return await ElmaAPI.get('/fast_users').then(r => r.data);
}

export const getOrgStruct = async () => {
    return await ElmaAPI.get('/orgstruct').then(r => r.data);
}

export const getUserByEmail = async (email) => {
    return await ElmaAPI.get(`/user_by_mail?email=${email}`).then(r => r.data);
}
export const getFileLink = async (fileId) => {
    return await ElmaAPI.get(`/get_file_link/${fileId}`).then(r => r.data);
}
export const getHobbies = async () => {
    return await ElmaAPI.get('/hobbies').then(r => r.data['result']['result']);
}
export const getDolzhnosti = async () => {
    return await ElmaAPI.get('/job_positions').then(r => r.data['result']['result']);
}
export const getAchievements = async () => {
    const achivki = await ElmaAPI.get('/achievements').then(r => r.data['result']['result']);
    return await Promise.all(achivki?.map(async (item) => {
        return {...item, achivka: await getFileLink(item.achivka[0]).then(r => r?.Link)}
    }));
}
export const getKpis = async () => {
    return await ElmaAPI.get('/kpis').then(r => r.data['result']['result']);
}
export const getProjects = async () => {
    return await ElmaAPI.get('/projects').then(r => r.data['result']);
}

export const getMyElmaUser = async () => {
    return await ElmaAPI.get('/ElmaAuth/my_elma_profile').then(r => r.data);
}

export default ElmaAPI