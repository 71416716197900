import ElmaAPI from "./myAPI";

export const markAsRead = async (profileId, polls, events, education, freeNotifications) => {
    return await ElmaAPI.post(`/mark_as_read/${profileId}`, {
        polls,
        events,
        education,
        freeNotifications
    }).then(r => r.data);
}
export const getNotifications = async () => {
    return await ElmaAPI.get(`/notifications`).then(r => r.data);
}

export const getFreeNotifications = async () => {
    return await ElmaAPI.get(`/free_notifications`).then(r => r.data);
}