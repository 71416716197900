export function getFio({ firstname, lastname, middlename }) {
    return (
        lastname?' ' + lastname + ' ' + (firstname[0]??'') + ' ' + (middlename[0] ?? ''):''
    );
}


export function extractIframeSrc(inputString) {
    const regex = /<iframe src="(.*?)"/;
    const match = inputString.match(regex);
    if (match && match.length > 1) {
        return match[1];
    }
    return null;
}

