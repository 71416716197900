import { createSlice } from "@reduxjs/toolkit";
import {getNewsList} from './thunk';

export const initialState = {
    newsList: [],
    error: {},
};

const NewsSlice = createSlice({
    name: 'News',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getNewsList.fulfilled, (state, action) => {
            state.newsList = action.payload;
        });
        builder.addCase(getNewsList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default NewsSlice.reducer;