import { combineReducers } from "redux";

// Front
import LayoutReducer from "./layouts/reducer";

// Authentication
import LoginReducer from "./auth/login/reducer";
import ProfileReducer from "./auth/profile/reducer";

//Events
import EventsReducer from "./events/reducer";

//Users
import UsersReducer from "./users/reducer";

// News
import NewsReducer from "./news/reducer";
import ElmaNewsReducer from "./elmaNews/reducer";

// Lessons
import LessonsReducer from "./lessons/reducer";

//Polls
import PollsReducer from "./polls/reducer";

//Vacancies
import VacanciesReducer from "./vacancies/reducer";

//devmode
import DevModeReducer from "./devMode/reducer";

const rootReducer = combineReducers({
    Layout: LayoutReducer,
    Login: LoginReducer,
    Users: UsersReducer,
    News: NewsReducer,
    ElmaNews: ElmaNewsReducer,
    Events: EventsReducer,
    Lessons: LessonsReducer,
    Profile: ProfileReducer,
    Polls: PollsReducer,
    Vacancies: VacanciesReducer,
    DevMode: DevModeReducer
});

export default rootReducer;