import React from 'react';
import Maintenance from "../Pages/Maintenance/Maintenance";

//Import Breadcrumb


const newPage = () => {
    document.title = "New Page | Velzon - React Admin & Dashboard Template";   //for meta title
    return (<Maintenance/>
    );
}

export default newPage;