import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import {getFilenameFromElmaLink} from "../../helpers/getFilenameFromElmaLink";
import {Button} from "reactstrap";
import 'vidstack/styles/defaults.css';
import 'vidstack/styles/community-skin/video.css';

import {MediaCommunitySkin, MediaOutlet, MediaPlayer, MediaPoster} from '@vidstack/react';
import {Link} from "react-router-dom";

const MyCustomVideoRenderer = ({mainState: {currentDocument},}) => {
    MyCustomVideoRenderer.fileTypes = ["mp4", "video/mp4"];
    MyCustomVideoRenderer.weight = 1;

    const RUSSIAN = {
        Audio: 'Аудио',
        Auto: 'Авто',
        Captions: 'Субтитры',
        Chapters: 'Главы',
        Default: 'По умолчанию',
        Mute: 'Выключить звук',
        Normal: 'Нормальная',
        Off: 'Выключено',
        Pause: 'Пауза',
        Play: 'Воспроизвести',
        Speed: 'Скорость',
        Quality: 'Качество',
        Settings: 'Настройки',
        Unmute: 'Включить звук',
        'Seek Forward': 'Вперёд',
        'Seek Backward': 'Назад',
        'Closed-Captions On': 'Субтитры Вкл',
        'Closed-Captions Off': 'Субтитры Выкл',
        'Enter Fullscreen': 'В полный экран',
        'Exit Fullscreen': 'Из полного экрана',
        'Enter PiP': 'Картинка в картинке',
        'Exit PiP': 'Вернуть на вкладку',
    };

    if (!currentDocument) return null;

    return (
        <MediaPlayer
            title={currentDocument.fileName}
            src={[{src: currentDocument.uri, type: "video/mp4"}]}
            aspectRatio={16 / 9}
            language="ru"
            loading="lazy"
            method="get"
            type="video"
            crossorigin=""
        >
            <MediaOutlet>
                <MediaPoster
                    alt="Sample video poster"
                />
            </MediaOutlet>
            <MediaCommunitySkin translations={RUSSIAN}/>
        </MediaPlayer>
    );
};

const MyHeader = (state, previousDocument, nextDocument) => {

    MyCustomVideoRenderer.fileTypes = ["mp4", "video/mp4"];
    MyCustomVideoRenderer.weight = 1;

    if (!state.currentDocument || state.config?.header?.disableFileName) {
        return (
            <>
            <div className={"align-bottom text-muted text-truncate mt-2"}>
                Файл: {state.currentDocument.fileName || ""}
                <Link to={state.currentDocument?.uri} className={"btn btn-link"}>Скачать <i className={"ri ri-download-2-fill align-bottom"}/></Link>
            </div>
        </>
        );
    }

    return (
        <>
            <div className={"d-flex justify-content-around p-2 align-items-center"}>
                <Button className={'btn btn-sm btn-primary'} onClick={previousDocument}
                        disabled={state.currentFileNo === 0}>
                    Предыдущий файл
                </Button>
                <div className={"align-top text-muted text-truncate mt-2 mb-2"}>Файл: {state.currentDocument.fileName || ""}</div>
                <Button className={'btn btn-sm btn-primary'}
                        onClick={nextDocument}
                        disabled={state.currentFileNo >= state.documents.length - 1}
                >
                    Следующий файл
                </Button>
            </div>
        </>
    );
};

export default function MyDocViewer({docs, disableHeader = false}) {
    const newDocs = docs.map((item, key) => ({
        uri: item,
        fileType: getFilenameFromElmaLink(item).split(".").at(-1),
        fileName: getFilenameFromElmaLink(item)
    }));

    return <DocViewer pluginRenderers={[...DocViewerRenderers, MyCustomVideoRenderer]}
                      key={newDocs[0].uri}
                      className={"myDocViewer"}
                      documents={newDocs}
                      config={{
                          header: {
                              overrideComponent: MyHeader,
                              disableFileName: disableHeader
                          },
                      }}
                      prefetchMethod="GET"
                      language="ru"
    />;
}