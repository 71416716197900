import {ElmaAPI} from "./myAPI";
import moment from "moment";
import mockPolls from "../pages/Polls/mockPolls.json"
import testUserMock from "./userMock.json"

export const getPolls = async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
    const groups = [...user.groupIds, ...user.osIds];

    //const polls = mockPolls;
    const polls = await ElmaAPI.get('/polls').then(r=>r.data).catch(e=>mockPolls);
    if(!polls) return [];

    const pollsForMe = polls?.filter(item => item?.komu_prokhodit_opros?.some(item2 => groups.includes(item2.code) || item2.code === user.__id) || item?.dlya_kogo_opros?.some(item2 => item2 === user.__id));
    return pollsForMe?.map((item) => {
        item.pending = !item?.kto_proshel_opros?.includes(user.__id) && !moment(item?.okonchanie_oprosa).isBefore(moment());
        item.completed = item?.kto_proshel_opros?.includes(user.__id);
        item.expired = moment(item?.okonchanie_oprosa).isBefore(moment());
        return item;
    });
};

export const sendPoll = async (data) => {
    return await ElmaAPI.post('/send_poll', data).then(r=>r.data).catch(e=>{return e});
}