import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    CloseButton,
    Col,
    Container,
    Form,
    ListGroup,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from "reactstrap";
import classnames from "classnames";
import {
    agreeVacation,
    cancelVacation,
    getAgreementVacations,
    getUnpaidVacations,
    getVacationMovements,
    getVacations
} from "../../../../api/vacations";
import moment from "moment";
import VacationModal from "../../../Vacations/vacationModal";

function VacationsTab() {


    // const dispatch = useDispatch();
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser")) || {__id: "a12793ef-0195-4185-8358-aafc13f332a0"};
    const [activeTab, setActiveTab] = useState('1');
    const [vacationItems, setVacationItems] = useState([]);
    const [vacations, setVacations] = useState([]);
    const [unpaidVacations, setUnpaidVacations] = useState([]);
    const [modal_center, tog_center] = useState(false);
    const [currentVacation, setCurrentVacation] = useState({});
    const [foldedGroups, setFoldedGroups] = useState([]);
    const [confirmVacationLoading, setConfirmVacationLoading] = useState(false);
    const [modal_cancel, tog_cancel] = useState(false);
    const [vacationAgreements, setVacationAgreements] = useState([]);
    const [vacationMovements, setVacationMovements] = useState([]);
    const [forceRefresh, setForceRefresh] = useState(false);

    function toggleGroup(groupId) {
        setFoldedGroups(prevState => ({...prevState, [groupId]: !prevState[groupId]}));
    }

    function toggleEditModal(vacation) {
        setCurrentVacation(vacation);
        tog_center(!modal_center);
    }

    function toggleCancelModal(vacation) {
        setCurrentVacation(vacation);
        tog_cancel(!modal_cancel);
    }

    // function waitForMe(millisec) {
    //     return new Promise(resolve => {
    //         setTimeout(() => {
    //             resolve('')
    //         }, millisec);
    //     });
    // }

    // function loading() {
    //     setConfirmVacationLoading(true);
    //     waitForMe(1000).then(() => setConfirmVacationLoading(false));
    // }

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    useEffect(() => {
        const fetchVacations = async () => {
            const response = await getVacations().then(r => r.filter(x => x.employee_full_name[0] === currentUser.__id));
            //const response = await getVacations().then(r=>r);
            //TODO: filter by user
            setVacationItems(response);

            const allFolded = response.reduce((acc, cur) => {
                acc[cur.__id] = true;
                return acc;
            }, {});

            setFoldedGroups(allFolded);
        }
        const fetchUnpaidVacations = async () => {
            const response = await getUnpaidVacations().then(r => r.filter(x => x.employee_full_name[0] === currentUser.__id));
            //const response = await getUnpaidVacations().then(r=>r);
            //TODO: filter by user
            setUnpaidVacations(response);

            // const allFolded = response.reduce((acc, cur) => {
            //     acc[cur.__id] = true;
            //     return acc;
            // }, {});
        }
        const fetchAgreements = async () => {
            const response = await getAgreementVacations().then(r => r.filter(x => x.full_name_employee[0] == currentUser.__id));
            //const response = await getAgreementVacations().then(r=>r);

            //TODO: filter by user
            setVacationAgreements(response);

            // const allFolded = response.reduce((acc, cur) => {
            //     acc[cur.__id] = true;
            //     return acc;
            // }, {});
        }

        const fetchMovements = async () => {
            const response = await getVacationMovements().then(r => r.filter(x => x.employee[0] == currentUser.__id));
            //const response = await getAgreementVacations().then(r=>r);

            //TODO: filter by user
            setVacationMovements(response);

            // const allFolded = response.reduce((acc, cur) => {
            //     acc[cur.__id] = true;
            //     return acc;
            // }, {});
        }

        fetchVacations();
        fetchUnpaidVacations();
        fetchAgreements();
        fetchMovements();

    }, [forceRefresh, currentVacation, modal_center, modal_cancel]);

    useEffect(() => {
        if (vacationItems) {
            const vacations_new = vacationItems.map(x => [...x.vacation_table?.rows].map(y => ({...y, ...x}))).flat();
            setVacations(vacations_new);
        }
    }, [vacationItems]);

    function sendAgreement(item) {
        setConfirmVacationLoading(true);
        agreeVacation(item).then(() => {
            setForceRefresh(!forceRefresh);
            setConfirmVacationLoading(false);
        })
    }

    function cancelVacItem(item) {
        setConfirmVacationLoading(true);
        cancelVacation(item.__id).then(() => {
            setConfirmVacationLoading(false);
            setForceRefresh(!forceRefresh);
            tog_cancel(false);
        })
    }

    const VacationYear = ({vacationType, vacations}) => {
        return (
            <Card className={"min-w-350 shadow-lg mb-1"}>
                <CardBody>
                    <div className={"d-flex flex-column gap-0"}>
                        {
                            vacations.length === 0 && <h6 className={"mb-0"}>Не найдено</h6>
                        }
                        {
                            vacations.map((vacation, index) => <Vacation key={index} vacationType={vacationType}
                                                                         item={vacation}/>)
                        }
                    </div>
                </CardBody>
            </Card>
        )
    }

    const Vacation = ({item, vacationType}) => {
        const findAgreement =
            vacationAgreements.find(x =>
                moment.utc(x.start_date).startOf('day').dayOfYear() ===  moment.utc(item.start_date).startOf('day').dayOfYear()
                && moment.utc(x.end_date).startOf('day').dayOfYear() ===  moment.utc(item.end_date).startOf('day').dayOfYear());

        // const findMovement = vacationMovements.filter(x =>
        //     moment.utc(x.old_start_date).startOf('day').diff(moment.utc(item.start_date).startOf('day'), 'days') === 0
        //     && moment.utc(x.old_end_date).startOf('day').diff(moment.utc(item.end_date).startOf('day'), 'days') === 0);

        const findMovement = vacationMovements.filter(x =>
            moment.utc(x.old_start_date).startOf('day').dayOfYear() ===  moment.utc(item.start_date).startOf('day').dayOfYear()
            && moment.utc(x.old_end_date).startOf('day').dayOfYear() ===  moment.utc(item.end_date).startOf('day').dayOfYear());


        return (
            <Card className="shadow-lg mb-3">
                <CardBody className="d-flex flex-column flex-md-row justify-content-between align-items-start p-3">
                    {/* Date range */}
                    <div className="min-w-50">
                        {moment.utc(item.start_date).startOf('day').format('DD.MM') + ' - ' + moment.utc(item.end_date).startOf('day').format('DD.MM')} ({moment.utc(item.end_date).startOf('day').diff(moment.utc(item.start_date), 'days') + 1} дн.)
                    </div>
                    {/* Actions and Status */}
                    <div className="d-flex flex-column flex-md-row gap-3 min-w-100 justify-content-end">
                        {/* Status Display */}
                        <div className="d-flex align-items-center gap-2">
                            {findAgreement ? (
                                <>
                                    {findAgreement.__status.status === 1 && <span><i className="ri-timer-2-line align-middle text-warning"></i> На согласовании</span>}
                                    {findAgreement.__status.status === 2 && <span><i className="ri-check-line align-middle text-success"></i> Согласован</span>}
                                    {findAgreement.__status.status === 3 && <span><i className="ri-close-line align-middle text-danger"></i> Не согласован</span>}
                                </>
                            ) : (
                                <span><i className="ri-timer-2-line align-middle text-warning"></i> На согласовании</span>
                            )}
                        </div>

                        {/* Action Buttons */}
                        {(vacationType === 'yearly' && moment.utc(item.start_date).startOf('day').diff(moment.utc().startOf('day'), "days") > 2 && findAgreement?.__status.status !== 3) && (
                            <>
                                {(moment.utc(item.start_date).startOf('day').diff(moment.utc().startOf('day'), "days") < 21 && findAgreement &&  findAgreement?.__status.status === 2) && (
                                    <Button className={`btn btn-success btn-sm ${confirmVacationLoading ? 'disabled loading' : ''}`} onClick={() => sendAgreement(item)}>
                                        <i className="ri-check-line align-middle"></i> Пойду в отпуск
                                    </Button>
                                )}
                                <Button className="btn btn-outline-info btn-sm" onClick={() => toggleEditModal(item)}>
                                    <i className="ri-edit-2-line align-middle"></i> Хочу перенести
                                </Button>
                            </>
                        )}

                        {((vacationType === 'self-paid' && findAgreement?.__status.status !== 3) || vacationType === 'unpaid') && (
                            <Button className={`btn btn-danger btn-sm ${confirmVacationLoading ? 'disabled loading' : ''}`} onClick={() => toggleCancelModal(item)}>
                                {confirmVacationLoading && <div className="spinner-border-sm text-primary" role="status"></div>}
                                <i className="ri-check-line align-middle"></i> Отменить
                            </Button>
                        )}
                    </div>
                </CardBody>
                {findMovement?.length > 0 &&
                    <CardFooter>
                        {findMovement.map((movement, index) => (
                            <div className="d-flex justify-content-between align-items-center py-2" key={index}>
                                Перенос с {moment.utc(movement.old_start_date).startOf('day').format("DD.MM")} на {moment.utc(movement.start_date).startOf('day').format("DD.MM")}
                                <div className="d-flex align-items-center gap-2">
                                    {moment.utc(movement.end_date).startOf('day').diff(moment.utc(movement.start_date), 'days') + 1} дн.
                                    {movement.__status.status === 1 && <span><i className="ri-timer-2-line align-middle text-warning"></i> На согласовании</span>}
                                    {movement.__status.status === 2 && <span><i className="ri-check-line align-middle text-success"></i> Согласован</span>}
                                    {movement.__status.status === 3 && <span><i className="ri-close-line align-middle text-danger"></i> Не согласован
                                        {movement?.kommentarii_rukovoditelya && `(${movement.kommentarii_rukovoditelya})`}</span>}
                                </div>
                            </div>
                        ))}
                    </CardFooter>
                }
            </Card>

        )
    }

    return (
        <Container fluid>
            <div className={`${'pt-0 mb-lg-3 pb-lg-4'}`}>
                <Col lg={13}>
                    <div className="d-flex p-1 bg-dark bg-opacity-75 rounded-2">
                        <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                             role="tablist">
                            <NavItem className="fs-14">
                                <NavLink
                                    href="#future"
                                    className={classnames({active: activeTab === '1'})}
                                    onClick={() => {
                                        toggleTab('1');
                                    }}
                                >
                                    <i className="ri-group-line d-inline-block d-md-none"></i> <span
                                    className="d-none d-md-inline-block">Предстоящие</span>
                                </NavLink>
                            </NavItem>
                            <NavItem className="fs-14">
                                <NavLink
                                    href="#past"
                                    className={classnames({active: activeTab === '2'})}
                                    onClick={() => {
                                        toggleTab('2');
                                    }}
                                >
                                    <i className=" ri-archive-line d-inline-block d-md-none"></i> <span
                                    className="d-none d-md-inline-block">Архив</span>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </div>
                    <TabContent activeTab={activeTab} className="pt-4">
                        <TabPane tabId="1">
                            <Card>
                                <CardBody>
                                    В данном разделе Вы можете отслеживать предстоящие даты отпусков, а также перенести
                                    отпуск, нажав на кнопку "Перенести"
                                    рядом с интересующими датами.
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Row id="job-list">
                                        <ListGroup className="nested-list nested-sortable">
                                            {
                                                vacationItems && vacationItems.map((group) => (
                                                    <Card key={group.__id}>
                                                        <CardHeader>
                                                            <div
                                                                className="btn-group btn-group-sm align-items-center d-sm-flex cursor-pointer"
                                                                onClick={() => toggleGroup(group.__id)}>

                                                                <div className="d-flex align-items-center align-middle">
                                                                    <h5 className="text-truncate align-middle">{group.god}
                                                                        <i className={`fs-24 ${!foldedGroups[group.__id] ? 'ri-arrow-down-s-line align-middle' : 'ri-arrow-right-s-line align-middle'}`}/>
                                                                    </h5>
                                                                </div>
                                                            </div>
                                                        </CardHeader>
                                                        {
                                                            !foldedGroups[group.__id] && (
                                                                <CardBody>

                                                                    <h5>Неоплачиваемые отпуска</h5>
                                                                    <VacationYear vacationItem={group}
                                                                                  vacationType="self-paid"
                                                                                  vacations={unpaidVacations.filter(x => moment.utc(x.start_date).isAfter(moment.utc()))}/>

                                                                    <h5>Ежегодные отпуска</h5>
                                                                    <VacationYear vacationItem={group}
                                                                                  vacationType="yearly"
                                                                                  vacations={vacations.filter(x => moment.utc(x.start_date).isAfter(moment.utc()))}/>

                                                                </CardBody>
                                                            )
                                                        }
                                                    </Card>
                                                ))
                                            }
                                        </ListGroup>
                                    </Row>
                                </CardBody>
                            </Card>
                        </TabPane>
                        <TabPane tabId="2">
                            <Card>
                                <CardBody>
                                    <Row id="job-list">
                                        <div>Архив отпусков</div>
                                    </Row>
                                    <ListGroup className="nested-list nested-sortable">
                                        {
                                            vacationItems && vacationItems.map((group) => (
                                                <Card key={group.__id}>
                                                    <CardHeader>
                                                        <div
                                                            className="btn-group btn-group-sm align-items-center d-sm-flex cursor-pointer"
                                                            onClick={() => toggleGroup(group.__id)}>

                                                            <div className="d-flex align-items-center align-middle">
                                                                <h5 className="text-truncate align-middle">{group.god}
                                                                    <i className={`fs-24 ${!foldedGroups[group.__id] ? 'ri-arrow-down-s-line align-middle' : 'ri-arrow-right-s-line align-middle'}`}/>
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </CardHeader>
                                                    {
                                                        !foldedGroups[group.__id] && (
                                                            <CardBody>

                                                                <h5>Неоплачиваемые отпуска</h5>
                                                                <VacationYear vacationItem={group}
                                                                              vacationType="archieve"
                                                                              vacations={unpaidVacations.filter(x => moment.utc(x.start_date).startOf('day').isBefore(moment.utc().startOf('day')))}/>

                                                                <h5>Ежегодные отпуска</h5>
                                                                <VacationYear vacationItem={group}
                                                                              vacationType="archieve"
                                                                              vacations={vacations.filter(x => moment.utc(x.start_date).startOf('day').isBefore(moment.utc().startOf('day')))}/>

                                                            </CardBody>
                                                        )
                                                    }
                                                </Card>
                                            ))
                                        }
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </TabPane>
                    </TabContent>
                </Col>
            </div>

            <VacationModal modal_center={modal_center} tog_center={tog_center} vacation={currentVacation}/>

            <Modal
                size="md"
                isOpen={modal_cancel}
                toggle={() => {
                    tog_cancel(!modal_cancel);
                }}
                centered
            >
                <ModalBody>
                    <div className="d-flex justify-content-between">
                        <h4 className="mb-3">Отмена отпуска</h4>
                        <CloseButton onClick={() => tog_cancel(!modal_cancel)}/></div
                    >
                    <Form onSubmit={() => cancelVacItem(currentVacation)}>
                        <div className="mb-3">
                            Вы действительно хотите отменить неоплачиваемый отпуск
                            от {moment.utc(currentVacation.start_date).startOf('day').format('DD.MM.YYYY')} до {moment.utc(currentVacation.end_date).startOf('day').format('DD.MM.YYYY')}?
                        </div>
                        <div className="d-flex justify-content-center">
                            <div className={"d-flex gap-4"}>
                                <Button
                                    type="submit"
                                    color="success"
                                    className="btn btn-success px-5">
                                    Да
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="btn btn-danger px-5"
                                    onClick={() => tog_cancel(!modal_cancel)}>
                                    Нет
                                </Button>
                            </div>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </Container>
    );
}

export default VacationsTab;