import {createAsyncThunk} from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {getVacancies} from "../../api/vacancies";

export const getVacanciesList = createAsyncThunk("vacancies/getVacancies", async () => {
    try {
        return await getVacancies();
    } catch (error) {
        return error;
    }
});

