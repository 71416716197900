import React, {useEffect, useState} from 'react';
import {Card, CardBody, Col, Container, Input, Offcanvas, OffcanvasBody, Row} from "reactstrap";
import mockOsData from "./mock_os.json";

//Import Breadcrumb
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getUsersList} from "../../slices/users/thunk";
import {ToastContainer} from "react-toastify";
import {Link} from "react-router-dom";
import avatar2 from "../../assets/images/users/avatar-2.jpg";
import moment from "moment";
import profileBg from "../../assets/images/profile-bg.jpg";
import Loader from "../../Components/Common/Loader";
import Folder from "./NewFolders";
import {getOrgStruct} from "../../api/myAPI";

const newPage = ({orgstruct = true}) => {

    document.title = "Компания";
    const dispatch = useDispatch();

    const selectedUsersData = createSelector(
        (state) => state.Users.usersList,
        (usersList) => usersList
    );

    // Inside your component
    const usersList = useSelector(selectedUsersData);
    const [userList, setUserList] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [sideBar, setSideBar] = useState([]);
    const [loading, setLoading] = useState(true);

    const [isFiltering, setIsFiltering] = useState(false);
    const [viewGrid, setViewGrid] = useState(!orgstruct);

    const [orgStructure, setOrgStructure] = useState([]);

    async function getOrgStructure() {
        await getOrgStruct().then(r => setOrgStructure(r)).catch(()=>setOrgStructure(mockOsData));
    }

    useEffect(() => {
        dispatch(getUsersList());
    }, [dispatch]);

    useEffect(() => {
        if (isFiltering) return;
        setUserList(usersList.filter(x =>
            !x.__name.toLowerCase().includes('test')
            &&!x.__name.toLowerCase().includes('тест')
            &&!x.__name.toLowerCase().includes('админ')
            &&!x.__name.toLowerCase().includes('admin')
        ));

        getOrgStructure().finally(
            () => setLoading(false)
        );
    }, [usersList]);


    useEffect(() => {
        const list = document.querySelectorAll(".team-list");
        const buttonGroups = document.querySelectorAll('.filter-button');
        for (let i = 0; i < buttonGroups.length; i++) {
            buttonGroups[i].addEventListener('click', onButtonGroupClick);
        }

        function onButtonGroupClick(event) {
            if (event.target.id === 'list-view-button' || event.target.parentElement.id === 'list-view-button') {
                document.getElementById("list-view-button").classList.add("active");
                document.getElementById("grid-view-button").classList.remove("active");
                list.forEach(function (el) {
                    el.classList.add("list-view-filter");
                    el.classList.remove("grid-view-filter");
                });

            } else {
                document.getElementById("grid-view-button").classList.add("active");
                list.forEach(function (el) {
                    el.classList.remove("list-view-filter");
                    el.classList.add("grid-view-filter");
                });
            }
        }
    }, []);

    const searchList = (e) => {
        let inputVal = e.toLowerCase().trim();

        const fieldContainsQuery = (field, query) => {
            return field && field.toLowerCase().includes(query);
        };

        const filterItems = (arr, query) => {
            return arr.filter((el) => {
                return (
                    fieldContainsQuery(el.__name, query) ||
                    fieldContainsQuery(el.displayedPosition, query) ||
                    fieldContainsQuery(el.email, query) ||
                    (el.osNames && el.osNames.some(name => fieldContainsQuery(name, query))) ||
                    (el.groupNames && el.groupNames.some(name => fieldContainsQuery(name, query)))
                );
            });
        };

        let filterData = filterItems(usersList, inputVal);

        setIsFiltering(true);
        setUserList(filterData.filter(x =>
            !x.__name.toLowerCase().includes('test')
            &&!x.__name.toLowerCase().includes('тест')
            &&!x.__name.toLowerCase().includes('админ')
            &&!x.__name.toLowerCase().includes('admin')
        ));

        if (filterData.length ===  0) {
            document.getElementById("noresult").style.display = "block";
        } else {
            document.getElementById("noresult").style.display = "none";
        }
    };



    function getTelegram(item) {
        let tg = item?.accounts?.find(x => x.type === 'telegram')?.login;
        if (tg) {
            if (tg.includes("@")) {
                return tg.split("@")[1];
            } else if (tg.includes(".me/")) {
                return tg.split(".me/")[1];
            }
            return tg;
        }
        return null
    }


    useEffect(() => {
        setViewGrid(!orgstruct)
    }, [orgstruct]);


    return (
        <React.Fragment>
            <ToastContainer closeButton={false}/>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Компания" pageTitle="Компания"/>
                    <Card>
                        <CardBody>
                            <Row className="g-2">
                                <Col sm={4}>
                                    <div className="search-box">
                                        <Input type="text" className="form-control"
                                               placeholder="Поиск по имени, должности, группе..."
                                               onChange={(e) => searchList(e.target.value)}/>
                                        <i className="ri-search-line search-icon"></i>
                                    </div>
                                </Col>
                                <Col className="col-sm-auto ms-auto">
                                    {/*<div className="list-grid-nav hstack gap-1">*/}
                                    {/*    <span className={"fw-bold fs-12 text-uppercase text-muted me-2"}>{!viewGrid ? 'Сетка' : 'Орг. структура'}</span>*/}
                                    {/*    <Button onClick={switchView} color="info" id="grid-view-button"*/}
                                    {/*            className="btn btn-soft-info nav-link btn-icon fs-14 active filter-button"><i*/}
                                    {/*        className={`${viewGrid ? 'ri-list-unordered' : 'ri-layout-grid-line'}`}></i></Button>*/}
                                    {/*</div>*/}
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    {(!loading && viewGrid) &&
                        <Row>
                            <Col lg={12}>
                                <div id="teamlist">
                                    <Row className="team-list grid-view-filter">
                                        {(userList || []).map((item, key) => (
                                            <Col lg={4} key={key}>

                                                <Card className="team-box fixedCard">
                                                    <div className="team-cover">
                                                        <img src={item.backgroundImg} alt="" className="img-fluid"/>
                                                    </div>
                                                    <CardBody className="p-4">
                                                        <Row className="align-items-center team-row">
                                                            <Link to="#" onClick={() => {
                                                                setIsOpen(!isOpen);
                                                                setSideBar(item);
                                                            }}>
                                                                <Col lg={1} className="col">
                                                                    <div className="team-profile-img">
                                                                        <div
                                                                            className="avatar-lg img-thumbnail rounded-circle flex-shrink-0">
                                                                            {item.avatar != null ?
                                                                                <img src={item.avatar} alt=""
                                                                                     className="img-fluid d-block rounded-circle"/>
                                                                                :
                                                                                <div
                                                                                    className="avatar-title text-uppercase border rounded-circle bg-light text-primary">
                                                                                    {item.__name.charAt(0) + item.__name.split(" ").slice(-1).toString().charAt(0)}
                                                                                </div>}
                                                                        </div>

                                                                        <div className="team-content">
                                                                            <h5 className="text-truncate text-truncate-two-lines">
                                                                                <span>{item.__name}</span>
                                                                                <p className="text-muted mb-0 fs-13">{item?.displayedPosition}</p>
                                                                            </h5>

                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </Link>

                                                            <Col lg={5} className="col">
                                                                <Row className="text-muted ">
                                                                    <div className="team-content">
                                                                        <p className="text-muted mb-0">{
                                                                            (item.mobilePhone && item.mobilePhone.length > 0) ? item.mobilePhone[0].tel :
                                                                                (item.workPhone && item.workPhone.length > 0) ? item.workPhone[0].tel :
                                                                                    <br/>
                                                                        }</p>
                                                                        <Link to={`mailto:${item?.email}`}
                                                                              className="text-info mb-0">{item?.email}</Link>
                                                                        <div>{
                                                                            item.birthDate ? <>
                                                                                <i className="ri-calendar-line text-primary me-1 align-bottom"></i>
                                                                                {moment(item?.birthDate).format('DD.MM.YYYY')}
                                                                            </> : <div><br/></div>}
                                                                        </div>
                                                                        <div className={"align-middle"}>
                                                                            {getTelegram(item) ? <Link
                                                                                to={'https://t.me/' + getTelegram(item)}>
                                                                                <span className="link-info">
                                                                                    <i className="ri-telegram-fill me-1 align-bottom"/>
                                                                                    {
                                                                                        getTelegram(item)
                                                                                    }
                                                                                </span>
                                                                            </Link> : <p/>}
                                                                        </div>
                                                                        <div className="buttonBottom">
                                                                            <Link to={`/profile?user_id=${item.__id}`}
                                                                                  className="btn btn-light view-btn">Открыть
                                                                                профиль</Link>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                </div>

                            </Col>
                            <Offcanvas
                                isOpen={isOpen}
                                direction="end"
                                toggle={() => setIsOpen(!isOpen)}
                                className="offcanvas-end border-0"
                                tabIndex="-1"
                                id="member-overview"
                            >
                                <OffcanvasBody className="profile-offcanvas p-0">
                                    <div className="team-cover">
                                        <img src={sideBar.backgroundImg || profileBg} alt="" className="img-fluid"/>
                                    </div>
                                    <div className="p-3">
                                        <div className="team-settings">
                                            <Row>
                                                <Col>

                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                    <div className="p-3 text-center">
                                        <img src={sideBar?.avatar || avatar2} alt=""
                                             className="avatar-lg img-thumbnail rounded-circle mx-auto"/>
                                        <div className="mt-3">
                                            <h5 className="fs-15 profile-name"><Link to="#"
                                                                                     className="link-primary">{sideBar.__name || "Nancy Martino"}</Link>
                                            </h5>
                                            <p className="text-muted profile-designation">{sideBar.displayedPosition}</p>
                                        </div>
                                        {getTelegram(sideBar) && <div className="hstack gap-2 justify-content-center mt-4">
                                            <div className="avatar-xs">
                                                <Link to={`https://t.me/${getTelegram(sideBar)}`}
                                                      className="avatar-title bg-secondary-subtle text-secondary rounded fs-16">
                                                    <i className="ri-telegram-fill"></i>
                                                </Link>
                                            </div>
                                        </div>}
                                    </div>
                                    <div className="p-3">
                                        {sideBar?.osNames?.length > 1 && <><h5 className="fs-15 mb-3">Должность: </h5>
                                            <p className="text-muted profile-designation">{sideBar.osNames?.map((item, key) => <span key={key}>{item}<br/>
                                    </span>
                                    )}</p></>}

                                        <h5 className="fs-15 mb-3">Личные данные</h5>
                                        <div className="mb-3">
                                            <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Телефон</p>
                                            <a href={`tel:${sideBar.mobilePhone && sideBar.mobilePhone.length > 0 ? sideBar.mobilePhone[0].tel : sideBar.workPhone && sideBar.workPhone.length > 0 ? sideBar.workPhone[0].tel : null}`}>{
                                                (sideBar.mobilePhone && sideBar.mobilePhone.length > 0) ? sideBar.mobilePhone[0].tel :
                                                    (sideBar.workPhone && sideBar.workPhone.length > 0) ? sideBar.workPhone[0].tel : null
                                            }</a>
                                        </div>
                                        <div className="mb-3">
                                            <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Email</p>
                                            <a href={`mailto:${sideBar.email}`}>{sideBar.email}</a>
                                        </div>
                                        <div>
                                            <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Временная
                                                зона:</p>
                                            <h6 className="mb-0">{sideBar?.timezone}</h6>
                                        </div>
                                    </div>
                                </OffcanvasBody>
                                <div
                                    className="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative">
                                    <button onClick={() => setIsOpen(!isOpen)} className="btn btn-light w-100"><i
                                        className="ri-arrow-go-back-fill align-bottom ms-1"></i> Назад
                                    </button>
                                    <Link to={`/profile?user_id=${sideBar.__id}`} className="btn btn-primary w-100"><i
                                        className="ri-user-3-fill align-bottom ms-1"></i> Профиль</Link>
                                </div>
                            </Offcanvas>
                        </Row> }
                    {
                        (!loading && !viewGrid) &&
                            <Folder users={userList} allGroups={orgStructure || mockOsData}/>
                    }
                    {
                        loading && <Loader/>
                    }
                    <div className="py-4 mt-4 text-center" id="noresult" style={{display: "none"}}>
                        <lord-icon src="https://cdn.lordicon.com/msoeawqm.json" trigger="loop"
                                   colors="primary:#405189,secondary:#0ab39c"
                                   style={{width: "72px", height: "72px"}}></lord-icon>
                        <h5 className="mt-4">Никого не найдено! 👀</h5>
                    </div>
                </Container>
            </div>

        </React.Fragment>

    );
}

export default newPage;