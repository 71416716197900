import React, {useState} from 'react';
import {Button, CloseButton, Input, Modal, ModalBody, Spinner, Table} from "reactstrap";
import moment from "moment/moment";
import {toast} from "react-toastify";
import {sendPoll} from "../../api/polls";
import ImageComponent from "./ImageComponent";
import mockPolls from "./mockPolls.json";

function ModalPoll({modal_center = true, tog_center, currentPoll = mockPolls[0], allUsers}) {
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));

    const [selectedValues, setSelectedValues] = useState({});
    const [myCommitment, setMyCommitment] = useState('');
    const [isSending, setIsSending] = useState(false);
    const handleChange = (e, questionName, value, multipleVariants, freeinput=false) => {
        if (multipleVariants && !freeinput) {
            const updatedValues = selectedValues[questionName] ? [...selectedValues[questionName]] : [];
            if (e.target.checked) {
                updatedValues.push(value);
            } else {
                const index = updatedValues.indexOf(value);
                if (index > -1) {
                    updatedValues.splice(index, 1);
                }
            }
            setSelectedValues({
                ...selectedValues,
                [questionName]: updatedValues
            });
        } else {
            setSelectedValues({
                ...selectedValues,
                [questionName]: value
            });
        }
    };

    const updatePoll = () => {
        setIsSending(true);
        const keyValuePairs = Object.entries(selectedValues);
        const stringifiedPairs = keyValuePairs.map(([name, answer]) => `${name}: ${answer}`).map(x => x.answer === "Другoе" ? keyValuePairs.find(x=>x.name === x.name+'_other')?.answer : x).join(',\n');
        let test = stringifiedPairs + myCommitment;

        const users = currentPoll.kto_proshel_opros ? [...currentPoll.kto_proshel_opros, currentUser.__id] : [currentUser.__id];

        const commitment = {
            pollId: currentPoll.__id,
            userId: currentUser.__id,
            result: test,
            userList: users
        }
        sendPoll(commitment).then(r => {
            setIsSending(false);
            tog_center();
            toast.success("Анкета успешно отправлена");
        }).catch(e => {
                setIsSending(false);
                toast.error("Не удалось отправить анкету");
            }
        ).finally(
            () => {
                setIsSending(false);
                tog_center();
            }
        );
    }


    return (
        <Modal
            size="xl"
            isOpen={modal_center}
            toggle={() => {
                tog_center();
            }}
            centered
        >
            <ModalBody>
                <div className="d-flex justify-content-between"><h4
                    className="mb-3">{currentPoll?.__name}</h4>
                    <CloseButton onClick={() => tog_center()}/></div>
                <ul className="list-inline mb-0">
                    {
                        (currentPoll?.pending) &&
                        <li className="list-inline-item"><i
                            className="las la-clock text-warning align-middle me-1"></i>{"Пройти до " + `${moment(currentPoll?.okonchanie_oprosa).format("DD.MM.YYYY") === "Invalid date" ? " неограниченого времени" : moment(currentPoll?.okonchanie_oprosa).format("DD.MM.YYYY")}`}
                        </li>
                    }
                    {
                        (currentPoll?.completed) &&
                        <li className="list-inline-item"><i
                            className="las la-check-circle text-success align-middle me-1"></i>{"Пройдено"}
                        </li>
                    }
                    {
                        (currentPoll?.expired) &&
                        <li className="list-inline-item"><i
                            className="las la-times-circle text-danger align-middle me-1"></i>{"Завершено" + " " + moment(currentPoll?.okonchanie_oprosa).format("DD.MM.YYYY")}
                        </li>
                    }
                </ul>
                <div className="mt-3 p-2" style={{width: "100%"}}>
                    <h5>Описание: </h5>
                    <div
                        className="text-muted mb-2"
                        style={{
                            overflowWrap: "break-word",
                            whiteSpace: "pre-wrap",
                            maxHeight: "150px",
                            overflowY: "auto",
                        }}
                    >{currentPoll?.opisanie ? currentPoll?.opisanie : "Нет описания"}</div>
                </div>
                {currentPoll.predislovie &&
                    <div className="mt-3 p-2" style={{width: "100%"}}>
                        <h5>Предисловие: </h5>
                        <div
                            className="text-muted mb-2"
                            style={{
                                overflowWrap: "break-word",
                                whiteSpace: "pre-wrap",
                                maxHeight: "150px",
                                overflowY: "auto",
                            }}
                        >{currentPoll?.predislovie ? currentPoll?.predislovie : "Нет предисловия"}</div>
                    </div>
                }
                {
                    currentPoll.pending &&
                    <div className="mt-3 p-2">
                        <h5>Опрос: </h5>
                        {
                            currentPoll?.questions?.length > 0 &&
                            <Table bordered>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Вопрос</th>
                                    <th>Ответ</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    currentPoll?.questions?.map(({item}, index) => {
                                        const {__name, tip_voprosa, otvety, vopros_s_izobrazheniem, multiple_variants} = item;
                                        return (
                                            <tr key={index}>
                                                <td className="w-20">{index + 1}</td>
                                                <td className="w-50">
                                                    <div>{__name}</div>
                                                    {
                                                        vopros_s_izobrazheniem &&
                                                        <ImageComponent item={item.izobrazhenie}/>
                                                    }
                                                </td>

                                                <td className={"w-50"}>
                                                    {
                                                        (tip_voprosa[0].code === "zakrytyi" && __name) &&
                                                        <>
                                                            {
                                                                <form>
                                                                    {otvety.rows.map((answer, index) => {
                                                                        const isChecked = multiple_variants
                                                                            ? (selectedValues[__name] && selectedValues[__name]?.includes(answer.variant_1))
                                                                            : (selectedValues[__name] === answer.variant_1);

                                                                        return (
                                                                            <div className="form-check mb-2">
                                                                                <input className="form-check-input"
                                                                                       type={multiple_variants ? "checkbox" : "radio"}
                                                                                       name={`${__name}`}
                                                                                       required
                                                                                       id={`${__name}${answer?.variant_1}${index}`}
                                                                                       value={answer.variant_1}
                                                                                       checked={isChecked}
                                                                                       onChange={(e) => handleChange(e,__name, answer.variant_1, multiple_variants)}
                                                                                />
                                                                                <label
                                                                                    className="form-check-label align-bottom ms-1"
                                                                                    htmlFor={`${answer?.variant_1}1`}>{answer.variant_1}</label>
                                                                            </div>
                                                                        )
                                                                    })}

                                                                </form>
                                                            }
                                                        </>
                                                    }
                                                    {tip_voprosa[0].code === "otkrytyi" &&
                                                        <>
                                                            Ответ:
                                                            <Input
                                                                type="text"
                                                                name={`${__name}`}
                                                                required
                                                                value={selectedValues[`${__name}`] || ''}
                                                                onChange={(e) => handleChange(e,`${__name}`, e.target.value, multiple_variants, true)}
                                                            />
                                                        </>
                                                    }
                                                    {tip_voprosa[0].code === "otkryto-zakrytyi" &&
                                                        <div>
                                                            <>
                                                                {
                                                                    <form>
                                                                        {[...otvety.rows, {variant_1: "Другoе"}].map((answer, index) => {
                                                                            const isChecked = multiple_variants
                                                                                ? (selectedValues[__name] && selectedValues[__name]?.includes(answer.variant_1))
                                                                                : (selectedValues[__name] === answer.variant_1);

                                                                            return (
                                                                                <div className="form-check mb-2"
                                                                                     key={index}>
                                                                                    <input
                                                                                        className="form-check-input"
                                                                                        type={multiple_variants ? "checkbox" : "radio"}
                                                                                        name={`${__name}`}
                                                                                        required
                                                                                        id={`${__name}${answer?.variant_1}${index}`}
                                                                                        value={answer.variant_1}
                                                                                        checked={isChecked}
                                                                                        onChange={(e) => handleChange(e,__name, answer.variant_1, multiple_variants)}
                                                                                    />
                                                                                    <label
                                                                                        className="form-check-label align-bottom ms-1"
                                                                                        htmlFor={`${answer?.variant_1}1`}>{answer.variant_1}</label>
                                                                                </div>
                                                                            )
                                                                        })}
                                                                        {(selectedValues[`${__name}`] === "Другoе" || selectedValues[`${__name}`]?.includes("Другoе")) &&
                                                                            <div>
                                                                                Другoе:
                                                                                <Input
                                                                                    type="text"
                                                                                    required
                                                                                    id={`${__name}_other`}
                                                                                    name={`${__name}_other`}
                                                                                    onChange={(e) => handleChange(e,`${__name} (свой ответ)`, e.target.value, multiple_variants, true)}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </form>
                                                                }
                                                            </>
                                                        </div>
                                                    }
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                        }
                        {
                            <Button
                                type="submit"
                                color="success" disabled={isSending}
                                onClick={updatePoll}
                                className="btn btn-success">
                                {isSending ?
                                    <Spinner size="sm"
                                             className='me-2'> Отправка... </Spinner> : null}
                                Отправить
                            </Button>
                        }
                    </div>

                }
                {
                    (currentPoll.completed || currentPoll.expired) &&
                    <div className="mt-3 p-2">
                        <h5>Опрос завершен</h5>
                    </div>
                }

            </ModalBody>
        </Modal>
    );
}

export default ModalPoll;