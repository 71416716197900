import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CloseButton,
    Col,
    Container, Input,
    Modal,
    ModalBody,
    Row,
    Spinner,
    Table
} from "reactstrap";

//Import Breadcrumb
import BreadCrumbs from "../../Components/Common/BreadCrumb";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getLessonsList, pendLesson, sendLesson} from "../../slices/lessons/thunk";
import {Link} from "react-router-dom";
import Masonry from "react-masonry-component";
import moment from "moment/moment";
import {getFilenameFromElmaLink} from "../../helpers/getFilenameFromElmaLink";
import MyDocViewer from "../../Components/FileViewers/MyDocViewer";
import {getUsersList} from "../../slices/users/thunk";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

const EducationPage = () => {
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));

    const searchParams = new URLSearchParams(location.search);
    const lessonId = searchParams.get('lesson_id');


    const dispatch = useDispatch();

    const selectLessonsData = createSelector(
        (state) => state.Lessons.lessonsList,
        (lessonsList) => lessonsList
    );

    const selectUsersData = createSelector(
        (state) => state.Users.usersList,
        (usersList) => usersList
    );

    const lessonsList = useSelector(selectLessonsData);
    const usersList = useSelector(selectUsersData);

    const [allLessons, setAllLessons] = useState([]);
    const [displayCategory, setCategory] = useState("Новые");
    const [currentLesson, setCurrentLesson] = useState(null);
    const [modal_center, setmodal_center] = useState(false);
    const [myCommitment, setMyCommitment] = useState('');
    const [isSending, setIsSending] = useState(false);
    const [allUsers, setAllUsers] = useState([]);

    const [selectedValues, setSelectedValues] = useState({});
    const [isPendingSolution, setIsPendingSolution] = useState(false);

    const docsViewer = useMemo(() => {
        return <MyDocViewer docs={currentLesson?.faily_obucheniya}/>
    }, [currentLesson]);

    const handleChange = (e) => {
        setSelectedValues({
            ...selectedValues,
            [e.target.name]: e.target.value
        });
    };

    // useEffect(() => {
    //     dispatch(getLessonsList());
    //     dispatch(getUsersList());
    // }, [dispatch]);

    useEffect(() => {
        setAllLessons(lessonsList);
        setAllUsers(usersList);
    }, [lessonsList, usersList]);

    useEffect(() => {
        let lesson = allLessons?.find(e => e.__id === lessonId);
        if (currentLesson && lesson) {
            if (currentLesson && lesson.__id !== currentLesson.__id) {
                setCurrentLesson(lesson);
                setmodal_center(true);
            }
        } else if (lesson) {
            setCurrentLesson(lesson);
            setmodal_center(true);
        }

    }, [lessonId, allLessons]);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    async function sendCommitment(e) {

        setIsSending(true);

        const keyValuePairs = Object.entries(selectedValues);
        const stringifiedPairs = keyValuePairs.map(([name, answer]) => `${name}: ${answer}`).join(',\n');
        let test = stringifiedPairs + "\n Доп. информация:" + myCommitment;

        const commitment = {
            lessonId: currentLesson.__id,
            userId: currentUser.__id,
            mentorId: currentLesson?.mentor?.[0],
            reshenie: test
        }

        const newLessons = allLessons.filter(x=>x.__id!==currentLesson.__id);
        const newLesson = {...currentLesson, pending: true};
        setAllLessons([...newLessons, newLesson]);

        dispatch(sendLesson(commitment)).then(() => {
            setmodal_center(false);

            setTimeout(() => {
                toast.clearWaitingQueue();
            }, 3000);
            setMyCommitment('');
            setIsSending(false);
        }).catch(() => {
            setIsSending(false);
        });
    }

    async function updateLesson() {
        setIsSending(true);

        const users = currentLesson?.ozhidayut_proverki || [];
        const newUsers = [...users, currentUser.__id];

        let payload = {
            lessonId: currentLesson.__id,
            users: newUsers
        }
        const newLessons = allLessons.filter(x=>x.__id!==currentLesson.__id);
        const newLesson = {...currentLesson, pending: true};
        setAllLessons([...newLessons, newLesson]);

        dispatch(pendLesson(payload)).then(() => {
            setmodal_center(false);
            setTimeout(() => {
                toast.clearWaitingQueue();
            }, 3000);
        }).finally(() => {
            setIsSending(false);
        });
    }

    const showLesson = (lesson) => {
        if (lesson.dedlain) {
            return true;
        }
        if (lesson.dnei_posle_trudoustroistva) {
            const hireDatePlusDays = moment(currentUser.hireDate).add(lesson.dnei_posle_trudoustroistva, 'days');
            if (hireDatePlusDays.isSameOrBefore(moment(), 'day')) {
                return true;
            }
        }
        return !lesson.dedlain && !lesson.dnei_posle_trudoustroistva;
    };



    document.title = "Обучение";
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumbs title="Обучение" breadcrumbItem="Обучение"/>
                    <Row>
                        <Col lg={12}>
                            <div className="">
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <div className="text-center">
                                                <ul className="list-inline categories-filter animation-nav" id="filter">
                                                    <li className="list-inline-item">
                                                        <Link to="#" onClick={() => setCategory("Новые")}
                                                              className={displayCategory === "Новые" ? "categories active" : "categories"}
                                                              data-filter="*">Новые</Link></li>

                                                    <li className="list-inline-item"><Link to="#"
                                                                                           onClick={() => setCategory("Заочное")}
                                                                                           className={displayCategory === "Заочное" ? "categories active" : "categories"}
                                                                                           data-filter=".project">Заочное</Link>
                                                    </li>

                                                    <li className="list-inline-item"><Link to="#"
                                                                                           onClick={() => setCategory("Очное")}
                                                                                           className={displayCategory === "Очное" ? "categories active" : "categories"}
                                                                                           data-filter=".project">Очное</Link>
                                                    </li>
                                                    <li className="list-inline-item"><Link to="#"
                                                                                           onClick={() => setCategory("Я участник")}
                                                                                           className={displayCategory === "Я участник" ? "categories active" : "categories"}
                                                                                           data-filter=".project">Я
                                                        участник</Link>
                                                    </li>

                                                    <li className="list-inline-item"><Link to="#"
                                                                                           onClick={() => setCategory("На проверке")}
                                                                                           className={displayCategory === "На проверке" ? "categories active" : "categories"}
                                                                                           data-filter=".project">На
                                                        проверке</Link>
                                                    </li>
                                                    <li className="list-inline-item"><Link to="#"
                                                                                           onClick={() => setCategory("Отклонено")}
                                                                                           className={displayCategory === "Отклонено" ? "categories active" : "categories"}
                                                                                           data-filter=".project">Отклонено</Link>
                                                    </li>
                                                    <li className="list-inline-item"><Link to="#"
                                                                                           onClick={() => setCategory("Завершенные")}
                                                                                           className={displayCategory === "Завершенные" ? "categories active" : "categories"}
                                                                                           data-filter=".project">Завершенные</Link>
                                                    </li>
                                                </ul>
                                            </div>

                                            {(allLessons?.length > 0) && (
                                              <Masonry className="row gallery-wrapper">
                                                  {allLessons?.filter(({ tip_obucheniya, pending, completed, rejected }) =>
                                                    (displayCategory === "Заочное" && tip_obucheniya === true) ||
                                                    (displayCategory === "Очное" && tip_obucheniya === false) ||
                                                    (displayCategory === "На проверке" && pending && tip_obucheniya === true) ||
                                                    (displayCategory === "Отклонено" && rejected === true) ||
                                                    (displayCategory === "Я участник" && pending && tip_obucheniya === false) ||
                                                    (displayCategory === "Завершенные" && completed === true) ||
                                                    (displayCategory === "Новые" && !completed && !pending)
                                                  )?.map((item, key) => (
                                                      <Col
                                                        xxl={3}
                                                        xl={4}
                                                        sm={6}
                                                        className="element-item project designing development"
                                                        key={item.__id}
                                                      >
                                                          {showLesson(item) &&
                                                              <Card
                                                                className="border cursor-pointer"
                                                                onClick={() => {
                                                                    setCurrentLesson(item);
                                                                    tog_center();
                                                                }}
                                                              >
                                                                  <CardBody>
                                                                      <div>
                                                                          <h5 className="text-truncate text-truncate-two-lines">
                                                                              <Link to="#">{item.__name}</Link>
                                                                          </h5>
                                                                          {item.opisanie && (
                                                                            <div className="text-muted mb-2 text-truncate-four-lines">
                                                                                {item.opisanie}
                                                                            </div>
                                                                          )}
                                                                      </div>
                                                                      <div className="d-flex justify-content-between align-items-center">
                                                                          <div>
                                                                              <i className="las la-graduation-cap text-success align-middle me-1"></i>
                                                                              {item.tip_obucheniya ? "Заочное" : "Очное"}
                                                                          </div>
                                                                          <div>
                                                                              {item?.pending && item?.tip_obucheniya && (
                                                                                <li className="list-inline-item">
                                                                                    <i className="las la-clock text-warning align-middle me-1"></i>
                                                                                    {"На проверке"}
                                                                                </li>
                                                                              )}
                                                                              {item?.pending && !item?.tip_obucheniya && (
                                                                                <li className="list-inline-item">
                                                                                    <i className="las la-calendar text-warning align-middle me-1"></i>
                                                                                    {"Вы пойдете"}
                                                                                </li>
                                                                              )}
                                                                              {item?.completed && (
                                                                                <li className="list-inline-item">
                                                                                    <i className="las la-check-circle text-success align-middle me-1"></i>
                                                                                    {"Пройдено"}
                                                                                </li>
                                                                              )}
                                                                              {item?.rejected && (
                                                                                <li className="list-inline-item">
                                                                                    <i className="las la-times-circle text-danger align-middle me-1"></i>
                                                                                    {"Отклонено"}
                                                                                </li>
                                                                              )}
                                                                              {!item?.rejected && !item?.completed && !item?.pending && (
                                                                                <li className="list-inline-item">
                                                                                    <i className="las la-plus-circle text-success align-middle me-1"></i>
                                                                                    {"Новое"}
                                                                                </li>
                                                                              )}
                                                                          </div>
                                                                      </div>
                                                                      <div style={{height: "20px"}}>
                                                                          {item.dedlain && (
                                                                            <div className={`${moment(item.dedlain).isSameOrBefore(moment.now()) ? 'text-danger' : ''}`}>
                                                                                Пройти до: {moment(item.dedlain).format("DD-MM-YYYY")}
                                                                            </div>
                                                                          )}
                                                                      </div>
                                                                  </CardBody>
                                                              </Card>
                                                          }
                                                      </Col>
                                                    )
                                                  )}
                                              </Masonry>
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </Col>
                    </Row>

                    {currentLesson && <Modal
                        size="xl"
                        isOpen={modal_center}
                        toggle={() => {
                            tog_center();
                        }}
                        centered
                    >
                        <ModalBody>
                            <div className="d-flex justify-content-between"><h4
                                className="mb-3">{currentLesson?.__name}</h4>
                                <CloseButton onClick={() => tog_center()}/></div>

                            {currentLesson?.tip_obucheniya ? <>
                                    <ul className="list-inline mb-0">
                                        <li className="list-inline-item"><i
                                            className="ri-calendar-2-fill text-success align-middle me-1"></i> {moment(currentLesson?.__createdAt).format("DD.MM.YYYY")}
                                        </li>
                                        <li className="list-inline-item"><i
                                            className="las la-graduation-cap text-success align-middle me-1"></i>{currentLesson?.tip_obucheniya ? "Заочное" : "Очное"}
                                        </li>
                                        {
                                            (currentLesson?.pending) &&
                                            <li className="list-inline-item"><i
                                                className="las la-clock text-warning align-middle me-1"></i>{"На проверке"}
                                            </li>
                                        }
                                        {
                                            (currentLesson?.completed) &&
                                            <li className="list-inline-item"><i
                                                className="las la-check-circle text-success align-middle me-1"></i>{"Пройдено"}
                                            </li>
                                        }
                                        {
                                            (currentLesson?.rejected) &&
                                            <li className="list-inline-item"><i
                                                className="las la-times-circle text-danger align-middle me-1"></i>{"Отклонено"}
                                            </li>
                                        }

                                        {
                                            currentLesson.mentor && (
                                                <li><i
                                                    className="las la-user-graduate text-success align-middle me-1"></i>{allUsers.find((user) => user.__id === currentLesson.mentor[0])?.__name}
                                                </li>
                                            )
                                        }
                                    </ul>
                                    {(currentLesson?.faily_obucheniya && currentLesson?.faily_obucheniya.length > 0) &&
                                        docsViewer
                                    }
                                    <div className="mt-3 p-2">
                                        {currentLesson?.opisanie && (
                                          <div>
                                              <h5>Описание: </h5>
                                                <div className="text-muted mb-2 line-clamp-4 p-2">
                                                    {currentLesson?.opisanie}
                                                </div>
                                          </div>
                                        )}
                                    </div>
                                    <div className="mt-3 p-2">
                                        {currentLesson?.faily_obucheniya &&
                                          <h5>Файлы: </h5>
                                        }
                                        <ol>
                                            {currentLesson?.faily_obucheniya && (
                                                currentLesson?.faily_obucheniya.map((item, key) => (
                                                    <li key={key}>
                                                        <Link to={item}>
                                                            {getFilenameFromElmaLink(item)}
                                                        </Link>
                                                    </li>
                                                ))
                                            )}
                                        </ol>
                                    </div>
                                    {
                                        (!currentLesson?.completed && !currentLesson?.pending) &&
                                        <Card>
                                            <CardBody>
                                                <Card>
                                                    <CardHeader>
                                                        <h4 className={"mb-3 text-center"}>Задание</h4>
                                                    </CardHeader>
                                                    <CardBody>
                                                        {currentLesson?.test_posle_obucheniya?.rows && (
                                                            <Table bordered>
                                                                <thead>
                                                                <tr>
                                                                    <th>#</th>
                                                                    <th>Вопрос</th>
                                                                    <th>Ответ</th>
                                                                </tr>
                                                                </thead>
                                                                <tbody>
                                                                {currentLesson?.test_posle_obucheniya?.rows.map((item, key) => (
                                                                    <tr key={key}>
                                                                        <td>{key + 1}</td>
                                                                        <td>{item?.vopros}</td>
                                                                        <td>
                                                                            {
                                                                                item?.zakrytyi_vopros ?
                                                                                    <form>
                                                                                        <div className="form-check mb-2">
                                                                                            <input className="form-check-input"
                                                                                                   type="radio"
                                                                                                   name={`${item?.vopros}`}
                                                                                                   id={`${item?.vopros}1`}
                                                                                                   value={item?.variant_1}
                                                                                                   checked={selectedValues[`${item?.vopros}`] === item?.variant_1}
                                                                                                   onChange={handleChange}/>
                                                                                            <label
                                                                                                className="form-check-label align-bottom ms-1"
                                                                                                htmlFor={`${item?.vopros}1`}>{item?.variant_1}</label>
                                                                                        </div>
                                                                                        <div className="form-check mb-2">
                                                                                            <input className="form-check-input"
                                                                                                   type="radio"
                                                                                                   name={`${item?.vopros}`}
                                                                                                   id={`${item?.vopros}2`}
                                                                                                   value={item?.variant_2}
                                                                                                   checked={selectedValues[`${item?.vopros}`] === item?.variant_2}
                                                                                                   onChange={handleChange}/>
                                                                                            <label
                                                                                                className="form-check-label align-bottom ms-1"
                                                                                                htmlFor={`${item?.vopros}2`}>{item?.variant_2}</label>
                                                                                        </div>
                                                                                        <div className="form-check mb-2">
                                                                                            <input className="form-check-input"
                                                                                                   type="radio"
                                                                                                   name={`${item?.vopros}`}
                                                                                                   id={`${item?.vopros}3`}
                                                                                                   value={item?.variant_3}
                                                                                                   checked={selectedValues[`${item?.vopros}`] === item?.variant_3}
                                                                                                   onChange={handleChange}/>
                                                                                            <label
                                                                                                className="form-check-label align-bottom ms-1"
                                                                                                htmlFor={`${item?.vopros}3`}>{item?.variant_3}</label>
                                                                                        </div>
                                                                                        {
                                                                                            item?.variant_4 && (
                                                                                                <div
                                                                                                    className="form-check mb-2">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="radio"
                                                                                                        name={`${item?.vopros}`}
                                                                                                        id={`${item?.vopros}4`}
                                                                                                        value={item?.variant_4}
                                                                                                        checked={selectedValues[`${item?.vopros}`] === item?.variant_4}
                                                                                                        onChange={handleChange}/>
                                                                                                    <label
                                                                                                        className="form-check-label align-bottom ms-1"
                                                                                                        htmlFor={`${item?.vopros}4`}>{item?.variant_4}</label>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        {
                                                                                            item?.variant_5 && (
                                                                                                <div
                                                                                                    className="form-check mb-2">
                                                                                                    <input
                                                                                                        className="form-check-input"
                                                                                                        type="radio"
                                                                                                        name={`${item?.vopros}`}
                                                                                                        id={`${item?.vopros}5`}
                                                                                                        value={item?.variant_5}
                                                                                                        checked={selectedValues[`${item?.vopros}`] === item?.variant_5}
                                                                                                        onChange={handleChange}/>
                                                                                                    <label
                                                                                                        className="form-check-label align-bottom ms-1"
                                                                                                        htmlFor={`${item?.vopros}5`}>{item?.variant_5}</label>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                    </form>
                                                                                    :
                                                                                    <Input
                                                                                        type="text"
                                                                                        name={`${item?.vopros}`}
                                                                                        value={selectedValues[`${item?.vopros}`] || ''}
                                                                                        onChange={handleChange}
                                                                                    />
                                                                            }
                                                                        </td>
                                                                    </tr>))}
                                                                </tbody>
                                                            </Table>
                                                        )}
                                                    </CardBody>
                                                </Card>
                                                <form className="mt-4" onSubmit={(e) => {
                                                    e.preventDefault()
                                                    sendCommitment(e.target)
                                                }}>
                                                    <Row className="g-3">
                                                        <Col xs={12}>
                                                            <label htmlFor="exampleFormControlTextarea1"
                                                                   className="form-label text-body">Доп информация к решению: </label>
                                                            <textarea
                                                                className="form-control bg-light border-light"
                                                                id="exampleFormControlTextarea1" rows="3"
                                                                value={myCommitment}
                                                                onChange={(e) => setMyCommitment(e.target.value)}
                                                                placeholder="Введите комментарии..."></textarea>
                                                        </Col>
                                                        <Col xs={12} className="text-end">
                                                            <Button
                                                                type="submit"
                                                                color="success" disabled={isSending}
                                                                className="btn btn-success">
                                                                {isSending ?
                                                                    <Spinner size="sm"
                                                                             className='me-2'> Отправка... </Spinner> : null}
                                                                Отправить на проверку
                                                            </Button>
                                                        </Col>
                                                    </Row>
                                                </form>
                                            </CardBody>
                                        </Card>
                                    }
                                    {
                                        currentLesson?.completed &&
                                        <Card>
                                            <CardBody>
                                                <h5 className={"mb-3 text-center"}>Задание выполнено.</h5>
                                            </CardBody>
                                        </Card>
                                    }
                                    {
                                        currentLesson?.pending &&
                                        <Card>
                                            <CardBody>
                                                <h5 className={"mb-3 text-center"}><i
                                                    className="las la-clock text-warning align-middle me-1"></i>Задание на
                                                    проверке...</h5>
                                                <p>Проверяющий:</p>
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-xs flex-shrink-0 me-3">
                                                        <img
                                                            src={allUsers?.find(user => user.__id === currentLesson?.mentor[0])?.avatar}
                                                            alt=""
                                                            className="img-fluid rounded-circle"/>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h5 className="fs-13 mb-0"><Link
                                                            to={`/profile?user_id=${currentLesson?.mentor[0]}`}
                                                            className="text-body d-block">{allUsers?.find(user => user.__id === currentLesson?.mentor[0])?.__name}</Link>
                                                        </h5>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    }
                                </> :
                                <>
                                    <div className="mt-3 p-2">
                                        <div className="p-2">
                                            <h5>Описание: </h5>
                                            <div className="text-muted mb-2">{currentLesson?.opisanie}</div>
                                        </div>
                                        <div className="table-responsive table-card p-2">
                                            <Table className="table mb-0">
                                                <tbody>
                                                <tr>
                                                    <td className="fw-medium">Название:</td>
                                                    <td>{currentLesson?.__name}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">Место:</td>
                                                    <td>{currentLesson?.mesto_ochnogo_obucheniya}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">Дата:</td>
                                                    <td>{(currentLesson.data_vremya_ochnogo_obucheniya || currentLesson.dedlain) ? moment(currentLesson.data_vremya_ochnogo_obucheniya || currentLesson.dedlain).format('DD MMMM') : 'без даты'}</td>
                                                </tr>
                                                <tr>
                                                    <td className="fw-medium">Время:</td>
                                                    <td>
                                                        {currentLesson.data_vremya_ochnogo_obucheniya ? moment(currentLesson.data_vremya_ochnogo_obucheniya).format('HH:mm') : 'без времени'}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </Table>
                                        </div>
                                        <div className="mt-4 p-2">
                                            <h5>Доп информация: </h5>
                                            <div
                                                className="text-muted mb-2">{currentLesson?.dop_informaciya_po_ochnomu_obucheniyu}</div>
                                        </div>
                                        {currentLesson?.pending ? <li className="list-inline-item fs-16 align-bottom"><i
                                                className="las la-calendar fs-24 text-warning align-middle me-1"/>
                                                Вы пойдете
                                            </li> :
                                            <Button
                                                type="submit"
                                                color="success" disabled={isSending}
                                                onClick={updateLesson}
                                                className="btn btn-success">
                                                {isSending ?
                                                    <Spinner size="sm"
                                                             className='me-2'> Отправка... </Spinner> : null}
                                                Принять
                                            </Button>}
                                    </div>
                                </>
                            }
                        </ModalBody>
                    </Modal>}
                    <ToastContainer closeButton={false} limit={1}/>
                </Container>
            </div>
        </>
    );
}

export default EducationPage;