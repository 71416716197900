import React from 'react';
import {Card, CardBody, CardHeader, Col, Row, Table} from "reactstrap";


function DolzhnostTab({myProfile, currentUser, userId, mockOS}) {

    const isMyProfile = userId === currentUser.__id;
    const dolzhnost = myProfile['dolzhnost'];

    return (
        <Card>
            <CardHeader>
                <h2 className="card-title">{dolzhnost['__name'] ?? ''}</h2>
            </CardHeader>
            <CardBody>
                <Row>
                    <Col xxl={8}>

                        <div className="mb-2">
                            <h4 className="font-bold">Ключевая функция</h4>
                            <p>{dolzhnost['klyuchevaya_funkciya']}</p>
                        </div>

                        <div className="mb-2">
                            <h4 className="font-bold">Решает следующие задачи</h4>
                            <p>{dolzhnost['reshaet_sleduyushie_zadachi']}</p>
                        </div>
                        <div className="mb-2">
                            <h4 className="font-bold">Горизонтальное взаимодействие</h4>
                            <p>{dolzhnost['gorizontalnoe_vzaimodeistvie']}</p>
                        </div>

                        {isMyProfile && <>
                            <div className="mb-2">
                                <h4 className="font-bold">Результат деятельности</h4>
                                <p>{dolzhnost['rezultat_deyatelnosti']}</p>
                            </div>
                            <div className="mb-2"><h4 className="font-bold">Показатели эффективности</h4>
                                <p>{dolzhnost['pokazateli_effektivnosti']}</p>
                            </div>
                            <div className="mb-2">
                                <h4 className="font-bold">Требуемые личные качества</h4>
                                <p>{dolzhnost['trebuemye_lichnye_kachestva']}</p>
                            </div>

                            <div className="mb-2">
                                <h4 className="font-bold">Условия труда и характер работы</h4>
                                <p>{dolzhnost['opisanie']}</p>
                            </div>

                            <Card>
                                <CardBody>
                                    <h5 className="card-title mb-4">Необходимые Soft
                                        skills</h5>
                                    <div className="d-flex flex-wrap gap-2 fs-15">
                                        {
                                            (dolzhnost['neobkhodimye_soft_skills']?.split(',') || []).map((item, key) => (
                                                <span
                                                    key={key}
                                                    className="badge bg-primary-subtle text-truncate text-primary">{item}</span>
                                            ))
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <h5 className="card-title mb-4">Необходимые Hard
                                        skills</h5>
                                    <div className="d-flex flex-wrap gap-2 fs-15">
                                        {
                                            (dolzhnost['neobkhodimye_hard_skills']?.split(',') || []).map((item, key) => (
                                                <span
                                                    key={key}
                                                    className="badge bg-primary-subtle text-truncate text-primary">{item}</span>
                                            ))
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </>}

                    </Col>

                    <Col xxl={4}>
                        <Card className="card">
                            <CardBody className="card-body">
                                <div className="table-responsive table-card">
                                    <Table className="table mb-0">
                                        <tbody>
                                        <tr>
                                            <td className="fw-medium">Структурное подразделение:</td>
                                            <td>
                                                {dolzhnost['strukturnoe_podrazdelenie']?.map((x, key) => (
                                                    <span
                                                        key={key}>{mockOS.find(y => x.code === y.__id)?.name ?? ''}</span>
                                                ))
                                                }
                                            </td>
                                        </tr>

                                        {isMyProfile && <tr>
                                            <td className="fw-medium">Должность из оргструктуры:</td>
                                            <td>{mockOS.find(x => dolzhnost['dolzhnost_iz_orgstruktury']?.[0]?.code === x.__id)?.name ?? ''}</td>
                                        </tr>}

                                        <tr>
                                            <td className="fw-medium">Непосредственный руководитель:</td>
                                            <td>
                                                <div className="fs-13 mb-0">
                                                    <span className="text-body d-block">
                                                    {mockOS.find(x => dolzhnost['neposredstvennyi_rukovoditel']?.[0]?.code === x.__id)?.name ?? ''}
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                        {isMyProfile && <tr>
                                            <td className="fw-medium">Требования к
                                                образованию:
                                            </td>
                                            <td>{dolzhnost['trebovaniya_k_obrazovaniyu'] ?? ''}</td>
                                        </tr>}
                                        {isMyProfile && <tr>
                                            <td className="fw-medium">Требования к
                                                опыту
                                                работы:
                                            </td>
                                            <td>{dolzhnost['trebovaniya_k_opytu_raboty'] ?? ''}</td>
                                        </tr>}
                                        </tbody>
                                    </Table>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader
                                className="align-items-center d-flex border-bottom-dashed">
                                <h4 className="card-title mb-0 flex-grow-1">Подчиненные</h4>
                            </CardHeader>

                            <CardBody>
                                {
                                    dolzhnost['strukturnoe_podrazdelenie']?.map((x, key) => (
                                        <h5 className="fs-13 mb-0" key={key}>
                                            <span className="text-body d-block">{mockOS.find(y => x.code === y.__id)?.name ?? ''}</span>
                                        </h5>
                                    ))
                                }
                            </CardBody>

                        </Card>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default DolzhnostTab;