import React, {useEffect, useMemo, useState} from 'react';
import mock from './mock.json'
//Import Breadcrumb
import Loader from "../../Components/Common/Loader";
import {Card, CardBody, CloseButton, Col, Container, Modal, ModalBody, Offcanvas, OffcanvasBody, Row} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import Masonry from "react-masonry-component";
import {getKnowledgeApps, getKnowledgeItems} from "../../api/knowledgeBase";
import {ToastContainer} from "react-toastify";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import MyDocViewer from "../../Components/FileViewers/MyDocViewer";
import {getFilenameFromElmaLink} from "../../helpers/getFilenameFromElmaLink";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getUsersList} from "../../slices/users/thunk";
import profileBg from "../../assets/images/profile-bg.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

const newPage = () => {
    document.title = "База знаний";   //for meta title
    const [loading, setLoading] = useState(false);
    const history = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const [mockData, setMockData] = useState([]);
    const [categoryItemsData, setCategoryItemsData] = useState([]);
    const [currentItem, setCurrentItem] = useState(null);
    const [currentCategory, setCurrentCategory] = useState(searchParams.get('cat') || "Все");
    const [displayCategory, setCategory] = useState("Все");
    const [covers, setCovers] = useState([]);
    const [modal_center, setmodal_center] = useState(false);

    const [isOpen, setIsOpen] = useState(false);
    const [sideBar, setSideBar] = useState([]);

    const [folders, setFolders] = useState([]);
    const [currentFolder, setCurrentFolder] = useState("Все");


    const docsViewer = useMemo(() => {
        return <MyDocViewer docs={currentItem?.faily}/>
    }, [currentItem]);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    useEffect(() => {
        if (!searchParams.get('cat')) {
            history('/knowledge');
        }
        setCurrentCategory(searchParams.get('cat') || "Все");
    }, [searchParams]);


    useEffect(() => {
        (async () => {
            if (currentCategory !== "Все") {
                setLoading(true);

                try {
                    const apps = await getKnowledgeApps();
                    setMockData(apps.map(app => {
                        app.image = "https://placehold.co/600x400";
                        return app
                    }));

                    await getItemsByApp(currentCategory);
                } catch (error) {
                    // handle error here
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            }
        })();

    }, [currentCategory]);


    async function getItemsByApp(app) {
        return await getKnowledgeItems(app)
            .then(r => {
                setCategoryItemsData(r);

                const arr2 = r.map(x => {
                    if (x.kategoriya) return x.kategoriya[0];
                });

                const arr3 = arr2.filter(obj => obj && Object.keys(obj).length > 0);

                const arr4 = arr3.filter((obj, index, self) =>
                        index === self.findIndex((t) => (
                            t?.code === obj?.code
                        ))
                );

                setFolders(arr4);
            })
            .finally(() => setLoading(false));
    }

    if (loading) return (<div className="page-content"><Loader/></div>);

    return (
        <div className="page-content">
            <Container fluid={true}>
                <BreadCrumb title={mockData.find(x => x.code === currentCategory)?.name}
                            pageTitle="База знаний"
                            link="/knowledge"
                            back={true}
                />
                <Row>
                    <Col lg={12}>
                        <div className="">
                            <CardBody>
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center">
                                            <ul className="list-inline categories-filter animation-nav" id="filter">
                                                <li className="list-inline-item">
                                                    <Link to={`?cat=${currentCategory}`}
                                                          onClick={() => setCurrentFolder("Все")}
                                                          className={currentFolder === "Все" ? "categories active" : "categories"}
                                                          data-filter="*">Все</Link>
                                                </li>
                                                {
                                                    folders?.map((f, key) => (
                                                        <li className="list-inline-item" key={f?.code}>
                                                            <Link to={`?cat=${currentCategory}`}
                                                                  onClick={() => setCurrentFolder(f?.name)}
                                                                  className={currentFolder === f.name ? "categories active" : "categories"}
                                                                  data-filter={f?.code}>{f?.name}</Link>
                                                        </li>))
                                                }
                                            </ul>
                                        </div>

                                        {(categoryItemsData?.length > 0) && <Masonry className="row gallery-wrapper">
                                            {categoryItemsData.filter((item) => (currentFolder === "Все" || item.kategoriya && currentFolder === item?.kategoriya[0]?.name)).map((item, key) => (
                                                <Col xxl={4} xl={5} sm={6}
                                                     className="element-item project designing development"
                                                     key={key}>
                                                    <Card className="border cursor-pointer" onClick={() => {
                                                        setCurrentItem(item);
                                                        tog_center()
                                                    }}>
                                                        <CardBody>
                                                            <div
                                                                className={"d-flex align-items-top justify-content-between"}>
                                                                <h5 className="text-truncate text-truncate-two-lines mb-1">
                                                                    {item.__name}
                                                                </h5>
                                                                {(item.kategoriya && currentFolder === "Все") && <div
                                                                    className={"text-sm text-muted"}>({item.kategoriya[0]?.name})</div>}
                                                            </div>


                                                            <div
                                                                className="text-muted mb-2 text-truncate-four-lines"
                                                                style={{
                                                                    maxHeight: '100px',
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis'
                                                                }}
                                                            >
                                                                {item?.opisanie && (
                                                                    item?.opisanie.split('\n').slice(0, 2).map((line, index) => (
                                                                        <div key={index} className="text-muted p-2">
                                                                            {line}
                                                                        </div>
                                                                    ))
                                                                )}

                                                            </div>

                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            ))}
                                        </Masonry>}
                                    </Col>
                                </Row>
                            </CardBody>
                        </div>
                    </Col>
                </Row>


                {currentItem && <Modal
                    size="xl"
                    isOpen={modal_center}
                    toggle={() => {
                        tog_center();
                    }}
                    centered
                >
                    <ModalBody>

                        <div className="d-flex justify-content-between">

                            <h4 className="mb-3">{currentItem?.__name}</h4>
                            <CloseButton onClick={() => tog_center()}/>

                        </div>

                        {(currentItem?.faily && currentItem?.faily.length > 0) &&
                            docsViewer
                        }

                        <div className="mt-3 p-2">
                            <h5>Описание: </h5>
                            {currentItem?.opisanie && (
                                currentItem?.opisanie.split('\n').map((line, index) => (
                                    <div key={index} className="text-muted mb-2 line-clamp-4 p-2">
                                        {line}
                                    </div>
                                ))
                            )}
                        </div>

                        <div className="mt-3 p-2">
                            <h5>Файлы: </h5>
                            <ol>
                                {currentItem?.faily && (
                                    currentItem?.faily.map((item, key) => (
                                        <li key={key}>
                                            <Link to={item}>
                                                {getFilenameFromElmaLink(item)}
                                            </Link>
                                        </li>
                                    ))
                                )}
                            </ol>
                        </div>
                    </ModalBody>
                </Modal>}



                <ToastContainer closeButton={false} limit={1}/>
            </Container>


        </div>
    )

}

export default newPage;