import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Input, Label} from 'reactstrap';
import {createSelector} from 'reselect';
import {useDispatch, useSelector} from 'react-redux';

//import images
import avatar3 from "../../assets/images/users/avatar-3.jpg";
import {getUserByEmail} from "../../api/myAPI";
import {changeDevModeThunk, loadDevModeFromSessionStorageThunk} from "../../slices/devMode/thunk";

const ProfileDropdown = () => {

    const dispatch = useDispatch();
    const devModeState = useSelector((state) => state.DevMode.devMode);
    const [userItem, setUserItem] = useState(null);
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        dispatch(loadDevModeFromSessionStorageThunk());
    }, [dispatch]);

    const devModeToggleHandler = async (devmode) => {
        await dispatch(changeDevModeThunk(devmode));
    }


    const profiledropdownData = createSelector(
        (state) => state.Profile.user,
        (user) => user
    );
    // Inside your component
    const user = useSelector(profiledropdownData);
    const [userName, setUserName] = useState("user");
    const [avatar, setAvatar] = useState(avatar3);

    useEffect(() => {
        if (JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"))) {
            const obj = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
            setUserName(obj.fullname?.firstname + " " + obj.fullname?.lastname);
            setAvatar(obj?.avatar);
            setUserItem(obj);
            obj.groupIds?.includes("d6000da0-c9aa-55eb-9882-f118b432730b") || obj.__id === "a12793ef-0195-4185-8358-aafc13f332a0" ? setIsAdmin(true) : setIsAdmin(false);
        }
    }, [userName, user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    useEffect(() => {
        async function updateProfile() {
            const obj = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
            const me = await getUserByEmail(obj.email);
            setUserName(me.fullname?.firstname + " " + me.fullname?.lastname);
            setAvatar(me?.avatar);
        }

        updateProfile();
    }, [])


    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown}
                      className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar}
                             alt="Header Avatar"/>
                        <span className="text-start ms-xl-2">
                            <span
                                className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName ?? 'Имя'}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <DropdownItem className='p-0'>
                        <Link to={"/profile"} target={"_self"} className="dropdown-item">
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                            <span className="align-middle">Профиль</span>
                        </Link>
                    </DropdownItem>
                    {
                        isAdmin &&
                        <DropdownItem className='p-0'>
                            <div className="dropdown-item">
                                <div className="form-check form-switch ">
                                    <Input className="form-check-input"
                                           type="checkbox"
                                           checked={devModeState}
                                           onChange={(e) => {
                                               devModeToggleHandler(e.target.checked);
                                           }}
                                           role="switch"
                                           id="devmodeToggler"/>
                                    <Label className="form-check-label"
                                           for="devmodeToggler">Тест. режим</Label>
                                </div>
                            </div>
                        </DropdownItem>
                    }
                    <DropdownItem className='p-0'>
                        <Link to={"https://hr.mc-medprof.ru/sso-logout"} target={"_top"} className="dropdown-item">
                            <i
                                className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span
                            className="align-middle" data-key="t-logout">Выйти</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;