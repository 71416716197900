import {createAsyncThunk} from "@reduxjs/toolkit";
import mockUsers from "../../pages/Company/mockUsers.json";
import {getAllUsers} from "../../api/myAPI";

export const getUsersList = createAsyncThunk("users/getUsers", async () => {
    try {
        return await getAllUsers().then(r=> r);
    } catch (error) {
        return error;
    }
});

