import { createSlice } from "@reduxjs/toolkit";
import {getVacanciesList} from './thunk';

export const initialState = {
    vacanciesList: [],
    error: {},
};

const VacanciesSlice = createSlice({
    name: 'Vacancies',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getVacanciesList.fulfilled, (state, action) => {
            state.vacanciesList = action.payload;
        });
        builder.addCase(getVacanciesList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default VacanciesSlice.reducer;