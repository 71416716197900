import { createSlice } from "@reduxjs/toolkit";
import {getLessonsList, pendLesson, sendLesson} from './thunk';

export const initialState = {
    lessonsList: [],
    error: {},
};

const LessonsSlice = createSlice({
    name: 'Lessons',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getLessonsList.fulfilled, (state, action) => {
            state.lessonsList = action.payload;
        });
        builder.addCase(getLessonsList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
        builder.addCase(sendLesson.fulfilled, (state, action) => {
            state.isLessonSent = true;
        });
        builder.addCase(sendLesson.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isLessonSent = false;
        });
        builder.addCase(pendLesson.fulfilled, (state, action) => {
            state.isLessonPend = true;
        });
        builder.addCase(pendLesson.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.isLessonPend = false;
        });
    }
});

export default LessonsSlice.reducer;