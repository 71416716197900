import ElmaAPI, {getFileLink} from "./myAPI";
import galleryMock from "../pages/Gallery/galleryMock.json";
export const getGallery = async () => {
    return await ElmaAPI.get('/gallery').then(r=>r.data).catch(e=>{return galleryMock});
}

export const getGalleryAlbums = async (albumId) => {
    //return album_mock.item;
    return  await ElmaAPI.get(`/gallery/`+albumId).then(r=>r.data.item).catch(e=>{return e});
}


const album_mock = {
    "success": true,
    "error": "",
    "item": {
        "__createdAt": "2024-06-03T22:06:40Z",
        "__createdBy": "3109cc73-5bfa-4862-b201-a5f0b9f0c046",
        "__debug": false,
        "__deletedAt": null,
        "__directory": null,
        "__externalId": null,
        "__externalProcessMeta": null,
        "__id": "018fe020-a9e5-7dec-83c6-28233d9a95ff",
        "__index": 11,
        "__name": "Random Album",
        "__subscribers": [
            "3109cc73-5bfa-4862-b201-a5f0b9f0c046"
        ],
        "__updatedAt": "2024-06-03T22:06:40Z",
        "__updatedBy": "3109cc73-5bfa-4862-b201-a5f0b9f0c046",
        "__version": 1717452400,
        "faily": [
            "018fe023-e980-78e0-bdfc-c2ee3e7c5049"
        ],
        "soderzhimoe_alboma": [
            "https://co.mc-medprof.ru/s3elma365/b225fd9f-4f6b-4ff2-bc51-e6e01e10252b?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%2706.jpg%3B&X-Amz-Signature=cffa1c9f06030afe8ca8a315c80d5face7dba442ee9ed73bf893a03e025edf7b",
            "https://co.mc-medprof.ru/s3elma365/f0044e69-166e-4d2e-b245-8c4203c6fd36?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo_2024-06-02_14-49-40.jpg%3B&X-Amz-Signature=0ff4d0290e443817eb1e84bebc8114ae10a3ee0560e978b809d292298de4f177",
            "https://co.mc-medprof.ru/s3elma365/fed48024-d9ea-4bcd-b2ff-3fe6d03b0658?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1714030962958-0a0e06717ea5.jpg%3B&X-Amz-Signature=976e3197f3cbd4d98b8d2976e6b8e7049729c9eb86808da4addfa82aec75864a",
            "https://co.mc-medprof.ru/s3elma365/56e22b2f-d660-4738-b06f-c84272fac051?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1714836999719-86884fbe3ee2.jpg%3B&X-Amz-Signature=e8fe6e76116881e91b81bd0bd3e9480c2b1259bd52eb1b66892af36e0e9b92a7",
            "https://co.mc-medprof.ru/s3elma365/7862a8c0-efda-4030-bfb8-3c8e924cfa8f?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1715128083376-06b7eca1b981.jpg%3B&X-Amz-Signature=bfa462cec3aba841ce7aaa3275213fce81ab974289212fde98c469a9dcbc7bbc",
            "https://co.mc-medprof.ru/s3elma365/7f6d5018-7c88-4c2e-9587-92c6455a1f7b?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1715285372355-b60cc02121da.jpg%3B&X-Amz-Signature=1089b115780017dddee15b0d1dd8c409aa38770e73200a8620bb2bee40ee10e2",
            "https://co.mc-medprof.ru/s3elma365/55189a40-d6bf-4a32-a5c1-eb0e6a3c64ed?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1715313036312-01d046cd99b8.jpg%3B&X-Amz-Signature=4c3780a02b31c56c5059973532e0d5ce8c6e6091b1736f74efdb6f51545ed303",
            "https://co.mc-medprof.ru/s3elma365/b07eb27c-40b5-4431-97c6-5122a3bf8566?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1715571527459-84b49e550d2c.jpg%3B&X-Amz-Signature=1a029c2e686e680a5f12889c1ca39d974170a530a56447e38301997c2ebb42fe",
            "https://co.mc-medprof.ru/s3elma365/0afeaf1d-9c75-40b6-b754-b8f143f25fcc?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1715704698525-6581ceb2d32f.jpg%3B&X-Amz-Signature=c2eec60461d8da04c6ebed4495bb06b7dbad7bd71a38a985f6772df4c6e2b6c8",
            "https://co.mc-medprof.ru/s3elma365/09bf7807-7993-4e41-8d47-cf55783f5f3e?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1715768731984-681108168122.jpg%3B&X-Amz-Signature=971076f768704969047f739549f505debc573547e3dd156ae6514abe1a7245dd",
            "https://co.mc-medprof.ru/s3elma365/6c344c29-223e-4a9f-88d7-c976bb631b61?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1715960901706-4f0f4913756e.jpg%3B&X-Amz-Signature=9c83166ff62414ceed3f59c3d97f83f1efdc79a8314839181aa7419b1e832d51",
            "https://co.mc-medprof.ru/s3elma365/81bbbdff-014f-4c94-8e60-fd51db2e8145?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1716315008771-7b572540a417.jpg%3B&X-Amz-Signature=7326cd4d5f662bb021250015e261737167ffc3ffeb8cd6d3bb215d00cbda19e0",
            "https://co.mc-medprof.ru/s3elma365/06dd5350-d4b0-438c-a630-c0d53a2cedf3?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1716339140080-be256d3270ce.jpg%3B&X-Amz-Signature=b208b6d1c314472a407d6744b1bf5535804aa8dfa151d9f64b77a723e1af536b",
            "https://co.mc-medprof.ru/s3elma365/12365567-1caf-4c36-98c0-97c2f55faead?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27photo-1717226263667-7ce6f7f35d9d.jpg%3B&X-Amz-Signature=8ffc9eb0a8ed0903fb4d8cba3c45a22c8e8784a4bdda1de12c386c0a20a30ad2",
            "https://co.mc-medprof.ru/s3elma365/01914f93-5821-4fb1-a072-e94d0698dca7?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27hero-tech-support.jpg%3B&X-Amz-Signature=0a65acf7ef9ca83a8e722f4a5c21c19ab034f4eb9dbac2dbe3b25ae8929a00d2",
            "https://co.mc-medprof.ru/s3elma365/9b7d3d91-f47e-4364-af77-7f0dda906a66?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.png%22%3B%20filename%2A%3DUTF-8%27%27img-3.png%3B&X-Amz-Signature=61bd4cd9b4f5e2bb41b6272479fa8f1c60672b21886e47a39b4562080545d092",
            "https://co.mc-medprof.ru/s3elma365/8545f4d3-5a04-4ddc-9def-b91df5a706fd?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.png%22%3B%20filename%2A%3DUTF-8%27%27img-2%2520%25283%2529.png%3B&X-Amz-Signature=17bf29b5a7c067cb90dc05bfec9ba31795d9bcb1022ef795a6e5e5f234daa1b6",
            "https://co.mc-medprof.ru/s3elma365/57aaf270-9adb-468e-ae79-ea85f70f4218?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27pexels-heyho-6758532.jpg%3B&X-Amz-Signature=62807e00c649193b8cc67dbf672ab666e36176c7669eacefa2a7daad0572c514",
            "https://co.mc-medprof.ru/s3elma365/3154bbfb-dd14-43f6-94d9-1d471d6f4531?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=PZSF73JG72Ksd955JKU1HIA%2F20240603%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Date=20240603T220750Z&X-Amz-Expires=28800&X-Amz-SignedHeaders=host&response-content-disposition=attachment%3B%20filename%3D%22.jpg%22%3B%20filename%2A%3DUTF-8%27%27pexels-olly-760118.jpg%3B&X-Amz-Signature=6d240566ce697ea6a18b8e126dd575aa62e2eb0ebe165a806efce18c5152d9ab"
        ],
        "teg": [
            {
                "code": "prochee",
                "name": "Прочее"
            },
            {
                "code": "proch2e",
                "name": "Проче2е"
            },
            {
                "code": "prochee3",
                "name": "Прочее3"
            },
            {
                "code": "prochee4",
                "name": "Прочее4"
            }
        ]
    }
}