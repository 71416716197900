import React, {useEffect, useState} from 'react';
import {
    CloseButton,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import {Link} from 'react-router-dom';
import classnames from 'classnames';

//SimpleBar
import SimpleBar from "simplebar-react";
import {createSelector} from "reselect";
import {useDispatch, useSelector} from "react-redux";
import {getEventList} from "../../../slices/events/thunk";
import {getUsersList} from "../../../slices/users/thunk";
import moment from "moment";
import {getLessonsList} from "../../../slices/lessons/thunk";
import {getPollsList} from "../../../slices/polls/thunk";
import {getFreeNotifications, getNotifications, markAsRead} from "../../../api/notifications";
import {getFullProfileById} from "../../../api/myAPI";
import {toast} from "react-toastify";

const NotificationDropdown = () => {
    //Dropdown Toggle
    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const currentUser = JSON.parse(sessionStorage.getItem("authUser"));

    const [isLoading, setIsLoading] = useState();
    const dispatch = useDispatch();
    const [eventsData, setEventsData] = useState([]);
    const [allLessons, setAllLessons] = useState([]);
    const [allPolls, setAllPolls] = useState([]);
    const [freeNotifications, setFreeNotifications] = useState([]);
    const [fullFreeNotifications, setFullFreeNotifications] = useState([]);
    const [allNotifications, setAllNotifications] = useState([]);
    const [buttonDisabled, setButtonDisabled] = useState(false);


    async function getMyProfile() {
        const profile = await getFullProfileById(currentUser.__id);
        const myNotifications = [...profile["novye_meropriyatiya_opovesheniya"], ...profile["novye_obucheniya_opovesheniya"], ...profile["novye_oprosy_opovesheniya"], ...profile["svobodnye_uvedomleniya"]];
        setFreeNotifications(profile["svobodnye_uvedomleniya"]);
        setAllNotifications(myNotifications);
    }

    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };

    //Tab 
    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    //Events
    const selectDashboardData = createSelector(
        (state) => state.Events.eventList,
        (eventList) => eventList
    );

    //Lessons
    const selectLessonsData = createSelector(
        (state) => state.Lessons.lessonsList,
        (lessonsList) => lessonsList
    );

    //Polls
    const selectPollsData = createSelector(
        (state) => state.Polls.pollsList,
        (pollsList) => pollsList
    )

    const eventList = useSelector(selectDashboardData);
    const lessonsList = useSelector(selectLessonsData);
    const pollsList = useSelector(selectPollsData);

    useEffect(() => {
        dispatch(getEventList());
        dispatch(getLessonsList());
        dispatch(getPollsList());

        // LOOP
        const intervalId = setInterval(() => {
            dispatch(getEventList());
            dispatch(getLessonsList());
            dispatch(getPollsList());
            getMyProfile();
        }, 5000);

        // Clean up the interval on component unmount
        return () => clearInterval(intervalId);

    }, [dispatch]);


    useEffect(() => {

        if (eventList.length > 0) {
            const newEvents = eventList.filter(ev => moment(ev.__startAt).isAfter(moment()))
                .filter(e =>
                    e.__participants?.includes(currentUser.__id)
                    && !e.kto_ne_pridet_otmechayutsya_sami?.includes(currentUser.__id)
                    && !e.kto_pridet?.includes(currentUser.__id)
                );
            const newNotifications = newEvents.filter(x => !allNotifications.includes(x.__id));
            setEventsData(newNotifications);
        }
        if (lessonsList.length > 0) {
            const newLessons = lessonsList.filter(lesson => !lesson.pending && !lesson.completed);
            const newNotifications = newLessons.filter(x => allNotifications.includes(x.__id));
            setAllLessons(newNotifications);
        }

        if (pollsList.length > 0) {
            const newPolls = pollsList.filter(poll => !poll.expired && !poll.completed);
            const newNotifications = newPolls.filter(x => allNotifications.includes(x.__id));
            setAllPolls(newNotifications);
        }


    }, [eventList, lessonsList, pollsList]);

    useEffect(() => {
        getFreeNotifications().then(r => setFullFreeNotifications(r.filter(x => freeNotifications.includes(x.__id))));
    }, [freeNotifications]);

    async function clearNotifications() {
        setButtonDisabled(true);

        await markAsRead(currentUser.profile_id, [], [], [], [] ).then(
            r => {
                if (r.status === 200) {
                    getMyProfile();
                    setAllNotifications([]);
                    setFullFreeNotifications([]);
                    setEventsData([]);
                    setAllLessons([]);
                    setAllPolls([]);
                    toast.success("Уведомления очищены");
                }
            }
        ).finally(() => setButtonDisabled(false));
    }


    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown}
                      className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button"
                                className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    {
                        (eventsData.length > 0 || allLessons.length > 0 || allPolls.length > 0 || freeNotifications.length > 0) && <span
                            className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">
                            {allPolls.length + eventsData.length + allLessons.length + freeNotifications.length}
                        </span>
                    }
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Уведомления </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span
                                        className="badge bg-light-subtle fs-13 text-primary"> Новые: {eventsData.length + allLessons.length + allPolls.length + freeNotifications.length}</span>
                                </div>
                            </Row>
                        </div>

                        <div className="px-2 py-0">
                            <Nav className="nav-tabs dropdown-tabs nav-tabs-custom  d-flex justify-content-between">
                                <NavItem>
                                    <NavLink
                                        href="#"
                                        className={classnames({active: activeTab === '1'}, 'customNoborder')}
                                        onClick={() => {
                                            toggleTab('1');
                                        }}
                                    >
                                        Все события
                                    </NavLink>
                                </NavItem>
                                <NavItem>

                                <button className="btn btn-sm btn-danger" disabled={buttonDisabled} onClick={async ()=>{await clearNotifications() }}>Очистить</button>
                                </NavItem>
                            </Nav>

                        </div>

                    </div>

                    <TabContent activeTab={activeTab}>
                        <TabPane tabId="1" className="py-2 ps-2">
                            <SimpleBar style={{maxHeight: "300px"}} className="pe-2">
                                {
                                    eventsData?.length > 0 && (
                                        eventsData.map((item, key) => (
                                            <div
                                                className="text-reset notification-item d-block dropdown-item position-relative"
                                                key={key}>
                                                <Link to={`/events?event_id=${item.__id}`} target={"_top"}
                                                      className="stretched-link">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs me-3">
                                                            <div
                                                                className="avatar-title bg-info-subtle text-info rounded-circle fs-18 p-2">
                                                                <i className="bx bx-calendar-event"/></div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mt-0 mb-2 lh-base">Новое мероприятие: <span
                                                                className="fw-semibold">{item.__name}</span></h6>
                                                            <p className="mb-0 fs-12 fw-medium text-uppercase text-muted">
                                                                <span><i
                                                                    className="mdi mdi-clock-outline"/>{moment(item?.__startAt).format("DD.MM.YYYY")}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    )
                                }
                                {
                                    allPolls?.length > 0 && (
                                        allPolls.map((item, key) => (
                                            <div
                                                className="text-reset notification-item d-block dropdown-item position-relative"
                                                key={key}>
                                                <Link to={`/polls?poll_id=${item.__id}`} target={"_top"}
                                                      className="stretched-link">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs me-3">
                                                            <div
                                                                className="avatar-title bg-info-subtle text-info rounded-circle fs-18 p-2">
                                                                <i className="las la-question-circle"/></div>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mt-0 mb-0 lh-base">Новый опрос: </h6>
                                                            <span className="fw-semibold">{item.__name}</span>
                                                            <p className="mb-0 fs-12 fw-medium text-uppercase text-muted">
                                                                <span><i
                                                                    className="mdi mdi-clock-outline me-1"/>{moment(item?.__startAt).format("DD.MM.YYYY")}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    )
                                }
                                {
                                    allLessons?.length > 0 && (
                                        allLessons.filter(x => !x.rejected).map((item, key) => (
                                            <div
                                                className="text-reset notification-item d-block dropdown-item position-relative"
                                                key={key}>
                                                <Link to={`/education?lesson_id=${item.__id}`} target={"_top"}
                                                      className="stretched-link">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs me-3">
                                            <span
                                                className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                <i className="la la-user-graduate"></i>
                                            </span>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mt-0 mb-0 lh-base">Новый урок:</h6>
                                                            <span className="fw-semibold">{item.__name}</span>
                                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                            <span><i
                                                                className="mdi mdi-clock-outline me-1"></i>{moment(item.__updatedAt).format("DD.MM.YYYY")}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    )
                                }
                                {
                                    allLessons?.length > 0 && (
                                        allLessons.filter(x => x.rejected).map((item, key) => (
                                            <div
                                                className="text-reset notification-item d-block dropdown-item position-relative"
                                                key={key}>

                                                <div className="d-flex">
                                                    <Link to={`/education?lesson_id=${item.__id}`} target={"_top"}
                                                          className="stretched-link">
                                                        <div className="avatar-xs me-3">
                                                            <span
                                                                className="avatar-title bg-danger-subtle text-danger rounded-circle fs-16"><i
                                                                className="la la-user-graduate"></i></span>
                                                        </div>
                                                    </Link>

                                                    <div className="flex-grow-1">
                                                        <div className="d-flex justify-content-between">
                                                            <h6 className="mt-0 mb-0 lh-base">Задание отклонено:</h6>
                                                            <div className="flex-shrink-0"><CloseButton
                                                                onClick={() => console.log("test")}/></div>
                                                        </div>
                                                        <Link to={`/education?lesson_id=${item.__id}`} target={"_top"}
                                                              className="stretched-link">
                                                            <span className="fw-semibold">{item.__name}</span>
                                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                                <span><i
                                                                    className="mdi mdi-clock-outline me-1"></i>{moment(item.__updatedAt).format("DD.MM.YYYY")}</span>
                                                            </p>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )
                                }
                                {
                                    fullFreeNotifications?.length > 0 && (
                                        fullFreeNotifications.map((item, key) => (
                                            <div
                                                className="text-reset notification-item d-block dropdown-item position-relative"
                                                key={key}>
                                                <Link to={item?.ssylka} target={"_top"}
                                                      className="stretched-link">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs me-3">
                                                            <span
                                                                className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                                <i className="la la-user-graduate"/>
                                                            </span>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h6 className="mt-0 mb-0 lh-base">{item.tekst_uvedomleniya}</h6>
                                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                            <span><i
                                                                className="mdi mdi-clock-outline me-1"></i>{moment(item.__updatedAt).format("DD.MM.YYYY")}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        ))
                                    )
                                }
                                {
                                    !allLessons?.length > 0 && !eventsData?.length > 0 && !allPolls?.length > 0 && !freeNotifications?.length > 0 && (
                                        <div>
                                            <div className="py-4 mt-1 text-center">
                                                <h5 className="mt-4">Уведомлений нет!</h5>
                                            </div>
                                        </div>
                                    )
                                }
                            </SimpleBar>
                        </TabPane>
                    </TabContent>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default NotificationDropdown;