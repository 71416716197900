import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    CloseButton,
    Col,
    Container,
    Modal,
    ModalBody,
    Nav,
    NavItem,
    NavLink,
    Progress,
    Row,
    TabContent,
    Table,
    TabPane
} from 'reactstrap';
import classnames from 'classnames';
import SwiperCore, {Autoplay} from "swiper";
import mockOSdefault from "../../../Company/mock_os.json";
//Images
import profileBg from '../../../../assets/images/profile-bg.jpg';
import Section from "../../../Projects/ProjectOverview/Section";
import {getAchievements, getFullProfileById, getKpis, getOrgStruct, getProjects} from "../../../../api/myAPI";
import moment from "moment";
import Loader from "../../../../Components/Common/Loader";
import EventsPage from "../../../Events";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getUsersList} from "../../../../slices/users/thunk";
import KpisList from "./KpisList";
import DolzhnostTab from "./DolzhnostTab";
import VacationsTab from "./VacationsTab";


const SimplePage = () => {
    document.title = `Профиль`;

    SwiperCore.use([Autoplay]);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
    const userId = searchParams.get('user_id') ?? currentUser.__id;
    const [activeTab, setActiveTab] = useState('1');
    const [activityTab, setActivityTab] = useState('1');
    const [activeKPITab, setActiveKPITab] = useState('1');
    const [currentPage, setCurrentPage] = useState(1);
    const [modal_achievement, setmodal_achievement] = useState(false);
    const [modal_project, setmodal_project] = useState(false);
    const [loading, setLoading] = useState(true);
    const [myProfile, setMyProfile] = useState({});
    const [allAchivki, setAllAchivki] = useState([]);
    const [curAchievement, setCurAchievement] = useState();
    const [currentProject, setCurrentProject] = useState();

    const [mockOS, setMockOS] = useState(mockOSdefault);

    const [allKpis, setAllKpis] = useState([]);

    const [userList, setUserList] = useState([]);
    const [profilePercent, setProfilePercent] = useState(0);
    const dispatch = useDispatch();

    const selectedUsersData = createSelector(
        (state) => state.Users.usersList,
        (usersList) => usersList
    );

    async function getOrgStructure() {
        await getOrgStruct().then(r => setMockOS(r)).catch(() => setMockOS(mockOSdefault));
    }

    const devModeState = useSelector((state) => state.DevMode.devMode);

    // Inside your component
    const usersList = useSelector(selectedUsersData);

    useEffect(() => {
        dispatch(getUsersList());
    }, [dispatch]);

    useEffect(() => {
        setUserList(usersList);
        getOrgStructure().then(() => console.log("Получили оргструктуру")).catch(() => console.log("Не получили оргструктуру"));
    }, [usersList]);


    const [projectsData, setProjectsData] = useState([]);

    function tog_achievement() {
        setmodal_achievement(!modal_achievement);
    }

    function tog_project() {
        setmodal_project(!modal_project);
    }


    useEffect(() => {
        setLoading(true);
        const myProfile = async () => {
            const profile = await getFullProfileById(userId);
            const achivki = await getAchievements();
            const projects = await getProjects();
            const myProjects = projects.filter(item => item._participants?.includes(userId));
            const KPIS = await getKpis();
            let osIds = [];
            if (profile.osIds && profile.osIds.length > 0) {
                osIds = profile.osIds;
            }
            const groups = [...profile.groupIds, ...osIds];
            const kpiis = KPIS.filter(item => item?.dolzhnost?.some(item2 => groups.includes(item2.code) || item2.code === profile.__id));
            setAllKpis(kpiis);
            setProjectsData(myProjects);
            setAllAchivki(achivki);
            setMyProfile(profile);
        };
        myProfile().then(() => {
            setLoading(false);
        });
    }, [userId]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const toggleKpiTab = (tab) => {
        if (activeKPITab !== tab) {
            setActiveKPITab(tab);
        }
    };

    const toggleActivityTab = (tab) => {
        if (activityTab !== tab) {
            setActivityTab(tab);
        }
    };

    const propertiesToConsider = {
        'dolzhnost': 'должность',
        'interesy_i_uvlecheniya': 'интересы и увлечения',
        'o_sebe': 'о себе',
        'birthDate': 'дата рождения',
        'mobilePhone': 'мобильный телефон',
        'gorod': 'город',
        'timezone': 'часовой пояс',
        'accounts': 'аккаунты'
    };

    function calculateProfileProgress() {

        let existingProperties = 0;
        let existingPropertiesList = [];
        const totalProperties = Object.keys(propertiesToConsider).length;

        Object.keys(propertiesToConsider).forEach((property) => {
            if (myProfile.hasOwnProperty(property) || myProfile[property]) {
                if (myProfile[property] !== "") {
                    existingPropertiesList.push(property);
                    existingProperties++;
                }
            }
        });
        console.log("existingProperties", existingPropertiesList, propertiesToConsider);
        setProfilePercent(Math.ceil((existingProperties / totalProperties) * 100));
    }

    useEffect(() => {
        calculateProfileProgress();
    }, [myProfile]);

    return (
        <React.Fragment>
            <div className="page-content">
                {loading ? <Loader/> : (<Container fluid>
                    <div className="profile-foreground position-relative mx-n4 mt-n4">
                        <div className="profile-wid-bg">
                            <img src={profileBg} alt="" className="profile-wid-img"/>
                        </div>
                    </div>
                    <div className="pt-2 mb-lg-3 pb-lg-4 mb-4">
                        <Row className="g-4">
                            <div className="col-auto">
                                <div className="avatar-lg">
                                    <img src={myProfile.avatar} alt="user-img"
                                         className="img-thumbnail rounded-circle"/>
                                </div>
                            </div>
                            <Col>
                                <div className="p-2">
                                    <h3 className="text-white mb-1">{myProfile['__name'] ?? ''}</h3>
                                    {
                                        !myProfile?.est_na_portale &&
                                        <div className="badge bg-danger fs-12">Нет профиля на портале</div>
                                    }
                                    <>
                                        <p className="text-white text-opacity-75">{myProfile?.displayedPosition ?? ''}</p>
                                        <div className="d-flex flex-wrap text-white-50 gap-2">
                                            <div className="me-2">
                                                <i className="ri-map-pin-user-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{myProfile?.gorod}
                                            </div>
                                            <div>
                                                <i className="ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>{myProfile?.komanda}
                                            </div>
                                            <div><i
                                                className="ri-calendar-event-line me-1 text-white text-opacity-75 fs-16 align-middle"></i>
                                                {moment(myProfile?.birthDate).format('DD.MM.YYYY') !== 'Invalid date' ? moment(myProfile?.birthDate).format('DD.MM.YYYY') : 'Не указано'}
                                            </div>
                                        </div>
                                    </>
                                </div>
                            </Col>
                            {
                                (myProfile?.est_na_portale && myProfile.__id !== currentUser.__id && myProfile['dostizheniya'].length > 0) &&
                                <div className="avatar-lg flex-column text-center cursor-pointer" onClick={() => {
                                    setCurAchievement(myProfile['dostizheniya'][0]);
                                    tog_achievement();
                                }}>
                                    <img src={myProfile['dostizheniya'][0]?.achivka} alt="user-img"
                                         className="img-thumbnail rounded-circle"/>
                                    <div
                                        className="fs-12 text-white text-opacity-75">{myProfile['dostizheniya'][0]['__name']}</div>
                                </div>
                            }
                            {
                                (myProfile?.est_na_portale && myProfile.__id === currentUser.__id && myProfile['dostizheniya'].length > 0) &&
                                <Link to="#trophies" className="avatar-lg flex-column text-center cursor-pointer"
                                      onClick={() => {
                                          setActiveTab('5');
                                      }}>
                                    <img src={myProfile['dostizheniya'][0]?.achivka} alt="user-img"
                                         className="img-thumbnail rounded-circle"/>
                                    <div
                                        className="fs-12 text-white text-opacity-75">{myProfile['dostizheniya'][0]['__name']}</div>
                                </Link>
                            }
                        </Row>
                    </div>
                    {
                        myProfile?.est_na_portale &&
                        <Row>
                            <Col lg={12}>
                                <div>

                                    <div className="d-flex flex-wrap">
                                        <Nav pills className="animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1"
                                             role="tablist">
                                            <NavItem className="fs-14">
                                                <NavLink
                                                    href="#overview-tab"
                                                    className={classnames({active: activeTab === '1'})}
                                                    onClick={() => {
                                                        toggleTab('1');
                                                    }}

                                                >
                                                    <i className="ri-user-2-line d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">Общее</span>
                                                </NavLink>
                                            </NavItem>
                                            {userId === currentUser.__id && <NavItem className="fs-14">
                                                <NavLink
                                                    href="#activities"
                                                    className={classnames({active: activeTab === '2'})}
                                                    onClick={() => {
                                                        toggleTab('2');
                                                    }}

                                                >
                                                    <i className="ri-calendar-2-line d-inline-block d-md-none"></i>
                                                    <span
                                                        className="d-none d-md-inline-block">Активность</span>
                                                </NavLink>
                                            </NavItem>}
                                            {/*<NavItem className="fs-14">*/}
                                            {/*    <NavLink*/}
                                            {/*        href="#projects"*/}
                                            {/*        className={classnames({active: activeTab === '3'})}*/}
                                            {/*        onClick={() => {*/}
                                            {/*            toggleTab('3');*/}
                                            {/*        }}*/}
                                            {/*    >*/}
                                            {/*        <i className="ri-folder-2-line d-inline-block d-md-none"></i>*/}
                                            {/*        <span className="d-none d-md-inline-block">Проекты</span>*/}
                                            {/*    </NavLink>*/}
                                            {/*</NavItem>*/}
                                            <NavItem className="fs-14">
                                                <NavLink
                                                    href="#documents"
                                                    className={classnames({active: activeTab === '4'})}
                                                    onClick={() => {
                                                        toggleTab('4');
                                                    }}

                                                >
                                                    <i className="ri-briefcase-line d-inline-block d-md-none"></i> <span
                                                    className="d-none d-md-inline-block">О должности</span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem className="fs-14">
                                                <NavLink
                                                    href="#trophies_kpi"
                                                    className={classnames({active: activeTab === '5'})}
                                                    onClick={() => {
                                                        toggleTab('5');
                                                    }}
                                                >
                                                    <i className="ri-trophy-line d-inline-block d-md-none"></i>
                                                    <span className="d-none d-md-inline-block">Достижения и KPI</span>
                                                </NavLink>
                                            </NavItem>
                                            {(userId === currentUser.__id && devModeState) &&
                                                <NavItem className="fs-14">
                                                    <NavLink
                                                        href="#vacations"
                                                        className={classnames({active: activeTab === '6'})}
                                                        onClick={() => {
                                                            toggleTab('6');
                                                        }}
                                                    >
                                                        <i className="ri-trophy-line d-inline-block d-md-none"></i>
                                                        <span className="d-none d-md-inline-block">Мой отпуск</span>
                                                    </NavLink>
                                                </NavItem>}
                                        </Nav>
                                        {
                                            userId === currentUser.__id &&
                                            <div className="fs-14">
                                                <Link
                                                    to="/profile/edit"
                                                    className={"btn btn-md bg-success text-white"}
                                                >
                                                    <i className="ri-edit-box-line align-middle me-1"></i>
                                                    <span
                                                        className="d-none d-md-inline-block">Редактировать профиль</span>
                                                </Link>
                                            </div>
                                        }
                                    </div>
                                    <TabContent activeTab={activeTab} className="pt-4">
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col xxl={4}>
                                                    <Card>
                                                        <CardBody>
                                                            <h5 className="card-title mb-3">Заполненность профиля</h5>
                                                            {(profilePercent < 100 && currentUser.__id === userId) &&
                                                                <p className={"text-muted "}>Не заполнено:
                                                                    <br/>
                                                                    {
                                                                        Object.keys(propertiesToConsider)
                                                                            .filter(x => !myProfile[x])
                                                                            .map(x => propertiesToConsider[x])
                                                                            .join(", ")
                                                                    }
                                                                </p>
                                                            }
                                                            <Progress value={profilePercent} color="danger"
                                                                      className="animated-progess custom-progress progress-label">
                                                                <div className="label">{profilePercent}%</div>
                                                            </Progress>
                                                        </CardBody>
                                                    </Card>

                                                    <Card>
                                                        <CardBody>
                                                            <h5 className="card-title mb-2">Контакты</h5>
                                                            <div className="table-responsive overflow-x-hidden">
                                                                <Table className="table-borderless mb-0 align-middle">
                                                                    <tbody>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">ФИО:</th>
                                                                        <td className="text-muted">{myProfile['__name'] ?? ''}</td>
                                                                    </tr>
                                                                    {myProfile['mobilePhone']?.length > 0 && <tr>
                                                                        <th className="ps-0" scope="row">Телефон:</th>
                                                                        <td>
                                                                            <Link
                                                                                to={'tel:' + myProfile['mobilePhone'][0]?.tel}>
                                                                                <span className="link-info">
                                                                                    <i className="ri-phone-line me-1"/>
                                                                                    {myProfile['mobilePhone'][0]?.tel}
                                                                                </span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>}
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Telegram:</th>
                                                                        <td>
                                                                            <Link
                                                                                to={'https://t.me/' + myProfile?.accounts?.find(x => x.type === 'telegram')?.login}>
                                                                                <span className="link-info">
                                                                                    <i className="ri-telegram-line me-1"/>
                                                                                    {myProfile?.accounts?.find(x => x.type === 'telegram')?.login}
                                                                                </span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">E-mail:</th>
                                                                        <td>
                                                                            <Link
                                                                                to={'mailto:' + myProfile['email']}>
                                                                                <span className="link-info">
                                                                                    <i className="ri-mail-line me-1"/>
                                                                                    {myProfile['email']}
                                                                                </span>
                                                                            </Link>
                                                                        </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Город:</th>
                                                                        <td className="text-muted">{myProfile['gorod'] + ` (${myProfile['timezone']})`}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th className="ps-0" scope="row">Дата
                                                                            рождения:
                                                                        </th>
                                                                        <td className="text-muted">{moment(myProfile['birthDate']).format('DD.MM.YYYY')}</td>
                                                                    </tr>
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col xxl={8}>
                                                    <Card>
                                                        <CardBody>
                                                            <h5 className="card-title mb-3">О себе</h5>
                                                            <div>
                                                                {myProfile['o_sebe']}
                                                            </div>
                                                        </CardBody>
                                                    </Card>

                                                    <Row>
                                                        <Col lg={12}>
                                                            <Card>
                                                                <CardBody>
                                                                    <h5 className="card-title mb-4">Интересы \ Увлечения
                                                                        \
                                                                        Хобби</h5>
                                                                    <div className="d-flex flex-wrap gap-2 fs-15">
                                                                        {
                                                                            (myProfile['interesy_i_uvlecheniya'] || []).map((item, key) => (
                                                                                <span
                                                                                    key={item['__id']}
                                                                                    className="badge bg-primary-subtle text-primary">{item['__name'] ?? ''}</span>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </Card>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            {userId === currentUser.__id &&
                                                <EventsPage isWrapped={true} userId={userId}/>
                                            }
                                        </TabPane>
                                        <TabPane tabId="3">
                                            <Card>
                                                <CardHeader>
                                                    <h5 className="card-title mb-0">Участник проекта</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        {(projectsData || []).filter((x) => x.__status.status === 2 || x.__status.status === 6).map((item, key) => (
                                                            <Col xxl={4} sm={6} key={key}>
                                                                <Card
                                                                    className={`profile-project-card shadow-none`}>
                                                                    <CardBody className="p-4">
                                                                        <div className="d-flex">
                                                                            <div
                                                                                className="flex-grow-1 text-muted overflow-hidden">
                                                                                <h5 className="fs-14 text-truncate">
                                                                                    <p className="text-body">{item.__name}</p>
                                                                                </h5>
                                                                                <p className="text-muted text-truncate mb-0">Начало: <span
                                                                                    className="fw-semibold text-body">{moment(item._start_date).format('DD.MM.YYYY')}</span>
                                                                                </p>
                                                                                <p className="text-muted text-truncate mb-0">Окончание: <span
                                                                                    className="fw-semibold text-body">{moment(item._end_date).format('DD.MM.YYYY')}</span>
                                                                                </p>
                                                                            </div>
                                                                            <div className="flex-shrink-0 ms-2">
                                                                                <div
                                                                                    className={`badge ${item.__status.status === 2 ? 'bg-success-subtle' : 'bg-primary-subtle'}
                                                                                     ${item.__status.status === 2 ? 'text-success' : 'text-primary'} fs-10`}>
                                                                                    {item.__status.status === 2 ? 'Активный' : 'Стратегия'}</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            <div className="flex-grow-1">
                                                                                <div
                                                                                    className="align-items-center gap-2">
                                                                                    <div>
                                                                                        <h5 className="fs-12 text-muted mb-1">
                                                                                            Руководитель: </h5>
                                                                                    </div>
                                                                                    <Link
                                                                                        to={`/profile?user_id=${item._project_manager?.[0]}`}>
                                                                                        <div
                                                                                            className={"d-flex align-items-center gap-2"}>
                                                                                            <div className="avatar-xs">
                                                                                                <img
                                                                                                    src={userList.find(x => x.__id === item._project_manager?.[0])?.avatar}
                                                                                                    alt=""
                                                                                                    className="rounded-circle img-fluid"/>
                                                                                            </div>
                                                                                            {userList.find(x => x.__id === item._project_manager?.[0])?.__name}
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-4 hstack gap-2">
                                                                            <button
                                                                                onClick={() => {
                                                                                    tog_project();
                                                                                    setCurrentProject(item);
                                                                                }}
                                                                                className="btn btn-soft-primary w-1/2"
                                                                            >
                                                                                Подробнее
                                                                            </button>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                            <Card>
                                                <CardHeader>
                                                    <h5 className="card-title mb-0">Завершенные</h5>
                                                </CardHeader>
                                                <CardBody>
                                                    <Row>
                                                        {(projectsData || []).filter((x) => x.__status.status !== 2 && x.__status.status !== 6 && x?._participants?.includes(userId)).map((item, key) => (
                                                            <Col xxl={4} sm={6} key={key}>
                                                                <Card
                                                                    className={`profile-project-card shadow-none`}>
                                                                    <CardBody className="p-4">
                                                                        <div className="d-flex">
                                                                            <div
                                                                                className="flex-grow-1 text-muted overflow-hidden">
                                                                                <h5 className="fs-14 text-truncate">
                                                                                    <span
                                                                                        className="text-body">{item.__name}</span>
                                                                                </h5>
                                                                                <p className="text-muted text-truncate mb-0">Начало: <span
                                                                                    className="fw-semibold text-body">{moment(item._start_date).format('DD.MM.YYYY')}</span>
                                                                                </p>
                                                                                <p className="text-muted text-truncate mb-0">Окончание: <span
                                                                                    className="fw-semibold text-body">{moment(item._end_date).format('DD.MM.YYYY')}</span>
                                                                                </p>
                                                                            </div>
                                                                            {/*<div className="flex-shrink-0 ms-2">*/}
                                                                            {/*    <div*/}
                                                                            {/*        className={`badge bg-${item.badgeClass}-subtle text-${item.badgeClass} fs-10`}>*/}
                                                                            {/*        {item.badgeText}</div>*/}
                                                                            {/*</div>*/}
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            {item.__name}
                                                                        </div>
                                                                        <div className="d-flex mt-2">
                                                                            <div className="flex-grow-1">
                                                                                <div
                                                                                    className="align-items-center gap-2">
                                                                                    <div>
                                                                                        <h5 className="fs-12 text-muted mb-1">
                                                                                            Руководитель: </h5>
                                                                                    </div>
                                                                                    <Link
                                                                                        to={`/profile?user_id=${item._project_manager?.[0]}`}>
                                                                                        <div
                                                                                            className={"d-flex align-items-center gap-2"}>
                                                                                            <div className="avatar-xs">
                                                                                                <img
                                                                                                    src={userList.find(x => x.__id === item._project_manager?.[0])?.avatar}
                                                                                                    alt=""
                                                                                                    className="rounded-circle img-fluid"/>
                                                                                            </div>
                                                                                            {userList.find(x => x.__id === item._project_manager?.[0])?.__name}
                                                                                        </div>
                                                                                    </Link>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="mt-4 hstack gap-2">
                                                                            <button
                                                                                onClick={tog_project}
                                                                                className="btn btn-soft-primary w-1/2">
                                                                                Подробнее
                                                                            </button>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        </TabPane>
                                        <TabPane tabId="4">
                                            {myProfile['dolzhnost'] &&
                                                <DolzhnostTab myProfile={myProfile} currentUser={currentUser}
                                                              userId={userId} mockOS={mockOS}/>}
                                        </TabPane>
                                        <TabPane tabId="5">
                                            <div className="d-flex p-1 bg-dark bg-opacity-75 rounded-2">
                                                <Nav pills
                                                     className="animation-nav align-middle profile-nav gap-2 gap-lg-3 flex-grow-1"
                                                     role="tablist">
                                                    <NavItem className="fs-14">
                                                        <NavLink
                                                            href="#trophies"
                                                            className={classnames({active: activeKPITab === '1'})}
                                                            onClick={() => {
                                                                toggleKpiTab('1');
                                                            }}

                                                        >
                                                            <i className="ri-trophy-fill d-inline-block d-md-none"></i>
                                                            <span className="d-none d-md-inline-block">Достижения</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    {userId === currentUser.__id && <NavItem className="fs-14">
                                                        <NavLink
                                                            href="#KPI"
                                                            className={classnames({active: activeKPITab === '2'})}
                                                            onClick={() => {
                                                                toggleKpiTab('2');
                                                            }}

                                                        >
                                                            <i className="ri-bar-chart-grouped-line d-inline-block d-md-none"></i>
                                                            <span
                                                                className="d-none d-md-inline-block">KPI</span>
                                                        </NavLink>
                                                    </NavItem>}
                                                </Nav>
                                            </div>
                                            <TabContent activeTab={activeKPITab} className="pt-4">
                                                <TabPane tabId="1">
                                                    <Card>
                                                        <CardBody>
                                                            <div className="d-flex align-items-center mb-4">
                                                                {
                                                                    currentUser.__id === userId ?
                                                                        <h5 className="card-title flex-grow-1 mb-0">Мои
                                                                            достижения</h5> :
                                                                        <h5 className="card-title flex-grow-1 mb-0">Достижения
                                                                            пользователя</h5>
                                                                }
                                                            </div>
                                                            <Row>
                                                                {
                                                                    (myProfile?.dostizheniya || []).map((item, key) => (
                                                                        <Col lg={4} md={6} key={key}>
                                                                            <Card className="cursor-pointer"
                                                                                  onClick={() => {
                                                                                      setCurAchievement(item);
                                                                                      tog_achievement();
                                                                                  }}>
                                                                                <CardBody className={"text-center p-0"}>
                                                                                    <img src={item?.achivka}
                                                                                         alt="user-img"
                                                                                         className="img-thumbnail avatar-lg rounded-circle"/>
                                                                                    <p className={"fs-14"}>{item['__name']}</p>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                    {currentUser.__id === userId && <Card>
                                                        <CardBody>
                                                            <div className="d-flex align-items-center mb-4">
                                                                <h5 className="card-title flex-grow-1 mb-0">Доступные
                                                                    достижения</h5>
                                                            </div>
                                                            <Row>
                                                                {
                                                                    allAchivki?.filter(x => myProfile?.dostizheniya?.find(y => y.__id === x.__id) === undefined).map((item, key) => (
                                                                        <Col lg={4} md={6} key={key}>
                                                                            <Card className={"cursor-pointer"}
                                                                                  onClick={() => {
                                                                                      setCurAchievement(item);
                                                                                      tog_achievement();
                                                                                  }}>
                                                                                <CardBody className={"text-center"}>
                                                                                    <img src={item?.achivka}
                                                                                         alt="user-img"
                                                                                         className="img-thumbnail avatar-lg rounded-circle"/>
                                                                                    <div>
                                                                                        <h5>{item['__name']}</h5>
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Row>
                                                        </CardBody>
                                                    </Card>}
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <KpisList allKpis={allKpis}/>
                                                </TabPane>
                                            </TabContent>
                                        </TabPane>
                                        <TabPane tabId="6">
                                            {(currentUser.__id === userId && devModeState) &&
                                                <VacationsTab/>
                                            }
                                        </TabPane>
                                    </TabContent>
                                </div>
                            </Col>
                        </Row>

                    }
                    {currentProject &&
                        <Modal
                            size={'xl'}
                            isOpen={modal_project}
                            toggle={() => {
                                tog_project();
                            }}
                            centered
                        >
                            <ModalBody>
                                <Section toggle={tog_project} project={currentProject} allUsers={userList}/>
                            </ModalBody>
                        </Modal>
                    }


                    {curAchievement &&
                        <Modal
                            size={'md'}
                            isOpen={modal_achievement}
                            toggle={() => {
                                tog_achievement();
                            }}
                            centered
                        >
                            <ModalBody>
                                <div className="d-flex justify-content-end"><CloseButton onClick={tog_achievement}/>
                                </div>
                                <div className={"center text-center"}>
                                    <h4>{curAchievement?.__name}</h4>
                                    <img src={curAchievement?.achivka}
                                         alt="user-img"
                                         className="img-thumbnail avatar-lg rounded-circle"/>
                                </div>
                                <div className={"p-2 mt-2"}>
                                    <div className={"mt-2 fw-bold"}>Описание:</div>
                                    <div>{curAchievement['opisanie']}</div>

                                    <div className={"mt-2 fw-bold"}>Условия выполнения:</div>
                                    <ol>
                                        {curAchievement.kriterii?.rows?.map((item, key) => (
                                            <li key={key}>{item?.trebovaniya_dlya_vypolneniya}</li>
                                        ))}
                                    </ol>
                                </div>
                            </ModalBody>
                        </Modal>}
                </Container>)}
            </div>
        </React.Fragment>
    );
};

export default SimplePage;