import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import { useNavigate } from 'react-router-dom';
import Masonry from "react-masonry-component";
import Loader from "../../../Components/Common/Loader";
import galleryMock from "../galleryMock.json";
import { getGallery, getGalleryAlbums } from "../../../api/gallery";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

import Download from "yet-another-react-lightbox/plugins/download";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";

import { LazyLoadImage } from 'react-lazy-load-image-component';

const GalleryAlbum = () => {
    document.title = "Галерея";
    const searchParams = useMemo(() => new URLSearchParams(window.location.search), []);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const currentCategory = useMemo(() => searchParams.get('album') || "Все", [searchParams]);
    const [albums, setAlbums] = useState(galleryMock);
    const [photos, setPhotos] = useState([]);
    const [currentPhotos, setCurrentPhotos] = useState([]);
    const [pages, setPages] = useState(1);
    const [page, setPage] = useState(1);
    const photosPerPage = 8;

    const [open, setOpen] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);

    useEffect(() => {
        if (!searchParams.get('album')) {
            navigate('/gallery');
        }
    }, [searchParams, navigate]);

    useEffect(() => {
        const totalPages = Math.ceil(photos.length / photosPerPage);
        setPages(totalPages);
    }, [photos]);

    useEffect(() => {
        const startIndex = (page - 1) * photosPerPage;
        const endIndex = startIndex + photosPerPage;
        setCurrentPhotos(photos.slice(startIndex, endIndex));
    }, [page, photos]);

    const handleChangePage = useCallback((pageNumber) => {
        setPage(pageNumber);
    }, []);

    useEffect(() => {
        const fetchGalleryData = async () => {
            setLoading(true);
            try {
                const albumsData = await getGallery();
                setAlbums(albumsData);
                const albumPhotos = await getGalleryAlbums(currentCategory);
                setPhotos(albumPhotos.soderzhimoe_alboma);
                setCurrentPhotos(albumPhotos.soderzhimoe_alboma.slice(0, photosPerPage));
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchGalleryData();
    }, [currentCategory]);

    useEffect(() => {
        const newPage = Math.ceil((slideIndex + 1) / photosPerPage);
        if (newPage !== page) {
            handleChangePage(newPage);
        }
    }, [slideIndex]);

    const paginationItems = useMemo(() => {
        const startIndex = Math.max(1, page - Math.floor(4 / 2));
        const endIndex = Math.min(startIndex + 5 - 1, pages);
        return Array.from({ length: endIndex - startIndex + 1 }, (_, i) => startIndex + i);
    }, [page, pages]);

    if (loading) return (<div className="page-content"><Loader /></div>);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Галерея" pageTitle="Галерея" link={"/gallery"} back={true} />
                    <Row>
                        <div className="row g-0 text-center text-sm-start align-items-center mb-3">
                            <div className="col-sm-6">
                            </div>
                            <div className="col-sm-6">
                                <ul className="pagination pagination-separated justify-content-center justify-content-sm-end mb-sm-0">
                                    <li className={`cursor-pointer page-item ${page === 1 ? 'disabled' : ''}`}>
                                        <span className="page-link" onClick={() => handleChangePage(page - 1)}><i className="mdi mdi-chevron-left"></i></span>
                                    </li>
                                    {paginationItems.map((pageIndex, index) => (
                                        <li key={index} className={`cursor-pointer page-item ${page === pageIndex ? 'active' : ''}`} onClick={() => handleChangePage(pageIndex)}>
                                            <span className="page-link">{pageIndex}</span>
                                        </li>
                                    ))}
                                    <li className={`cursor-pointer page-item ${page === pages ? 'disabled' : ''}`}>
                                        <span className="page-link" onClick={() => handleChangePage(page + 1)}><i className="mdi mdi-chevron-right"></i></span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <Col lg={12}>
                            <CardBody>
                                <Row>
                                    <h2 className="col-12">{albums?.find(x => x.__id === currentCategory)?.__name} ({photos.length} фото)</h2>
                                    <Col lg={12}>
                                        {currentPhotos.length > 0 &&
                                            <Masonry className="row gallery-wrapper">
                                                {currentPhotos.map((item, key) => (
                                                    <Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={key}>
                                                        <Card className="gallery-box">
                                                            <div className="gallery-container">
                                                                <span className="image-popup cursor-pointer" onClick={() => { setSlideIndex(key + (page - 1) * photosPerPage); setOpen(true); }} title="">
                                                                    <img
                                                                        className="gallery-img cursor-pointer img-cover mx-auto"
                                                                        alt=""
                                                                        height={500}
                                                                        src={item}
                                                                        width={500}
                                                                    />
                                                                </span>
                                                            </div>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Masonry>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Col>
                    </Row>
                </Container>
                <Lightbox
                    open={open}
                    index={slideIndex}
                    on={{ view: ({ index: currentIndex }) => setSlideIndex(currentIndex) }}
                    close={() => setOpen(false)}
                    plugins={[Download, Thumbnails]}
                    slides={photos.map(x => ({ src: x }))}
                />
            </div>
        </React.Fragment>
    );
};

export default GalleryAlbum;
