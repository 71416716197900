import React, {useEffect, useState} from 'react';
import {Alert, Button, Card, CardBody, CloseButton, Col, Container, Modal, ModalBody, Row} from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import {Link, useNavigate} from "react-router-dom";

import withRouter from "../../Components/Common/withRouter";
import logoLight from "../../assets/images/medprof/m_prof_logo_new_white.png";
import {ToastContainer} from "react-toastify";
import {getMyElmaUser} from "../../api/myAPI";
import {useDispatch} from "react-redux";
import {loginUser} from "../../slices/auth/login/thunk";

const Login = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [error, setError] = useState();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [cantLogin, setCantLogin] = useState(false);

    async function getMyProfile() {
        await getMyElmaUser().then(
            (response) => {
                dispatch(loginUser(history));
                setProfile(response);
                if (response.email !== null) {
                    history("/news");
                }
            }
        ).catch(
            (error) => {
                setError(error);
            }
        ).finally(
            () => setLoading(false)
        )
    }

    useEffect(() => {
        if (!profile) {
            getMyProfile();
        }
    }, [])


    document.title = "Вход в систему | М.Проф";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <div className="auth-page-content mt-lg-5">
                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="text-center mt-sm-5 mb-4 text-white-50">
                                    <div>
                                        <Link to="/" className="d-inline-block logo-sm">
                                            <img src={logoLight} alt="" height="64"/>
                                        </Link>
                                    </div>
                                    <p className="mt-3 fs-15 fw-medium">Корпоративный портал - только для своих!</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <h5 className="text-primary">Добро пожаловать!</h5>
                                            <p className="text-muted">Войдите, чтобы продолжить.</p>
                                            {
                                                loading ? <div className="spinner-border text-primary" role="status"/> :

                                                    <Link to="https://hr.mc-medprof.ru/sso-login"
                                                          className="btn btn-success"
                                                          style={{width: '100%'}}>
                                                        Войти c МедПроф ID
                                                    </Link>
                                            }
                                        </div>
                                        {(error && error.response.status !== 401) && (<div className="mt-4 text-center">
                                            <Alert color="danger"> {error.message} </Alert>
                                        </div>)}
                                    </CardBody>
                                </Card>
                                <div className="mt-4 text-center">
                                    <p className="mb-0">
                                        <Link to="#" className="fw-semibold text-primary text-decoration-underline" onClick={() => setCantLogin(true)}> Не могу войти </Link>
                                    </p>
                                </div>
                                <ToastContainer autoClose={2000} limit={1}/>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <CantLoginModal cantLogin={cantLogin} setCantLogin={setCantLogin}/>
            </ParticlesAuth>
        </React.Fragment>
    );
};

function CantLoginModal({cantLogin, setCantLogin}) {
        return (
            <Modal
                size="xl"
                isOpen={cantLogin}
                toggle={() => {
                    setCantLogin();
                }}
                centered
            >
                <ModalBody>
                    <div className="d-flex justify-content-between">
                        <h4 className="mb-3">Не могу войти!</h4>
                        <CloseButton onClick={() => setCantLogin()}/>
                    </div>
                    <div className={"p-3 fs-15"}>
                        <p>Для того, чтобы попасть на портал вам необходимо:</p>
                        <ol>
                            <li className="mb-1">Только один раз авторизоваться на сервере Цифрового офиса МедПроф, пройдя по ссылке
                                <a href="https://co.mc-medprof.ru" target="_blank"> https://co.mc-medprof.ru</a>.
                            </li>

                            <li className="mb-1">Нажать на "Другой способ входа" -> МедПроф ID</li>

                            <li className="mb-1">Ввести логин и пароль от вашего МедПроф ID, чтобы система создала вашу
                                учетную запись на портале. (если будет ошибка про недостаток лицензий, игнорируем ее)
                            </li>
                            <li>Пользоваться порталом по ссылке
                                <a href="https://hr.mc-medprof.ru" target="_blank"> https://hr.mc-medprof.ru </a>
                                все последующие разы. (кнопка с
                                порталом так же размещена в нашей МИС)
                            </li>
                        </ol>
                        <p>
                            Если все сделано, но все равно не получается войти, напишите о проблеме администратору цифрового офиса Михайленко Виктору на почту
                            <a href={"mailto:vs.mikhaylenko@mc-medprof.ru"}> vs.mikhaylenko@mc-medprof.ru</a>
                        </p>
                    </div>
                    <div className="d-flex justify-content-center align-items-center ">
                        <Button color="success" onClick={() => setCantLogin()}>
                            Понятно
                        </Button>
                    </div>
                </ModalBody>
            </Modal>
        );
}

export default withRouter(Login);