import {ElmaAPI} from "./myAPI";
import mockLessons from "./lessons_mock.json";
import userMock from "./userMock.json";

export const getLessons = async () => {
    const user = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser")) || userMock;
    const groups = [...user.groupIds, ...user.osIds];
    const lessons = await ElmaAPI.get('/lessons').then(r=>r.data).catch(e=>{return mockLessons});
    if(!lessons) return [];
    const lessonsForMe = lessons?.filter(item => item?.dlya_kogo?.some(item2 => groups.includes(item2.code) || item2.code === user.__id));
    return lessonsForMe?.map((item) => {
        item.rejected = item?.zadanie_otkloneno?.includes(user.__id);
        item.pending = item?.ozhidayut_proverki?.includes(user.__id);
        item.completed = item?.kto_proshel?.includes(user.__id);
        return item;
    });
}

export const runLessonBP = async (lessonData) => {
    return await ElmaAPI.post('/lessons_bpm', lessonData).then(r => r.data);
}

export const pendLessonToAPI = async (payload) => {
    const {lessonId, users} = payload;
    return await ElmaAPI.post(`/update_lesson/${lessonId}`, JSON.stringify(users)).then(r => r.data);
}