import {ElmaAPI} from "./myAPI";
import mockVacations from "./vacationsMock.json";
import unpaidMock from "./unpaidVacationsMock.json";
import mockAgreements from "./vacationsAgreements.json";
import mockMovements from "./vacations_movements.json";
import moment from "moment";

export const getVacations = async () => {
    //return mockVacations;
    return await ElmaAPI.get(`/vacations`).then(r => r.data).catch(()=>mockVacations)
}

export const getUnpaidVacations = async () => {
    //return unpaidMock;
    return await ElmaAPI.get(`/vacations_unpaid`).then(r => r.data).catch(()=>unpaidMock)
}

export const getAgreementVacations = async () => {
    //return mockAgreements;
    return await ElmaAPI.get(`/vacations-agreements`).then(r => r.data).catch(()=>mockAgreements);
}

export const getVacationMovements = async () => {
    //return mockAgreements;
    return await ElmaAPI.get(`/vacations_movements`).then(r => r.data).catch(()=>mockMovements);
}

export const cancelVacation = async (id) => {
    return await ElmaAPI.get(`/vacation-cancel-unpaid/${id}`).then(r => r.data);
}

export const moveVacation = async (data) => {
    const new_data = {
        employee_full_name: data.userId,
        days_quantity: moment(data.want_finish).diff(moment(data.want_start), 'days')+1,
        agreement_chief: data?.chief_id || "3109cc73-5bfa-4862-b201-a5f0b9f0c046",
        oldDateStart: moment(data.current_start).add(1,'day').toISOString(),
        oldDateEnd: moment(data.current_finish).add(1,'day').toISOString(),
        newDateStart: moment(data.want_start).add(1,'day').toISOString(),
        newDateEnd: moment(data.want_finish).add(1,'day').toISOString(),
        comment: data.text_text
    }
    console.log(new_data)
    return await ElmaAPI.post(`/vacations-move`, new_data).then(r => r.data);
}

export const agreeVacation = async (data) => {
    const new_data = {
        employee_full_name: data.employee_full_name[0],
        days_quantity: moment(data.start_date).diff(moment(data.end_date), 'days')+1,
        agreement_chief: data.agreement_chief[0],
        oldDateStart: moment(data.start_date).add(1,'day').toISOString(),
        oldDateEnd: moment(data.end_date).add(1,'day').toISOString(),
        newDateStart: moment(data.start_date).add(1,'day').toISOString(),
        newDateEnd: moment(data.end_date).add(1,'day').toISOString()
    }
    return await ElmaAPI.post(`/vacations-agree`, new_data).then(r => r.data);
}