import { createSlice } from "@reduxjs/toolkit";
import {getUsersList} from './thunk';

export const initialState = {
    usersList: [],
    error: {},
};

const UsersSlice = createSlice({
    name: 'Users',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUsersList.fulfilled, (state, action) => {
            state.usersList = action.payload;
        });
        builder.addCase(getUsersList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default UsersSlice.reducer;