import ElmaAPI from "./myAPI";

export const sendFeedback = async (data) => {
    return await ElmaAPI.post('/feedback', data).then(r => r.data);
}

export const getFeedbackTypes = async () => {
    return await ElmaAPI.get('/feedback_types').then(r => r.data);
}

export const getOtdels = async () => {
    return await ElmaAPI.get('/otdely').then(r => r.data);
}