import React from 'react';
import {Link} from 'react-router-dom';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';

//import images

//SimpleBar
import SimpleBar from "simplebar-react";
import moment from "moment";

const OverviewTab = ({project, allUsers}) => {
    return (
        <React.Fragment>
            <Row>
                <Col xl={9} lg={8}>
                    <Card>
                        <CardBody>
                            <div className="text-muted">
                                <h6 className="mb-3 fw-semibold text-uppercase">Описание</h6>
                                <div>
                                    {project?._description}
                                </div>
                                <div className="pt-3 border-top border-top-dashed mt-4">
                                    <Row>
                                        <Col lg={3} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium">Начало: </p>
                                                <h5 className="fs-15 mb-0">{moment(project._start_date).format('DD.MM.YYYY')}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={3} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium">Окончание: </p>
                                                <h5 className="fs-15 mb-0">{moment(project._end_date).format('DD.MM.YYYY')}</h5>
                                            </div>
                                        </Col>
                                        <Col lg={3} sm={6}>
                                            <div>
                                                <p className="mb-2 text-uppercase fw-medium">Статус :</p>
                                                <div className={`badge 
                                                ${project.__status.status === 2 ? 'bg-success-subtle text-success' : 'bg-primary-subtle text-primary'}
                                                 fs-12`}>{project.__status.status === 2 ? 'Активный' : 'Стратегия'}</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Col xl={3} lg={4}>
                    <Card>
                        <CardBody>
                            <h5 className="card-title mb-4">Руководитель</h5>
                            <Link
                                to={`/profile?user_id=${project?._project_manager?.[0]}`}>
                                <div
                                    className={"d-flex align-items-center gap-2"}>
                                    <div className="avatar-xs">
                                        <img
                                            src={allUsers.find(x => x.__id === project?._project_manager?.[0])?.avatar}
                                            alt=""
                                            className="rounded-circle img-fluid"/>
                                    </div>
                                    {allUsers.find(x => x.__id === project?._project_manager?.[0])?.__name}
                                </div>
                            </Link>
                        </CardBody>
                    </Card>

                    {project?._supervisor?.[0] && <Card>
                        <CardBody>
                            <h5 className="card-title mb-4">Куратор</h5>
                            <Link
                                to={`/profile?user_id=${project?._supervisor?.[0]}`}>
                                <div
                                    className={"d-flex align-items-center gap-2"}>
                                    <div className="avatar-xs">
                                        <img
                                            src={allUsers.find(x => x.__id === project?._supervisor?.[0])?.avatar}
                                            alt=""
                                            className="rounded-circle img-fluid"/>
                                    </div>
                                    {allUsers.find(x => x.__id === project?._supervisor?.[0])?.__name}
                                </div>
                            </Link>
                        </CardBody>
                    </Card>}


                    <Card>
                        <CardHeader className="align-items-center d-flex border-bottom-dashed">
                            <h4 className="card-title mb-0 flex-grow-1">Участники</h4>
                        </CardHeader>

                        <CardBody>
                            <SimpleBar data-simplebar style={{height: "235px"}} className="mx-n3 px-3">
                                <div className="vstack gap-3">
                                    {
                                        project?._participants?.map((item, index) => (
                                            allUsers.find(x => x.__id === item) && (
                                                <React.Fragment key={index}>
                                                    <div className="d-flex align-items-center">
                                                        <div className="avatar-xs flex-shrink-0 me-3">
                                                            <img src={allUsers.find(x => x.__id === item).avatar} alt=""
                                                                 className="img-fluid rounded-circle"/>
                                                        </div>
                                                        <div className="flex-grow-1">
                                                            <h5 className="fs-13 mb-0"><Link to={`/profile?user_id=${item}`}
                                                                                             className="text-body d-block">{allUsers.find(x => x.__id === item).__name}</Link></h5>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        ))
                                    }
                                </div>
                            </SimpleBar>

                        </CardBody>

                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
};

export default OverviewTab;