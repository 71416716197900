import React, {useEffect, useState} from 'react';
import {Button, CloseButton, Form, Input, Label, Modal, ModalBody, Spinner} from "reactstrap";
import moment from "moment";
import ElmaAPI from "../../api/myAPI";
import {moveVacation} from "../../api/vacations";
import {toast} from "react-toastify";


function InvoiceModal({modal_center, tog_center, vacation}) {
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));

    const [selectedValues, setSelectedValues] = useState({
        userId: currentUser?.__id,
        vacation_id: vacation.__id,
        current_start: moment(vacation.start_date).format('YYYY-MM-DD'),
        current_finish: moment(vacation.end_date).format('YYYY-MM-DD'),
        current_days: vacation.days_quantity,
        chief_id: (vacation.agreement_chief && vacation.agreement_chief[0]) ? vacation.agreement_chief[0] : "3109cc73-5bfa-4862-b201-a5f0b9f0c046",
        want_start: undefined,
        want_finish: moment(vacation.end_date).format('YYYY-MM-DD'),
        text_text: undefined,
        want_days: undefined,
        otdelApp: undefined,
        tipObrasheniya: undefined
    });

    const [isSending, setIsSending] = useState(false);

    useEffect(() => {
        console.log(vacation);

        setSelectedValues(prevState => ({
            ...prevState,
            current_start: moment(vacation.start_date).format('YYYY-MM-DD'),
            current_finish: moment(vacation.end_date).format('YYYY-MM-DD'),
            chief_id: (vacation.agreement_chief && vacation.agreement_chief[0]) ? vacation.agreement_chief[0] : "3109cc73-5bfa-4862-b201-a5f0b9f0c046",
            current_days: vacation.days_quantity,
            text_text: undefined,
            want_start: undefined,
            want_finish: moment(vacation.end_date).format('YYYY-MM-DD'),
            want_days: vacation.days_quantity,
            otdelApp: undefined,
            tipObrasheniya: undefined
        }));
    }, [vacation]);

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'want_start':
                const newFinishDate = moment(value, "YYYY-MM-DD").add(selectedValues.current_days-1, 'days').format("YYYY-MM-DD");
                setSelectedValues(prevState => ({...prevState, want_start: value, want_finish: newFinishDate }));
                break;
            case 'want_days':
                setSelectedValues(prevState => ({...prevState, want_days: value }));
                break;
            case 'text_text':
                setSelectedValues(prevState => ({...prevState, text_text: value }));
                break;
            default:
                setSelectedValues(prevState => ({...prevState, [name]: value }));
                break;
        }
    };

    const sendInvoice = async (e) => {
        e.preventDefault();
        setIsSending(true);
        try {
            await moveVacation(selectedValues);
            setIsSending(false);
            tog_center();
        } catch (error) {
            console.error(error);
            setIsSending(false);
        }
    };

    return (
        <Modal
            size="lg"
            isOpen={modal_center}
            toggle={() => {
                tog_center();
            }}
            centered
        >
            <ModalBody>
                <div className="d-flex justify-content-between">
                    <h4 className="mb-3">Перенос отпуска</h4>
                    <CloseButton onClick={() => tog_center()}/></div>

                <Form onSubmit={sendInvoice}>
                    <div className="d-flex">
                        <div className="mb-3 flex-grow-0 d-flex flex-column gap-1">
                            <Label for="text_topic" className="form-label mb-0">Текущая дата начала отпуска <span
                                className={"text-danger"}>*</span></Label>
                            <Input
                                id="text_topic"
                                type="date"
                                name={`current_start`}
                                readOnly
                                disabled
                                className={"mb-2"}
                                value={selectedValues['current_start']}
                                onChange={handleChange}
                            />

                            <Label for="text_topic" className="form-label mb-0">Дней: <span
                                className={"text-danger"}>*</span></Label>
                            <Input
                                id="text_topic"
                                type="number"
                                name={`current_days`}
                                readOnly
                                disabled
                                className={"mb-2"}
                                value={selectedValues['current_days']}
                                onChange={handleChange}
                            />

                            <Label for="text_topic" className="form-label mb-0">Текущая дата окончания отпуска <span
                                className={"text-danger"}>*</span></Label>
                            <Input
                                id="text_topic"
                                type="date"
                                name={`current_finish`}
                                readOnly
                                disabled
                                className={"mb-2"}
                                value={selectedValues['current_finish']}
                                onChange={handleChange}
                            />
                        </div>
                        <div className="mb-3 flex-grow-0 mx-5 d-flex flex-column gap-1">
                            <Label for="want_start" className="form-label mb-0">Новая дата начала отпуска <span
                                className={"text-danger"}>*</span></Label>
                            <Input
                                id="want_start"
                                type="date"
                                name={`want_start`}
                                className={"mb-2"}
                                value={selectedValues['want_start']}
                                min={moment().add(14, "day").format("YYYY-MM-DD")}
                                onChange={handleChange}
                            />

                            <Label for="want_days" className="form-label mb-0">Дней: <span
                                className={"text-danger"}>*</span></Label>
                            <Input
                                id="want_days"
                                type="number"
                                name={`want_days`}
                                className={"mb-2"}
                                defaultValue={selectedValues['want_days']}
                                disabled
                            />

                            <Label for="want_finish" className="form-label mb-0">Новая дата окончания отпуска <span
                                className={"text-danger"}>*</span></Label>
                            <Input
                                id="want_finish"
                                type="date"
                                name={`want_finish`}
                                className={"mb-2"}
                                value={selectedValues['want_finish']}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="mb-3">
                        <Label for="text_text" className="form-label mb-0">Комментарий <span
                            className={"text-danger"}>*</span></Label>
                        <Input
                            id="text_text"
                            type="textarea"
                            name={`text_text`}
                            placeholder=''
                            style={{minHeight: '150px'}}
                            required
                            value={selectedValues['text_text'] || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <Button
                        type="submit"
                        color="success"
                        disabled={isSending}
                        className="btn btn-success">
                        {isSending ?
                            <Spinner size="sm"
                                     className='me-2'> Отправка... </Spinner> : null}
                        Отправить
                    </Button>
                </Form>

            </ModalBody>
        </Modal>
    );
}

export default InvoiceModal;