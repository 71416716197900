import React, {useState} from 'react';
import {Card, CloseButton, Container, Modal, ModalBody, Row} from 'reactstrap';
import button from "bootstrap/js/src/button";

// Import Images
import goal1 from "../../assets/images/goals/goal1.jpg"
import goal2 from "../../assets/images/goals/goal2.jpg"
import goal3 from "../../assets/images/goals/goal3.jpg"
import goal4_1 from "../../assets/images/goals/goal4_1.jpg"
import goal4_2 from "../../assets/images/goals/goal4_2.jpg"
import goal5 from "../../assets/images/goals/goal5.jpg"


const Goals = () => {

    document.title = "Цели компании";

    const goals = [
        {
            number: 1,
            description: "Это - образ будущего,  к которому стремится вся наша команда. Какими мы хотим быть для наших клиентов? А для сотрудников? А для конкурентов (да, мы их тоже учитываем!)? Что должны говорить о нас?",
            buttons: [{
                text: "Узнать скорее!",
                imageSrc: goal1
            }]
        },
        {
            number: 2,
            description: "У МедПрофа есть важная миссия, и не одна. Мы - не та компания, которая просто работает ради работы, у нас есть своё видение нашей миссии.",
            buttons: [{
                text: "Хочу узнать миссию МедПроф!",
                imageSrc: goal2
            }]
        },
        {
            number: 3,
            description: "Какими мы будем в 2024 году? Какие проекты появятся уже в этом году? Что мы хотим оцифровать? Какие новые классные истории мы будем организовывать клиентам?",
            buttons: [{
                text: "Летим в 2024!",
                imageSrc: goal3
            }]
        },
        {
            number: 4,
            description: "Какими мы будем в 2025 году?. Каким МедПроф будет через год? Сколько вакансий мы будем закрывать через внешних специалистов, а сколько - через наш внутренний кадровый резерв? Как мы станем создавать новые продукты?",
            buttons: [
                {
                    text: "Летим в 2025! (1)",
                    imageSrc: goal4_1
                },
                {
                    text: "Летим в 2025! (2)",
                    imageSrc: goal4_2
                }
            ]
        },
        {
            number: 5,
            description: "Какими мы будем в 2026 году?. Что нового появится у нас через 2 года? Цифры, проценты, реальные цели.",
            buttons: [{
                text: "Летим в 2026!",
                imageSrc: goal5
            }]
        }
    ];

    const [currentImage, setCurrentImage] = useState();
    const [modal_center, setmodal_center] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className={"justify-content-center align-items-center gap-2"}>
                        <Card className="rounded-0 mx-n4 mt-n4 border-top">
                            <div className="px-4">
                                <div className="py-4">
                                    <h4 className="display-6">Цели компании</h4>
                                    <p className="text-bold fs-15 mt-3">Здравствуй, коллега!
                                        В этом разделе мы предлагаем заглянуть в будущее МедПроф. Не в
                                        абстрактное далёкое будущее, а в конкретные ближайшие годы с конкретными
                                        осязаемыми целями. Эти цели были сформированы командой на стратегической
                                        сессии и стали нашим планом развития.
                                        <br/>
                                        Посмотрим?
                                    </p>
                                </div>
                            </div>
                        </Card>

                        {
                            goals?.map((goal, i) =>
                                <Card key={i} className="rounded-0 bg-success-subtle mx-n4 mt-n4 border-top">
                                    <div className="px-4">
                                        <div className="py-4">
                                            <p className="text-bold fs-15 mt-3">{goal.number}. {goal.description}</p>
                                            <div className="hstack flex-wrap gap-2">
                                                {
                                                    goal.buttons.map((button, i) =>
                                                        <button key={i} type="button"
                                                                className="btn btn-primary btn-label rounded-pill"
                                                                onClick={() => {
                                                                    setCurrentImage(button.imageSrc)
                                                                    tog_center()
                                                                }}
                                                        >
                                                            <i className="ri-image-2-fill label-icon align-middle rounded-pill fs-16 me-2"></i> {button.text}
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </Card>
                            )
                        }

                    </Row>
                </Container>
            </div>


            <Modal size="xl"
                   isOpen={modal_center}
                   toggle={() => {
                       tog_center();
                   }}
                   centered>
                <ModalBody>
                    <div className="d-flex justify-content-end">
                        <CloseButton onClick={() => tog_center()}/>
                    </div>

                    <img width="100%" height="auto" src={currentImage} alt={'goal image'}/>
                </ModalBody>
            </Modal>
        </React.Fragment>
    )
}


export default Goals