import React, {useEffect, useState} from 'react';

//Import Breadcrumb
import Loader from "../../Components/Common/Loader";
import {Card, CardBody, CardImg, Col, Container, Offcanvas, OffcanvasBody, Row} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import {Link} from "react-router-dom";
import Masonry from "react-masonry-component";
import {getKnowledgeApps, getKnowledgeAppsCovers} from "../../api/knowledgeBase";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {getUsersList} from "../../slices/users/thunk";
import profileBg from "../../assets/images/profile-bg.jpg";
import avatar2 from "../../assets/images/users/avatar-2.jpg";

const newPage = () => {
    document.title = "База знаний";   //for meta title
    const [loading, setLoading] = useState(false);
    const [mockData, setMockData] = useState([]);
    const [covers, setCovers] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [sideBar, setSideBar] = useState([]);

    const dispatch = useDispatch();

    const selectedUsersData = createSelector(
        (state) => state.Users.usersList,
        (usersList) => usersList
    );

    const usersList = useSelector(selectedUsersData);
    const [userList, setUserList] = useState([]);

    useEffect(() => {
        dispatch(getUsersList());
    }, [dispatch]);

    useEffect(() => {
        setUserList(usersList);
    }, [usersList]);


    useEffect(() => {
        Promise.all([
            getKnowledgeAppsCovers(),
            getKnowledgeApps()
        ])
            .then(([coversResponse, appsResponse]) => {
                setCovers(coversResponse.map(cover => {
                    if(cover.otvetstvennyi_za_razdel1 && cover.otvetstvennyi_za_razdel1.length > 0 && userList?.length > 0) {
                        cover.responsible_id = cover.otvetstvennyi_za_razdel1[0];
                        cover.responsible_user = userList?.find(user => user.__id === cover.otvetstvennyi_za_razdel1[0]);
                    }
                    return cover
                }));
                setMockData(appsResponse.map(app => {
                    app.image = "https://placehold.co/600x400";
                    return app
                }));
            })
            .catch(error => {
                console.error(error);
                // handle your error here
            })
            .finally(() => {
                setLoading(false);

            });
    }, [userList]);

    function getTelegram(item) {
        let tg = item?.accounts?.find(x => x.type === 'telegram')?.login;
        if (tg) {
            if (tg.includes("@")) {
                return tg.split("@")[1];
            } else if (tg.includes(".me/")) {
                return tg.split(".me/")[1];
            }
            return tg;
        }
        return null
    }

    if (loading) return (<div className="page-content"><Loader/></div>);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="База знаний"
                                pageTitle="База знаний"
                                link="/knowledge"
                    />
                    <Row>
                        <Col xs={12}>
                            <div className="">
                                <CardBody>
                                    <Row>
                                        <Col lg={12}>
                                            <Masonry className="row gallery-wrapper">
                                                {(mockData || []).filter(x => !x.name.includes("служебное")).map((item) => (
                                                    <Col xxl={3} xl={4} sm={6}
                                                         className="element-item project designing development"
                                                         key={item.code}>
                                                        <Card className="border cursor-pointer">
                                                            <Link to={`/knowledge/category?cat=${item.code}`}>
                                                                <CardBody>
                                                                    <div>
                                                                        <h5 className="text-truncate text-truncate-two-lines">
                                                                            {item.name}
                                                                        </h5>
                                                                        <CardImg
                                                                            src={covers.find(x => x.__name === item.name)?.cover[0] || item.image}
                                                                            width={600}
                                                                            height={400}
                                                                            alt={item.name}
                                                                            className="rounded img-fluid"
                                                                        />

                                                                        {
                                                                            covers.find(x => x.__name === item.name)?.responsible_user ? <div>
                                                                                <p className="text-muted mb-0">Ответственный за раздел: </p>
                                                                            <Link to="#" onClick={() => {
                                                                                setIsOpen(!isOpen);
                                                                                setSideBar(covers.find(x => x.__name === item.name)?.responsible_user);
                                                                            }} className="d-flex align-items-center pt-1">
                                                                                <div
                                                                                    className="avatar-xs flex-shrink-0 me-3">
                                                                                    <img
                                                                                        src={covers.find(x => x.__name === item.name)?.responsible_user?.avatar}
                                                                                        alt=""
                                                                                        className="img-fluid rounded-circle"/>
                                                                                </div>
                                                                                <div className="flex-grow-1">
                                                                                    <h5 className="fs-13 mb-0">
                                                                                        <span
                                                                                            className="text-body d-block">{covers.find(x => x.__name === item.name)?.responsible_user?.__name}</span>
                                                                                    </h5>
                                                                                </div>
                                                                            </Link></div> : <div className={"mt-5"}></div>
                                                                        }
                                                                    </div>
                                                                </CardBody>
                                                            </Link>
                                                        </Card>
                                                    </Col>
                                                ))}
                                            </Masonry>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Offcanvas
                isOpen={isOpen}
                direction="end"
                toggle={() => setIsOpen(!isOpen)}
                className="offcanvas-end border-0"
                tabIndex="-1"
                id="member-overview"
            >
                <OffcanvasBody className="profile-offcanvas p-0">
                    <div className="team-cover">
                        <img src={sideBar.backgroundImg || profileBg} alt="" className="img-fluid"/>
                    </div>
                    <div className="p-3">
                        <div className="team-settings">
                            <Row>
                                <Col>

                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="p-3 text-center">
                        <img src={sideBar?.avatar || avatar2} alt=""
                             className="avatar-lg img-thumbnail rounded-circle mx-auto"/>
                        <div className="mt-3">
                            <h5 className="fs-15 profile-name"><Link to="#"
                                                                     className="link-primary">{sideBar.__name || "Nancy Martino"}</Link>
                            </h5>
                            <p className="text-muted profile-designation">{sideBar.displayedPosition}</p>
                        </div>
                        {getTelegram(sideBar) && <div className="hstack gap-2 justify-content-center mt-4">
                            <div className="avatar-xs">
                                <Link to={`https://t.me/${getTelegram(sideBar)}`}
                                      className="avatar-title bg-secondary-subtle text-secondary rounded fs-16">
                                    <i className="ri-telegram-fill"></i>
                                </Link>
                            </div>
                        </div>}
                    </div>
                    <div className="p-3">
                        {sideBar?.osNames?.length > 1 && <><h5 className="fs-15 mb-3">Должность: </h5>
                            <p className="text-muted profile-designation">{sideBar.osNames?.map((item, key) => <span
                                key={key}>{item}<br/>
                                    </span>
                            )}</p></>}

                        <h5 className="fs-15 mb-3">Личные данные</h5>
                        <div className="mb-3">
                            <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Телефон</p>
                            <a href={`tel:${sideBar.mobilePhone && sideBar.mobilePhone.length > 0 ? sideBar.mobilePhone[0].tel : sideBar.workPhone && sideBar.workPhone.length > 0 ? sideBar.workPhone[0].tel : null}`}>{
                                (sideBar.mobilePhone && sideBar.mobilePhone.length > 0) ? sideBar.mobilePhone[0].tel :
                                    (sideBar.workPhone && sideBar.workPhone.length > 0) ? sideBar.workPhone[0].tel : null
                            }</a>
                        </div>
                        <div className="mb-3">
                            <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Email</p>
                            <a href={`mailto:${sideBar.email}`}>{sideBar.email}</a>
                        </div>
                        <div>
                            <p className="text-muted text-uppercase fw-semibold fs-12 mb-2">Временная
                                зона:</p>
                            <h6 className="mb-0">{sideBar?.timezone}</h6>
                        </div>
                    </div>
                </OffcanvasBody>
                <div
                    className="offcanvas-foorter border p-3 hstack gap-3 text-center position-relative">
                    <button onClick={() => setIsOpen(!isOpen)} className="btn btn-light w-100"><i
                        className="ri-arrow-go-back-fill align-bottom ms-1"></i> Назад
                    </button>
                    <Link to={`/profile?user_id=${sideBar.__id}`} className="btn btn-primary w-100"><i
                        className="ri-user-3-fill align-bottom ms-1"></i> Профиль</Link>
                </div>
            </Offcanvas>
        </React.Fragment>
    );
}

export default newPage;