import React, {useState} from 'react';
import {Button, CloseButton, Form, Input, Label, Modal, ModalBody, Spinner} from "reactstrap";
import {toast} from "react-toastify";
import {getFeedbackTypes, getOtdels, sendFeedback} from "../../api/feedback";
import mockTypes from "../../pages/Invoices/mockTypes.json";
import mockOtdels from "../../pages/Invoices/mockOtdels.json";


function InvoiceModal({modal_center, tog_center}) {

    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
    const [selectedValues, setSelectedValues] = useState({tipObrasheniya: undefined});
    const [isSending, setIsSending] = useState(false);
    const [feedbackTypes, setFeedbackTypes] = useState([]);
    const [otdels, setOtdels] = useState([]);


    React.useEffect(async () => {

        let otdels = [];
        let types = [];

        await getFeedbackTypes().then(r => {
            types = r;
            setFeedbackTypes(r)
        }).catch(() => {
            setFeedbackTypes(mockTypes);
            types = mockTypes;
        });

        await getOtdels().then(r => {
            otdels = r;
            setOtdels(r)
        }).catch(() => {
            otdels = mockOtdels;
            setOtdels(mockOtdels)
        });

        setSelectedValues({
            userId: currentUser?.__id || 'cd6fe717-f605-493c-86e3-b6685cac0e03',
            otdelApp: otdels[0].__id,
            tipObrasheniya: undefined
        });

    }, []);


    const handleChange = (e) => {
        setSelectedValues({
            ...selectedValues,
            [e.target.name]: e.target.value
        });
    };

    const sendInvoice = (e) => {
        e.preventDefault();
        setIsSending(true);
        if(!selectedValues.tipObrasheniya) {
            setIsSending(false);
            toast.error("Не выбран тип обращения");
            return;
        }
        sendFeedback(selectedValues).then(r => {
            setIsSending(false);
            tog_center();
            setSelectedValues({
                userId: currentUser?.__id || 'cd6fe717-f605-493c-86e3-b6685cac0e03',
                otdelApp: otdels[0].__id,
                tipObrasheniya: undefined
            });
            toast.success("Обращение успешно отправлено");
        }).catch(e => {
            setIsSending(false);

            toast.error("Не удалось отправить обращение");
        }).finally(() => {
            setIsSending(false);
            tog_center();
        });
    }

    return (
        <Modal
            size="xl"
            isOpen={modal_center}
            toggle={() => {
                tog_center();
            }}
            centered
        >
            <ModalBody>
                <div className="d-flex justify-content-between">
                    <h4 className="mb-3">Отправить обращение</h4>
                    <CloseButton onClick={() => tog_center()}/></div>

                <Form onSubmit={sendInvoice}>
                    <div className="mb-3">
                        <Label for="ForminputState" className="form-label" >Отдел <span className={"text-danger"}>*</span></Label>

                        <select id="ForminputState"
                                className="form-select"
                                value={selectedValues['otdelApp'] || ''}
                                name="otdelApp"
                                onChange={handleChange}
                                required>

                            {otdels.map((item) => (
                                <option key={item.__id} value={item.__id}>{item.__name}</option>
                            ))}
                        </select>

                    </div>

                    <div className="mb-3">
                        <Label for="tipObrasheniyaState" className="form-label">Тип обращения <span
                            className={"text-danger"}>*</span> </Label>

                        <select id="tipObrasheniyaState"
                                className="form-select"
                                defaultValue={undefined}
                                value={selectedValues['tipObrasheniya'] || undefined}
                                name="tipObrasheniya"
                                onChange={handleChange}
                                required>

                            <option value={undefined} disabled selected>Выберите тип обращения</option>
                            {feedbackTypes.map((item) => (
                                <option key={item.__id} value={item.__id}>{item.__name}</option>
                            ))}
                        </select>

                    </div>

                    <div className="mb-3">
                        <Label for="text_topic" className="form-label">Тема обращения <span
                            className={"text-danger"}>*</span></Label>
                        <Input
                            id="text_topic"
                            type="text"
                            name={`topic`}
                            required
                            placeholder={'Например: Заказать справку 2НДФЛ'}
                            value={selectedValues['topic'] || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="mb-3">
                        <Label for="text_text" className="form-label">Текст обращения <span
                            className={"text-danger"}>*</span></Label>
                        <Input
                            id="text_text"
                            type="textarea"
                            name={`text`}
                            placeholder='Например: Мне необходимо заказать справку 2НДФЛ, или Я хочу перенести свой отпуск на другие даты и т.д.
Чем подробнее вы опишите свой вопрос, тем быстрее кадровая служба поймет, что вам нужно и тем быстрее вы получите результат.'
                            style={{minHeight: '150px'}}
                            required
                            value={selectedValues['text'] || ''}
                            onChange={handleChange}
                        />
                    </div>

                    <Button
                        type="submit"
                        color="success"
                        disabled={isSending}
                        className="btn btn-success">
                        {isSending ?
                            <Spinner size="sm"
                                     className='me-2'> Отправка... </Spinner> : null}
                        Отправить
                    </Button>
                </Form>

            </ModalBody>
        </Modal>
    );
}

export default InvoiceModal;