import React, {useState} from 'react';
import {Card, CardBody, CloseButton, Col, Modal, ModalBody, Row} from "reactstrap";
import moment from "moment/moment";

function KpisList({allKpis}) {
    const [open, setOpen] = useState(false);
    const [curKpi, setCurKpi] = useState(null);

    function tog_modal() {
        setOpen(!open);
    }

    return (
        <>
            <Card>
                <CardBody>
                    <div className="d-flex align-items-center mb-4">
                        <h5 className="card-title flex-grow-1 mb-0">KPI</h5>
                    </div>
                    <Row>
                        {
                            allKpis?.map((item, key) => (
                                <Col lg={3} md={4} sm={4} key={key}>
                                    <Card className="cursor-pointer" onClick={() => {
                                        setCurKpi(item);
                                        tog_modal();
                                    }}>
                                        <CardBody className={"text-center"}>
                                            <p>
                                                <h5>{item['__name']}</h5>
                                                <p className={"text-muted"}>{item['primechanie']}</p>
                                            </p>
                                            <div className={"d-flex justify-content-center gap-2"}>
                                                <span>{moment(item['kpi_otslezhivaetsya_s']).format('DD.MM.YYYY')}</span>
                                                <span>{moment(item['kpi_otslezhivaetsya_po']).format('DD.MM.YYYY')}</span>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))
                        }
                    </Row>
                </CardBody>
            </Card>
            {curKpi &&
                <Modal
                    size={'md'}
                    isOpen={open}
                    toggle={() => {
                        tog_modal();
                    }}
                    centered
                >
                    <ModalBody>
                        <div className="d-flex justify-content-end"><CloseButton onClick={tog_modal}/>
                        </div>
                        <div className={"center text-center"}>
                            <h4>{curKpi?.__name}</h4>
                            <span>{moment(curKpi['kpi_otslezhivaetsya_s']).format('DD.MM.YYYY')} - {moment(curKpi['kpi_otslezhivaetsya_po']).format('DD.MM.YYYY')}</span>
                        </div>
                        <div className={"p-2 mt-2"}>
                            <div className={"mt-2 fw-bold"}>Описание:</div>
                            <div>{curKpi['primechanie']}</div>

                            <div className={"mt-2 fw-bold"}>Параметры выполнения:</div>
                            <div>{
                                curKpi['parametry_vypolneniya_kpi'] && curKpi['parametry_vypolneniya_kpi'].rows.length > 0 &&
                                curKpi['parametry_vypolneniya_kpi'].rows.map((item, key) => (
                                    <div key={key}>
                                        {item.nazvanie_parametra}: {item['znachenie_parametra_ot']} - {item['znachenie_parametra_do']}
                                    </div>
                                ))
                            }</div>
                        </div>
                    </ModalBody>
                </Modal>}
        </>
    );
}

export default KpisList;