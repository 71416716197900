import {ElmaAPI} from "./myAPI";
import moment from "moment";


export const getKnowledgeAppsCovers = async () => {
    return await ElmaAPI.get('/knowledge/covers').then(r=>r.data).catch(e=>{return e});
}

export const getKnowledgeApps = async () => {
    return await ElmaAPI.get('/knowledge/apps').then(r=>r.data).catch(e=>{return e});
}

export const getKnowledgeItems = async (app) => {
    return await ElmaAPI.get(`/knowledge/items/${app}`).then(r=>r.data).catch(e=>{return e});
}