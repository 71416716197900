import { createSlice } from "@reduxjs/toolkit";
import {getEventList} from './thunk';

export const initialState = {
    eventList: [],
    pending: true,
    error: {},
};

const EventsSlice = createSlice({
    name: 'Events',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEventList.pending, (state, action) => {
            state.pending = true;
        })
        builder.addCase(getEventList.fulfilled, (state, action) => {
            state.eventList = action.payload;
            state.pending = false;
        });
        builder.addCase(getEventList.rejected, (state, action) => {
            state.error = action.payload.error || null;
            state.pending = false;
        });
    }
});

export default EventsSlice.reducer;