import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink, Progress,
    Row,
    TabContent,
    TabPane
} from 'reactstrap';
import classnames from "classnames";
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getFullProfileById, getHobbies, updateMyProfile, updateTelegramSettings} from "../../../../api/myAPI";
import Loader from "../../../../Components/Common/Loader";
import Select from "react-select";
import {useDispatch} from "react-redux";
import profileBg from '../../../../assets/images/profile-bg.jpg';
import TelegramLoginButton from "telegram-login-button";


const Settings = () => {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("1");
    const [myProfile, setMyProfile] = useState({});
    const [loading, setLoading] = useState(true);
    const [sending, setSending] = useState(false);
    const [selectedMulti, setselectedMulti] = useState(null);
    const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));

    const [oSebe, setOsebe] = useState('');
    const [gorod, setGorod] = useState('');
    const [allHobbies, setAllHobbies] = useState([]);
    const [profilePercent, setProfilePercent] = useState(0);

    const [tgEvents, setTgEvents] = useState(true);
    const [tgPolls, setTgPolls] = useState(true);
    const [tgEducation, setTgEducation] = useState(true);
    const [tgConnected, setTgConnected] = useState(false);
    const [tgId, setTgId] = useState(currentUser.telegram_chat_id?.id );
    const [tgAccount, setTgAccount] = useState(currentUser.tg_account || JSON.parse(localStorage.getItem("tgAccount")));

    function handleMulti(selectedMulti) {
        setselectedMulti(selectedMulti);
    }

    const sendData = () => {
        setSending(true);

        if(oSebe && oSebe.trim().length<50) {
            toast.error('Поле "О себе" должно быть не короче 50 символов');
            setSending(false);
            return;
        }
        updateMyProfile(currentUser.profile_id, {
            OSebe: oSebe.trim(),
            Gorod: gorod.trim(),
            Hobbies: selectedMulti.map(item => item.value),
            TelegramID: tgId || 0,
            tgPolls: tgPolls,
            tgEvents: tgEvents,
            tgEducation: tgEducation
        }).then(r => {
            toast.success("Данные успешно обновлены", { autoClose: 3000 });
            setTimeout(() => {
                toast.clearWaitingQueue();
            }, 3000);
        }).finally(() => setSending(false));
    }

    useEffect(() => {
        const myProfile = async () => {
            const profile = await getFullProfileById(currentUser.__id);
            setMyProfile(profile);
            setOsebe(profile['o_sebe'] || '');
            setGorod(profile['gorod'] || '');
            setselectedMulti(profile['interesy_i_uvlecheniya'].map(item => ({
                value: item['__id'],
                label: item['__name']
            })) || []);
            setTgPolls(profile['tg_polls']);
            setTgEvents(profile['tg_events']);
            setTgEducation(profile['tg_education']);
            const hobbies = await getHobbies().then(r => r);
            setAllHobbies(hobbies.map(h => ({value: h['__id'], label: h['__name']})) || []);
        }
        myProfile().then(() => setLoading(false));
        setTgConnected(!!currentUser.telegram_chat_id);
    }, [sending]);

    const propertiesToConsider = {
        'dolzhnost': 'должность',
        'interesy_i_uvlecheniya': 'интересы и увлечения',
        'o_sebe': 'о себе',
        'birthDate': 'дата рождения',
        'mobilePhone': 'мобильный телефон',
        'gorod': 'город',
        'timezone': 'часовой пояс',
        'accounts': 'аккаунты'
    };

    function calculateProfileProgress() {

        let existingProperties = 0;
        let existingPropertiesList = [];
        const totalProperties = Object.keys(propertiesToConsider).length;

        Object.keys(propertiesToConsider).forEach((property) => {
            if (myProfile.hasOwnProperty(property) || myProfile[property]) {
                if(myProfile[property] !== "") {
                    existingPropertiesList.push(property);
                    existingProperties++;
                }
            }
        });
        console.log("existingProperties", existingPropertiesList, propertiesToConsider);
        setProfilePercent(Math.ceil((existingProperties / totalProperties) * 100));
    }

    useEffect(() => {
        calculateProfileProgress();
    }, [myProfile]);

    async function setTelegramProfile(telegramId) {
        const currentUser = JSON.parse(sessionStorage.getItem("authUser")) || JSON.parse(localStorage.getItem("authUser"));
        currentUser.telegram_chat_id = telegramId.id;
        currentUser.tg_account = telegramId;
        sessionStorage.setItem("authUser", JSON.stringify(currentUser));
        localStorage.setItem("authUser", JSON.stringify(currentUser));
        setTgId(telegramId.id);
        sendData();
    }


    const tabChange = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const customStyles = {
        multiValue: (styles, {data}) => {
            return {
                ...styles,
                color: "white !important",
                backgroundColor: "#3762ea",
            };
        },
        multiValueLabel: (styles, {data}) => ({
            ...styles,
            backgroundColor: "#6c6d79",
            color: "white !important",
        }),
        multiValueRemove: (styles, {data}) => ({
            ...styles,
            color: "white !important",
            backgroundColor: "#405189",
            ':hover': {
                backgroundColor: "#405189",
                color: 'white !important',
            },
        }),
    }


    document.title = "Редактировать профиль";

    return (
        <React.Fragment>
            <div className="page-content">
                {
                    loading ? <Loader/> :
                        <Container fluid>
                            <div className="position-relative mx-n4 mt-n4">
                                <div className="profile-wid-bg profile-setting-img">
                                    <img src={profileBg} className="profile-wid-img" alt=""/>
                                    <div className="overlay-content">
                                        <div className="text-end p-3">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Row>
                                <Col xxl={3}>
                                    <Card className="mt-n5">
                                        <CardBody className="p-4">
                                            <div className="text-center">
                                                <div
                                                    className="profile-user position-relative d-inline-block mx-auto  mb-4">
                                                    <img src={myProfile.avatar}
                                                         className="rounded-circle avatar-xl img-thumbnail user-profile-image"
                                                         alt="user-profile"/>
                                                </div>
                                                <h5 className="fs-16 mb-1">{myProfile['__name'] ?? ''}</h5>
                                                <p className="text-muted mb-0">{myProfile['dolzhnost']['__name'] ?? ''}</p>
                                            </div>
                                        </CardBody>
                                    </Card>

                                    <Card>
                                        <CardBody>
                                            <h5 className="card-title mb-3">Заполненность профиля</h5>
                                            {(profilePercent < 100 ) &&
                                                <p className={"text-muted "}>Не заполнено:
                                                    <br/>
                                                    {
                                                        Object.keys(propertiesToConsider)
                                                            .filter(x => !myProfile[x])
                                                            .map(x => propertiesToConsider[x])
                                                            .join(", ")
                                                    }
                                                </p>
                                            }
                                            <Progress value={profilePercent} color="danger"
                                                      className="animated-progess custom-progress progress-label">
                                                <div className="label">{profilePercent}%</div>
                                            </Progress>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col xxl={9}>
                                    <Card className="mt-xxl-n5">
                                        <CardHeader>
                                            <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0"
                                                 role="tablist">
                                                <NavItem>
                                                    <NavLink
                                                        href={"#profile"}
                                                        className={classnames({active: activeTab === "1"})}
                                                        onClick={() => {
                                                            tabChange("1");
                                                        }}>
                                                        <i className="fas fa-home"></i>
                                                        Основное
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href={"#telegram"}
                                                        className={classnames({active: activeTab === "2"})}
                                                        onClick={() => {
                                                            tabChange("2");
                                                        }}>
                                                        <i className="fas fa-home"></i>
                                                        Telegram
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        href={"#security"}
                                                        className={classnames({active: activeTab === "3"})}
                                                        onClick={() => {
                                                            tabChange("3");
                                                        }}>
                                                        <i className="fas fa-home"></i>
                                                        Безопасность
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                        </CardHeader>
                                        <CardBody className="p-4">
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1">
                                                    <Form>
                                                        <Row>
                                                            <Col lg={6} md={5}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="choices-multiple-default"
                                                                           className="form-label text-muted">Выберите
                                                                        хобби и увлечения</Label>
                                                                    <Select
                                                                        value={selectedMulti}
                                                                        isMulti
                                                                        onChange={(newValue) => {
                                                                            handleMulti(newValue);
                                                                        }}
                                                                        options={allHobbies}
                                                                        placeholder={'Выберите хобби'}
                                                                        styles={customStyles}
                                                                    />
                                                                </div>
                                                            </Col>
                                                            <Col lg={4}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="cityInput"
                                                                           className="form-label">Город</Label>
                                                                    <Input type="text" className="form-control"
                                                                           id="cityInput"
                                                                           placeholder="Город"
                                                                           value={gorod}
                                                                           onChange={(e) => setGorod(e.target.value)}/>
                                                                </div>
                                                            </Col>
                                                            <Col lg={12}>
                                                                <div className="mb-3 pb-2">
                                                                    <Label htmlFor="exampleFormControlTextarea"
                                                                           className="form-label">О себе</Label>
                                                                    <textarea className="form-control"
                                                                              id="exampleFormControlTextarea"
                                                                              rows="3"
                                                                              value={oSebe}
                                                                              minLength={50}
                                                                              onChange={(e) => setOsebe(e.target.value)}></textarea>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button onClick={sendData} type="button"
                                                                    className={`btn btn-primary ${sending ? 'disabled' : ''}`}>{
                                                                sending ? 'Сохранение...' : 'Сохранить'
                                                            }</button>
                                                            <Link to='/profile'
                                                                  className="btn btn-soft-success">Отмена</Link>
                                                        </div>
                                                    </Form>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Form>
                                                        <Row>
                                                            <Col lg={6} md={5}>
                                                                <div className="mb-3">
                                                                    <div>Подключить бота:</div>
                                                                    <TelegramLoginButton
                                                                        botName="medprof_portal_test_bot"
                                                                        dataOnauth={ async (user) => {
                                                                            localStorage.setItem("tgAccount", JSON.stringify(user));
                                                                            await setTelegramProfile(user);
                                                                        }}
                                                                    />
                                                                    {
                                                                        tgConnected &&
                                                                        <div ><i className={"ri-check-fill text-success me-2 align-middle"}/> Бот подключен к аккаунту @{tgAccount?.username}</div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <p className="text-muted fw-medium">Уведомления: </p>

                                                                    <div className="form-check form-switch">
                                                                        <Input className="form-check-input"
                                                                               disabled={!tgConnected} type="checkbox"
                                                                               checked={tgPolls}
                                                                               onChange={(e) => {
                                                                                   setTgPolls(e.target.checked);
                                                                               }}
                                                                               role="switch"
                                                                               id="checkPolls"/>
                                                                        <Label className="form-check-label"
                                                                               for="checkPolls">Новые опросы</Label>
                                                                    </div>
                                                                    <div className="form-check form-switch">
                                                                        <Input className="form-check-input"
                                                                               disabled={!tgConnected}
                                                                               checked={tgEvents}
                                                                               onChange={(e) => {
                                                                                   setTgEvents(e.target.checked);
                                                                               }}
                                                                               type="checkbox"
                                                                               role="switch"
                                                                               id="checkEvents"
                                                                               />
                                                                        <Label className="form-check-label"
                                                                               for="checkEvents">Новые
                                                                            мероприятия</Label>
                                                                    </div>

                                                                    <div className="form-check form-switch">
                                                                        <Input className="form-check-input"
                                                                               disabled={!tgConnected}
                                                                               checked={tgEducation}
                                                                               onChange={(e) => {
                                                                                   setTgEducation(e.target.checked);
                                                                               }}
                                                                               type="checkbox"
                                                                               role="switch"
                                                                               id="checkLessons"/>
                                                                        <Label className="form-check-label"
                                                                               for="checkLessons">Новые и просроченные обучения</Label>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button onClick={sendData} type="button"
                                                                        className={`btn btn-primary ${sending ? 'disabled' : ''}`}>{
                                                                    sending ? 'Сохранение...' : 'Сохранить'
                                                                }</button>
                                                                <Link to='/profile'
                                                                      className="btn btn-soft-success">Отмена</Link>
                                                            </div>
                                                        </Row>
                                                    </Form>
                                                </TabPane>
                                            </TabContent>

                                        </CardBody>
                                    </Card>
                                </Col>
                                <ToastContainer closeButton={false} limit={1}/>
                            </Row>
                        </Container>
                }
            </div>
        </React.Fragment>
    );
};

export default Settings;