import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    CloseButton,
    Col,
    Container,
    Modal,
    ModalBody,
    Row,
    Spinner
} from "reactstrap";

//Import Breadcrumb
import BreadCrumbs from "../../Components/Common/BreadCrumb";
import moment from "moment";
import Loader from "../../Components/Common/Loader";
import {useDispatch, useSelector} from "react-redux";
import {createSelector} from "reselect";
import {ToastContainer} from "react-toastify";
import {getVacanciesList} from "../../slices/vacancies/thunk";

const VacanciesPage = () => {
    
    const [modal_center, setmodal_center] = useState(false);
    const [currentVacancies, setCurrentVacancies] = useState(null);

    const dispatch = useDispatch();
    
    const selectDashboardData = createSelector(
        (state) => state.Vacancies.vacanciesList,
        (vacanciesList) => vacanciesList
    );

    const vacanciesList = useSelector(selectDashboardData);
    const [allVacancies, setAllVacancies] = useState([]);

    const [isSending, setIsSending] = useState(false);
    const [loading, setLoading] = useState(false);

    function tog_center() {
        setmodal_center(!modal_center);
    }

    useEffect(() => {
    }, [currentVacancies]);

    useEffect(() => {
        setLoading(true);
        dispatch(getVacanciesList());
    }, [dispatch]);

    useEffect(() => {
        setAllVacancies(vacanciesList);
        setLoading(false);
    }, [vacanciesList]);

    document.title = "Вакансии";   //for meta title
    return (
        <>
            <div className="page-content">
                <Container fluid={true}>
                    <BreadCrumbs title="Вакансии" breadcrumbItem="Вакансии"/>
                    <Card>
                        <CardBody className={"card-title"}>
                            В данном разделе размещены активные вакансии нашей компании.
                            Если вы знаете идеально подходящего человека для описанных ниже вакансий - подайте обращение в кадровую службу.
                        </CardBody>
                    </Card>
                    {loading ? <Loader/> :
                        <Row>
                            {(allVacancies?.filter(x=>x?.translirovat_na_portal).slice(0, 300) || []).map((item, key) => (
                                <Col lg={6} key={key}>
                                    <Card className="border cursor-pointer" onClick={() => {
                                        setCurrentVacancies(item);
                                        tog_center()
                                    }}>
                                        <CardBody>
                                            <div className="d-sm-flex">
                                                <div className="flex-grow-1 ms-sm-4 mt-1 mt-sm-0">
                                                    <h5 className="text-truncate text-truncate-two-lines">
                                                        <span>{item.__name.substring(0, 100)}</span></h5>
                                                    {
                                                        item.opisanie && (
                                                            <div className="text-muted mb-2 line-clamp-4 text-truncate-four-lines" >
                                                                {item.opisanie.length>350 ? item.opisanie.substring(0, 350) + '...' : item.opisanie}
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>}
                    <ToastContainer autoClose={2000} limit={1}/>
                </Container>
            </div>

            {currentVacancies && !loading && <Modal
                size="xl"
                isOpen={modal_center}
                toggle={() => {
                    tog_center();
                }}
                centered
            >
                <ModalBody>
                    <div className="d-flex justify-content-between"><h4 className="mb-3">{currentVacancies.__name}</h4>
                        <CloseButton onClick={() => tog_center()}/></div>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"><i
                            className="ri-calendar-2-fill text-success align-middle me-1"></i> Вакансия обновлена {new moment(currentVacancies.__updatedAt).format('DD MMMM, HH:mm')}
                        </li>
                    </ul>
                    <Card className={"mt-4"}>
                        <CardHeader>
                            <h5 className="card-title mb-0">Описание</h5>
                        </CardHeader>
                        <CardBody>
                            {
                                currentVacancies?.opisanie
                            }
                        </CardBody>
                    </Card>
                    {
                        currentVacancies?.rassmatrivaem_kandidatov_vnutri_kompanii && (
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Рассматриваем кандидатов внутри компании</h5>
                                </CardHeader>
                                <CardBody>
                                    Да
                                </CardBody>
                            </Card>
                        )
                    }
                    {
                        currentVacancies?.usloviya_voznagrazhdeniya && (
                            <Card>
                                <CardHeader>
                                    <h5 className="card-title mb-0">Условия вознаграждения</h5>
                                </CardHeader>
                                <CardBody>
                                    {
                                        currentVacancies?.usloviya_voznagrazhdeniya
                                    }
                                </CardBody>
                            </Card>
                        )
                    }
                    <div className={"text-muted p-2"} >
                        Вы знаете человека идеально подходящего под описание вакансии?
                        Подайте обращение в кадровую службу с помощью кнопки "Обращение к кадровику" в верхней части экрана
                    </div>

                    <div className="d-flex justify-content-center align-items-center ">
                        <Button color="success" onClick={() => tog_center()}>
                            Понятно
                        </Button>
                    </div>
                </ModalBody>

            </Modal>}
        </>
    );
}

export default VacanciesPage;