import React from 'react';
import './assets/scss/themes.scss';
import Route from './Routes';

function App() {
    return (
        <React.Fragment>
           <Route/>
        </React.Fragment>
    );
}

export default App;
