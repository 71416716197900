import React, { useState, useEffect } from 'react';
import { getFileLink } from "../../api/myAPI";

const ImageComponent = ({ item }) => {
  const [imgSrc, setImgSrc] = useState(null);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchImageLink = async () => {
      try {
        const link = await getFileLink(item);
        setImgSrc(link.Link);
        setError(false)
      } catch (error) {
        console.error('Error fetching image link:', error);
        setError(true);
      }
    };

    fetchImageLink();
  }, [item]);

  return (
    <>
      {(imgSrc && !error) && (
        <img alt="question pic" src={imgSrc} style={{width: "100%", height: "100%"}}/>)
      }
    </>
  );
};

export default ImageComponent;
