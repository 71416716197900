import { createSlice } from "@reduxjs/toolkit";
import {getPollsList} from './thunk';

export const initialState = {
    pollsList: [],
    error: {},
};

const PollsSlice = createSlice({
    name: 'Polls',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getPollsList.fulfilled, (state, action) => {
            state.pollsList = action.payload;
        });
        builder.addCase(getPollsList.rejected, (state, action) => {
            state.error = action.payload.error || null;
        });
    }
});

export default PollsSlice.reducer;