import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

const Navdata = () => {
    const history = useNavigate();
    //state data
    const [isCompany, setIsCompany] = useState(false);
    const [iscurrentState, setIscurrentState] = useState('Company');

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Company') {
            setIsCompany(false);
        }
    }, [
        history,
        iscurrentState,
        isCompany
    ]);

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    const menuItems = [
        {
            id: "news",
            label: "Новости",
            icon: "ri-pages-line",
            link: "/news"
        },
        {
            id: "profile",
            label: "Профиль",
            icon: "ri-account-circle-line",
            link: "/profile"
        },
        {
            id: "profile",
            label: "Мероприятия",
            icon: "ri-calendar-event-line",
            link: "/events"
        },
        {
            id: "gallery",
            label: "Галерея",
            icon: "las la-images",
            link: "/gallery"
        },
        {
            id: "company",
            label: "Компания",
            icon: "ri-hospital-line",
            link: "/#",
            stateVariables: isCompany,
            click: function (e) {
                e.preventDefault();
                setIsCompany(!isCompany);
                setIscurrentState('Company');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "orgstruct",
                    label: "Оргструктура",
                    icon: "ri-account-circle-line",
                    link: "/orgstruct",
                    parentId: "company",
                },
                {
                    id: "team",
                    label: "Сотрудники",
                    link: "/team",
                    parentId: "company",
                },
                {
                    id: "goals",
                    label: "Цели компании",
                    link: "/goals",
                    parentId: "company",
                }
            ]
        },
        {
            id: "education",
            label: "Обучение",
            icon: "las la-graduation-cap",
            link: "/education"
        },
        {
            id: "knowledge",
            label: "База знаний",
            icon: "ri-book-2-line",
            link: "/knowledge"
        },
        {
            id: "polls",
            label: "Опросы",
            icon: "las la-chart-pie",
            link: "/polls"
        },
        {
            id: "vacancies",
            label: "Вакансии",
            icon: "mdi mdi-account-group-outline",
            link: "/vacancies"
        },
        {
            id: "lard",
            label: "Перейти в МИС (Lard)",
            icon: "ri-heart-2-line",
            link: "https://medprof.lard.in/"
        }
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;