import {createAsyncThunk} from "@reduxjs/toolkit";
//Include Both Helper File with needed methods
import {getPolls} from "../../api/polls";

export const getPollsList = createAsyncThunk("polls/getPolls", async () => {
    try {
        return await getPolls();
    } catch (error) {
        return error;
    }
});

